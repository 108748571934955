import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import { convertTime } from 'utils';
import { useCreateBulkDeliveryZone } from 'service/hooks';
import { DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE } from 'constants/index';
import { context as notificationsContext } from 'context/notifications';
import ZoneForm from '../ZoneForm';
import { convertedDeliveryTimeUtils, zonesInputNames, zonesPageSize } from '../utils';
import BulkResponseModal from './BulkResponseModal';

const BulkCreation = ({
  zoneController,
  setZoneController,
  setZones,
  bulkZones,
  setBulkZones,
  setDzTemplatesParams,
  setDzSuggestedTemplatesParams,
}) => {
  const { translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const [pending, setPending] = useState(false);
  const createBulkDeliveryZones = useCreateBulkDeliveryZone();
  const selectedStoreId = useSelectedStore();
  const [bulkZonesData, setBulkZonesData] = useState({ firstZone: '', data: [], length: 0 });

  const initialValues = {
    zoneName: 'temp',
    deliveryFee: '',
    minValue: '',
    enableZone: true,
    deliveryTime: [0, 'mins'],
    openingHours: zoneController.selectedBranch.openingHours,
  };

  useEffect(() => {
    const zonesData = bulkZones.map(item => ({
      area_title_en: item.properties.area_title_en,
    }));
    zonesData.splice(0, 1);
    setBulkZonesData({
      firstZoneTitle: bulkZones[0].properties.area_title_en,
      data: zonesData,
      length: zonesData.length,
    });
  }, []);

  const resetBulk = () => {
    setBulkZones([]);
    setDzTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null, searchValue: '' });
    setDzSuggestedTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null });
  };

  const convertedZones = (dynamicZones, dynamicBulkZones, values, convertedDeliveryTime) => {
    const convertedLocalZones = dynamicBulkZones.map(bulkItem => ({
      ...bulkItem,
      paths: bulkItem.geometry.coordinates[0].map(cordItem => ({ lat: cordItem[1], lng: cordItem[0] })),
      properties: {
        ...bulkItem.properties,
        id: dynamicZones.find(responseItem => bulkItem.properties.area_title_en === responseItem.zoneTemplateName).id,
        branch_reference_id: zoneController.selectedBranch.id,
        fillColor: zoneController.selectedBranch.color,
        zone_name: dynamicZones.find(
          responseItem => bulkItem.properties.area_title_en === responseItem.zoneTemplateName,
        ).zoneName,
        published: values.enableZone,
        branchName: zoneController.selectedBranch.title,
        delivery_fee: parseFloat(values.deliveryFee),
        delivery_time: convertedDeliveryTime,
        minimum_order: parseFloat(values.minValue),
        opening_hours: values.openingHours,
        fillOpacity: 0.5,
      },
    }));

    return convertedLocalZones;
  };

  const handleFormSubmit = async (values, setErrors) => {
    const convertedDeliveryTime = convertedDeliveryTimeUtils(
      values.deliveryTime[1],
      values.deliveryTime[0],
      convertTime,
    );

    setPending(true);

    const response = await createBulkDeliveryZones({
      deliveryZones: bulkZones.map(bulkItem => ({
        restaurantReferenceId: parseFloat(selectedStoreId),
        branchReferenceId: zoneController.selectedBranch.id,
        zoneName: bulkItem.properties.area_title_en,
        published: values.enableZone,
        deliveryFee: parseFloat(values.deliveryFee),
        deliveryTime: convertedDeliveryTime,
        minimumOrder: values.minValue ? parseFloat(values.minValue) : 0,
        openingHours: values.openingHours,
        geoShape: {
          ...DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE,
          features: [bulkItem],
        },
      })),
    });

    if (!response.hasError) {
      setPending(false);
      if (response.createBulkDeliveryZones) {
        const { deliveryZone, errors } = response.createBulkDeliveryZones;

        // - All zones failed
        if (deliveryZone.length === 0 && errors.length === 1) {
          const graphqlErrors = errors[0].errorMessage;
          const errorObj = {};
          if (graphqlErrors) {
            Object.entries(graphqlErrors).map(value => {
              if (value[0] === zonesInputNames.DELIVERY_TIME) {
                const time = value[1][0].match(/\(([^\\)]+)\)/g)[0];
                Object.assign(errorObj, { deliveryTime: translate(translations.DELIVERY_TIME_PREPARATION(time)) });
              }
              if (value[0] === zonesInputNames.ZONE_NAME) {
                Object.assign(errorObj, { zoneName: translate(translations.ZONE_NAME_EXIST) });
              }
              return null;
            });
          }
          setErrors(errorObj);
        }

        // - Some zones success and some failed
        if (deliveryZone.length >= 1 && errors.length >= 1) {
          const filteredLocalZones = bulkZones.filter(bulkItem =>
            errors.some(errorItem => errorItem.zoneTemplateName !== bulkItem.properties.area_title_en),
          );
          setZones(prevState =>
            prevState.concat(convertedZones(deliveryZone, filteredLocalZones, values, convertedDeliveryTime)),
          );
          notifications.show(
            <div className="flex flex-wrap">
              <p>{translate(translations.BULK_CREATE_ISSUE)} &nbsp;</p>
              <ul className="list-none flex flex-wrap">
                {errors.map(errorItem => (
                  <li key={errorItem.zoneTemplateName}>{errorItem.zoneTemplateName},&nbsp;</li>
                ))}
              </ul>
            </div>,
            'error',
          );
          resetBulk();
          setZoneController({
            ...zoneController,
            isAdd: false,
            drawerMode: null,
            showDrawerMode: false,
            enableDrawerMode: true,
            shapeRef: null,
            selectedZone: null,
            isBulkSelected: false,
            addZoneNotify: true,
            isBulk: false,
          });
        }

        // - All zones success
        if (deliveryZone.length >= 1 && errors.length === 0) {
          setZones(prevState =>
            prevState.concat(convertedZones(deliveryZone, bulkZones, values, convertedDeliveryTime)),
          );
          resetBulk();
          setZoneController({
            ...zoneController,
            isAdd: false,
            drawerMode: null,
            showDrawerMode: false,
            enableDrawerMode: true,
            shapeRef: null,
            selectedZone: null,
            isBulkSelected: false,
            addZoneNotify: true,
            isBulk: false,
          });
        }
      }
    } else {
      setPending(false);
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  const handleCancelMode = () => {
    resetBulk();
    setZoneController({
      ...zoneController,
      isAdd: false,
      drawerMode: null,
      showDrawerMode: false,
      enableDrawerMode: true,
      shapeRef: null,
      selectedZone: null,
      isBulkSelected: false,
      isBulk: false,
    });
  };

  return (
    <div className="bg-white p-4 mb-4 shadow">
      <div className="flex justify-between align-center mb-4">
        <Text className="text-base" value={translations.ZONE_PROPERTIES} />
        <div className="material-icons text-base cursor-pointer" onClick={handleCancelMode} aria-hidden="true">
          close
        </div>
      </div>
      <ZoneForm
        handleFormSubmit={handleFormSubmit}
        handleCancelMode={handleCancelMode}
        initialValues={initialValues}
        pending={pending}
        isBulk
        bulkZones={bulkZonesData}
      />
      {pending && <BulkResponseModal />}
    </div>
  );
};

BulkCreation.propTypes = {
  zoneController: PropTypes.shape({
    selectedZone: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      zoneName: PropTypes.string,
      deliveryFee: PropTypes.string,
      minValue: PropTypes.string,
      enableZone: PropTypes.bool,
    }),
    shapeRef: PropTypes.shape({
      id: PropTypes.number,
    }),
    drawerMode: PropTypes.string,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  setBulkZones: PropTypes.func.isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
  setDzSuggestedTemplatesParams: PropTypes.func.isRequired,
};

export default BulkCreation;
