import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { useDeliveryZoneMsToken } from 'hooks';
import { Text, Field } from 'components/service';
import { Input } from 'components/form/elements';
import { Button } from 'components/kit';
import { Label } from 'components/form/generic';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { VALIDATE_API_KEY, UPDATE_COURIER_BRANCH_ID } from './schemas';

const CourierIntegration = ({ branchId, setFieldValue, couriers }) => {
  const { lang } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const {
    selectedStore: { id: storeId },
  } = useContext(userContext);
  const [integratedCouriers, setIntegratedCouriers] = useState(couriers);
  const [courierBranchId, setCourierBranchId] = useState();
  const [selectedCourier, setSelectedCourier] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const [updateCourierBranchId] = useMutation(UPDATE_COURIER_BRANCH_ID, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    onCompleted: () => {
      setIsLoading(false);
      setSelectedCourier('');
    },
    onError: () => {
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  const [validateAPIKey] = useMutation(VALIDATE_API_KEY, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    onCompleted: data => {
      if (!data?.validateAPIKey?.valid) {
        setIsLoading(false);
        notifications.show(<Text value={translations.EXTERNAL_ID_INVALID} />, 'error');
        return;
      }

      if (!branchId) {
        let formattedCouriers = [];
        integratedCouriers.forEach(courier => {
          if (courier.branchExternalId) {
            formattedCouriers = [
              ...formattedCouriers,
              {
                courierId: courier.branchInternalId,
                apiKey: courier.branchExternalId,
              },
            ];
          }
        });
        setFieldValue('courierBranchId', formattedCouriers);
        setIsLoading(false);
        setSelectedCourier('');
        return;
      }

      updateCourierBranchId({
        variables: {
          storeId,
          branchInternalId: branchId,
          branchExternalId: courierBranchId,
          courierName: selectedCourier.courierName,
        },
      });
    },
    onError: () => {
      setIsLoading(false);
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  const checkValidation = key => {
    if (key?.length) {
      validateAPIKey({
        variables: {
          courierName: selectedCourier.courierName,
          APIKey: key,
        },
      });
    } else {
      setIsLoading(false);
      notifications.show(<Text value={translations.EXTERNAL_ID_IS_REQUIRED} />, 'error');
    }
  };

  const handleEditable = (e, courier) => {
    e.preventDefault();
    setSelectedCourier(courier);
    setCourierBranchId(courier.branchExternalId);
  };

  const handleUpdateCourierId = e => {
    e.preventDefault();
    setIsLoading(true);
    checkValidation(courierBranchId);
  };

  const handleExternalIdChange = value => {
    setCourierBranchId(value);
    if (!branchId) {
      const updatedCouriers = integratedCouriers.map(courier => {
        if (courier.branchInternalId === selectedCourier.branchInternalId) {
          return { ...courier, branchExternalId: value };
        }
        return courier;
      });
      setIntegratedCouriers(updatedCouriers);
    }
  };

  return (
    <div className="mb-2">
      <Text className="text-lg font-semibold px-4 -mx-4 pb-2" value={translations.DELIVERY_COURIER} />

      {couriers?.map(courier => (
        <div
          key={branchId ? courier.branchExternalId : courier.branchInternalId}
          className="border border-gray-300 p-4 mb-4"
        >
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-full pr-2">
              <Label
                title={
                  <>
                    <Text
                      className="text-lg font-semibold px-4 -mx-4 pb-2 capitalize"
                      value={[courier?.courierName, courier?.courierName]}
                    />
                    <Text value={translations.INTEGRATIONS_TYPE_DESCRIPTION(courier?.courierName)} />
                  </>
                }
              >
                <Field
                  type="text"
                  name="courierBranchId"
                  value={courier.branchExternalId}
                  placeholder={translations.ENTER_EXTERNAL_ID[lang === 'en' ? 0 : 1]}
                  component={Input}
                  onChange={e => handleExternalIdChange(e.currentTarget.value)}
                  disabled={!(courier.branchExternalId === selectedCourier.branchExternalId)}
                />
              </Label>
            </div>

            <div className="flex items-end">
              {(branchId &&
                courier.branchExternalId === selectedCourier.branchExternalId &&
                courier.branchInternalId === selectedCourier.branchInternalId) ||
              (!branchId && courier.branchInternalId === selectedCourier.branchInternalId) ? (
                <Button kind="primary" isSpinning={isLoading} onClick={handleUpdateCourierId}>
                  <Text value={translations.SAVE_CHANGE} />
                </Button>
              ) : (
                <Button kind="secondary" onClick={e => handleEditable(e, courier)}>
                  <Text value={translations.CHANGE} />
                </Button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

CourierIntegration.propTypes = {
  branchId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CourierIntegration;
