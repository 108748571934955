import { gql } from '@apollo/client';

export const UPDATE_COURIER_SETTINGS = gql`
  mutation updateCourierSettings($restaurantId: String!, $courierId: String!, $showRiderModal: Boolean) {
    updateCourierSettings(restaurantId: $restaurantId, courierId: $courierId, showRiderModal: $showRiderModal) {
      courierId
      isOtherDeliveryEnabled
      businessType
      courierDetails {
        id
        name
        country
        description
        supportNumber
        businessId
        businessName
      }
      isManualStatusUpdate
      authenticationType
      isInternalDelivery
      driverMaxCapacity
      isMenuPull
      isBranchListingEnabled
      isTaxInclusive
      isTimeFromCourier
      isFeeFromCourier
      supportMsValidationRequest
      default
      showRiderModal
    }
  }
`;
