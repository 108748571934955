import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { useDeliveryZoneMsToken } from 'hooks';
import { Box, Spinner } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { COURIER_AUTHENTICATION_TYPE } from 'constants/courier';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { context as userContext } from 'context/user';
import * as schemas from '../schemas';
import CourierCard from '../Card';

function CourierIntegration({ open, close, closeable }) {
  const {
    selectedStore: { countryCode, restaurantCourierSetting },
  } = useContext(userContext);
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const { data, loading } = useQuery(schemas.LIST_COURIERS, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    variables: {
      businessType: THIRD_PARTY_TYPE_ENUM.Delivery,
      isPublished: true,
      countryCode,
    },
  });
  const shouldShowCourier = courier =>
    (courier.authenticationType === COURIER_AUTHENTICATION_TYPE.GLOBAL_KEY &&
      !!restaurantCourierSetting.find(rcs => parseInt(rcs.courierId) === courier.id) &&
      !courier.inNetwork) ||
    courier.authenticationType !== COURIER_AUTHENTICATION_TYPE.GLOBAL_KEY;

  return (
    <>
      {!!data?.couriers.length && (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Box
              title={<Text className="font-semibold text-lg -mb-2" value={translations.DELIVERY_COURIER} />}
              body={
                <div className="pb-2 px-4">
                  {data?.couriers.map(
                    courier =>
                      shouldShowCourier(courier) && (
                        <div key={courier.name} className="mt-4">
                          <CourierCard
                            onOpen={open}
                            onClose={close}
                            closeable={closeable}
                            courier={courier}
                            courierType={THIRD_PARTY_TYPE_ENUM.Delivery.toLocaleLowerCase()}
                          />
                        </div>
                      ),
                  )}
                </div>
              }
            />
          )}
        </>
      )}
    </>
  );
}

CourierIntegration.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
};

export default CourierIntegration;
