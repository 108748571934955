import React from 'react';
import PropTypes from 'prop-types';

import { ORDER_STATUS } from 'constants/order';

const OrderFrameTabTitles = ({ name, status, data }) => {
  let count = 0;
  if (status === ORDER_STATUS.CURRENT) {
    count = data.orders.currentOrdersCount && data.orders.currentOrdersCount;
  } else if (status === ORDER_STATUS.PAST) {
    count = data.orders.pastOrdersCount && data.orders.pastOrdersCount;
  } else {
    count = data.orders.statusCount && data.orders.statusCount[status];
  }

  return (
    <>
      {name}
      {count || count === 0 ? (
        <span className="mx-1" style={{ direction: 'ltr' }}>
          ({count})
        </span>
      ) : (
        <div />
      )}
    </>
  );
};
OrderFrameTabTitles.propTypes = {
  name: PropTypes.element,
  status: PropTypes.string,
  data: PropTypes.shape({
    orders: PropTypes.shape({
      statusCount: PropTypes.shape({
        accepted: PropTypes.number,
        all: PropTypes.number,
        canceled: PropTypes.number,
        delivered: PropTypes.number,
        dispatched: PropTypes.number,
        fulfilled: PropTypes.number,
        paid: PropTypes.number,
        paymentExpired: PropTypes.number,
        paymentFailed: PropTypes.number,
        ready: PropTypes.number,
        redirectUrl: PropTypes.number,
        submitted: PropTypes.number,
        waitingForPayment: PropTypes.number,
        iframeUrl: PropTypes.number,
      }),
    }),
  }),
};

export default OrderFrameTabTitles;
