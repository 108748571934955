import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { DeliveryZoneMapView, QueryDeliveryZoneMapViewArgs } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { deliveryZonesQuery } from '../../queries/deliveryZones';

type useFetchDeliveryZonesShortType = (args: QueryDeliveryZoneMapViewArgs) => QueryHookResponse<DeliveryZoneMapView>;

const useFetchDeliveryZonesShort: useFetchDeliveryZonesShortType = args => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return useQuery<DeliveryZoneMapView>({
    query: deliveryZonesQuery,
    variables: args ? { ...args } : undefined,
    clientName: GRAPHQL_CLIENT_NAMES.DELIVERY_ZONES,
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res?.deliveryZones,
    config: { revalidateOnMount: true },
  });
};

export default useFetchDeliveryZonesShort;
