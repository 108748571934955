import React, { useContext } from 'react';
import { Typography } from '@zydalabs/zac-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { ReactComponent as Verd } from 'assets/verd2.svg';
import OrderRiderStatus from 'components/common/verd/OrderRiderStatus';

const StoreRiderInfo = ({ deliveryOrderStatus, orderDeliveryPhoneNumber, assignedDriver }) => {
  const { lang, translate } = useContext(localeContext);

  return (
    <div className="bg-gray-100 mb-4">
      <div className="flex bg-gray-100 py-4 px-3">
        <span className="h-5">
          <Verd />
        </span>
        <Typography variant="heading14" mx={12}>
          {assignedDriver?.name}
        </Typography>
      </div>
      <div className="flex flex-row justify-between px-4 py-4 mx-3 mb-4 bg-white">
        <div className="flex flex-col justify-between">
          <Typography variant="heading12" mb={8}>
            {translate(translations.REQUEST_STATUS)}
          </Typography>
          <OrderRiderStatus status={deliveryOrderStatus} lang={lang} />
        </div>
        <div className="flex  flex-col justify-between">
          <Typography variant="heading12" mb={8}>
            {translate(translations.PHONE_NUMBER)}
          </Typography>
          <Typography variant="element16">{orderDeliveryPhoneNumber}</Typography>
        </div>
      </div>
    </div>
  );
};

export default StoreRiderInfo;
