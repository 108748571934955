import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spinner } from '@zydalabs/zac-react';

import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { useFetchTotalEngageSales } from 'service/hooks/engage';
import {
  LOST_CUSTOMERS,
  TARGETED_CUSTOMERS,
  MESSAGES_SENT,
  SOMETHING_WENT_WRONG,
  TARGETED_CUSTOMERS_DESC,
  MESSAGES_SENT_DESC,
  LOST_CUSTOMERS_DESC,
} from 'constants/translations';
import { totalEngagementsEmptyState } from '../utils';

const ConversationCount = ({ setTotalOrders }) => {
  const { selectedStore } = useContext(userContext);

  const { data, isLoading, error } = useFetchTotalEngageSales({
    subdomain: selectedStore.subdomain,
  });

  // update total orders state
  useEffect(() => {
    if (data && data.totalEngagements) {
      setTotalOrders(data.totalEngagements.totalEngageOrders);
    }
  }, [data]);

  // handle loading state
  if (isLoading) {
    return <Spinner />;
  }

  // handle error state
  if (error) {
    return (
      <Typography variant="body14">
        <Text value={SOMETHING_WENT_WRONG} />
      </Typography>
    );
  }

  // get needed data
  const { totalEngagements } = data || {};
  const totalEngagementsFallback = totalEngagements || totalEngagementsEmptyState;

  return (
    <div className="bg-white p-5">
      <div>
        <div className="border-gray-100 border-b flex items-start justify-between pb-3 mb-3">
          <div className="w-3/4">
            <Typography variant="element14" mb={8}>
              <Text value={TARGETED_CUSTOMERS} />
            </Typography>
            <Typography variant="element12" mb={8}>
              <Text className="text-zyda-grey-70" value={TARGETED_CUSTOMERS_DESC} />
            </Typography>
          </div>
          <Typography variant="heading14">
            <span>{totalEngagementsFallback.totalEnqueuedEngageUsers}</span>
          </Typography>
        </div>

        <div className="border-gray-100 border-b flex items-center justify-between pb-3 mb-3">
          <div className="w-3/4">
            <Typography variant="element14" mb={8}>
              <Text value={MESSAGES_SENT} />
            </Typography>
            <Typography variant="element12" mb={8}>
              <Text className="text-zyda-grey-70" value={MESSAGES_SENT_DESC} />
            </Typography>
          </div>
          <Typography variant="heading14">
            <span>{totalEngagementsFallback.totalSentMessages}</span>
          </Typography>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-3/4">
            <Typography variant="element14" mb={8}>
              <Text value={LOST_CUSTOMERS} />
            </Typography>
            <Typography variant="element12" mb={8}>
              <Text className="text-zyda-grey-70" value={LOST_CUSTOMERS_DESC} />
            </Typography>
          </div>
          <Typography variant="heading14">
            <span>{totalEngagementsFallback.totalLostEngageUsers}</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

ConversationCount.propTypes = {
  setTotalOrders: PropTypes.func.isRequired,
};

export default ConversationCount;
