import { useContext } from 'react';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { context as userContext } from 'context/user';
import { Order, QueryRestaurantRecentOrdersArgs } from 'service/types/generated/backend_client_index';
import { restaurantRecentOrder } from 'service/queries';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';

type useFetchActiveOrdersType = (args: QueryRestaurantRecentOrdersArgs) => QueryHookResponse<Order>;

const useFetchActiveOrders: useFetchActiveOrdersType = ({ statuses }) => {
  const { selectedStore } = useContext(userContext);
  const token = userService.getToken();
  const { subdomain } = selectedStore || {};
  return useQuery<Order>({
    query: restaurantRecentOrder,
    variables: { subdomain, statuses },
    requestHeaders: { Authorization: `Bearer ${token}` },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    handler: (res: any) => res.restaurantRecentOrders,
    config: { revalidateOnMount: true },
  });
};
export default useFetchActiveOrders;
