import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import 'style.css';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ClockIcon } from '@heroicons/react/solid';
import { PaidMethodsIcon } from 'utils/imagesResolver';
import CheckBox from 'components/kit/CheckBox';
import PaidThrough from './PaidThrough';
import DeliveryCircularProgress from './DeliveryCircularProgress';
import { getOrderIcon, getDeliveryTypeText, BulkChangeTip, ManualOrderMark } from './utils/orderSectionUtils';

const OrderItemDesktopNew = ({
  updateOrderSelectedState = () => {
    // Do Nothing
  },
  isOrderUpdating,
  bulkChangeStatus,
  isFaded,
  onClick,
  textSize,
  showOrderingUser,
  order,
  currentTabStatus,
  OrderActionButton,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore, settings } = useContext(userContext);
  const { colors } = useTheme();
  const { currency } = selectedStore;
  const {
    deliveryStatus,
    id,
    isSelected,
    deliveryZone,
    areaNameEn,
    areaNameAr,
    paidThrough,
    number,
    deliveryType,
    branchName,
    branchData,
    total,
    gift,
    customerName,
    isManualOrder,
  } = order || {};
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;
  const isVerdEnabled = settings?.enableVerd;

  const orderNumberAndUserName = (
    <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
      {showOrderingUser ? (
        <>
          <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
            #<span className="select-all cursor-text">{number}</span>
          </Typography>
          <div className="text-truncation">
            <Typography variant="heading16" cursor="pointer">
              {customerName}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <span className="block text-gray-700 text-xs">Order No.</span>
          <span className="block text-sm">
            #<span className="select-all cursor-text">{number}</span>
          </span>
        </>
      )}
    </div>
  );

  const deliveryOrPickupText = (
    <>
      <span
        className={cx(
          lang === 'ar' ? 'ml-2' : 'mr-2',
          'rounded-full bg-gray-200 w-8 h-8 px-2 flex items-center justify-center',
        )}
      >
        <span className="h-5">{getOrderIcon(deliveryType, gift, deliveryStatus, currentTabStatus)}</span>
      </span>
      <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
        <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
          {getDeliveryTypeText(deliveryType)}
        </Typography>
        <div className="text-truncation">
          <Typography variant="heading16" cursor="pointer">
            {branchData ? translate(branchData) : branchName}

            {// checking if store use new delivery zone system (useDeliveryzonesMs)
            // NOTE later we will migrate all stores to new system
            deliveryType === 'delivery' && ` - ${deliveryZone?.zoneName ? deliveryZone?.zoneName : areaName}`}
          </Typography>
        </div>
      </div>
    </>
  );

  const paymentText = (
    <div className="flex items-center" style={{ direction }}>
      <div className="flex items-center justify-center rounded-full bg-gray-200 w-8 h-8 flex-shrink-0">
        <PaidMethodsIcon paidThrough={paidThrough} />
      </div>
      <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left', textSize !== 'sm' && 'pl-2')}>
        <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
          <PaidThrough paidThrough={paidThrough} />
        </Typography>
        <div style={{ direction }}>
          <Typography variant="heading16" cursor="pointer">
            {total.toFixed(currency.decimals)}
            <Text value={currency} className="inline" />
          </Typography>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isOrderUpdating && <BulkChangeTip status={bulkChangeStatus} />}
      <div
        role="presentation"
        data-tooltip-id={isOrderUpdating && 'bulkChangeTip'}
        className={cx(
          'flex flex-col md:flex-wrap bg-gray-100 relative p-5',
          lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row',
          'items-center cursor-pointer',
          isSelected && 'bg-primary-base/10',
          (isFaded || isOrderUpdating) && 'opacity-25',
        )}
        onClick={onClick}
        data-testid="order-number-select"
      >
        <div
          className={cx(
            'flex-initial border-b w-34 md:border-b-0 border-gray-200',
            lang === 'ar' ? 'flex-row-reverse' : '',
            'flex flex-row justify-center items-center',
          )}
        >
          {!isOrderUpdating && isVerdEnabled && (
            <CheckBox value={isSelected} lang={lang} onChange={value => updateOrderSelectedState(id, value)} />
          )}
          {isOrderUpdating && (
            <ClockIcon className={cx('w-5 h-5 text-zyda-yellow-500', lang === 'ar' ? 'ml-3' : 'mr-3')} />
          )}
        </div>
        <div
          className={cx('w-full flex-1 flex', lang === 'ar' && 'flex-row-reverse', 'items-center select-none gap-2')}
        >
          <div
            className={cx(
              'p-2 order-4 md:order-none md:p-0 flex items-center w-full md:w-1/4 justify-start',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
            )}
          >
            {orderNumberAndUserName}
          </div>
          <div
            className={cx(
              'flex items-center justify-start w-1/2 md:w-1/3',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
            )}
          >
            {deliveryOrPickupText}
          </div>
          <div className={cx('flex items-centerw-1/2 md:w-1/4', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
            {paymentText}
          </div>
          <div
            className={cx(
              'w-full p-2 md:w-1/6 md:p-0 flex items-center justify-start',
              lang === 'ar' ? ' flex-row-reverse' : 'flex-row',
            )}
          >
            <DeliveryCircularProgress order={order} />
          </div>
        </div>
        <div
          className={cx(
            'flex items-center md:w-2/5 justify-center',
            lang === 'ar' ? ' flex-row-reverse ml-5' : 'flex-row mr-5',
          )}
        >
          {OrderActionButton}
        </div>
        <div className={cx('absolute', lang === 'ar' ? 'left-4' : 'right-4')}>
          {isManualOrder && <ManualOrderMark />}
        </div>
      </div>
    </>
  );
};

OrderItemDesktopNew.propTypes = {
  updateOrderSelectedState: PropTypes.func,
  isOrderUpdating: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  userData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
    }),
  }),
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  textSize: PropTypes.string,
  showOrderingUser: PropTypes.bool,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

export default OrderItemDesktopNew;
