import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useReplaceParams, useSelectedStore } from 'hooks/index';
import { Button, Typography } from '@zydalabs/zac-react';
import { XIcon, CheckIcon } from '@zydalabs/zac-icons-react';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';

import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import {
  ACTIVATE_WHATSAPP_AUTOPILOT,
  ACTIVATE_WHATSAPP_AUTOPILOT_DESC,
  CANCEL,
  ACTIVATE,
  ENABLE_ONLINE_PAYMENTS,
  ONLINE_PAYMENT_NOTE_1,
  ONLINE_PAYMENT_NOTE_2,
  ONLINE_PAYMENT_NOTE_3,
  ACTIVATE_ENGAGE_MESSAGE,
  SOMETHING_WENT_WRONG,
} from 'constants/translations';
import { Text } from 'components/service';
import { paymentGateways } from 'paths';
import { UPDATE_ENGAGE_STATUS } from '../../schemas';

const ActivateModal = ({ close, isOnlinePaymentAvailable, setEngageController, cashbackNote }) => {
  const { translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const replace = useReplaceParams();
  const storeId = useSelectedStore();

  const [disableActivateButton, setDisableActivateButton] = useState(false);

  // handle activate engage mutation
  const [updateEnabled] = useMutation(UPDATE_ENGAGE_STATUS, {
    onCompleted: () => {
      setDisableActivateButton(false);
      setEngageController({
        active: true,
        registered: true,
      });
      notifications.show(<Text value={ACTIVATE_ENGAGE_MESSAGE} />, 'success');
      close();
    },
    onError: () => {
      setDisableActivateButton(false);
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  // handle activate internal helper
  const handleActivate = () => {
    if (isOnlinePaymentAvailable) {
      setDisableActivateButton(true);
      updateEnabled({
        variables: { storeId, enableEngage: true },
      });
    } else {
      navigate(replace(paymentGateways));
    }
  };

  return (
    <div>
      <div className="p-4 border-b border-zyda-grey-50 flex justify-between items-start mb-4">
        <div>
          <Typography variant="heading16" mb={4}>
            {translate(ACTIVATE_WHATSAPP_AUTOPILOT)}
          </Typography>
          <Typography variant="body14" color="rgba(31, 31, 31, 0.65)">
            {translate(ACTIVATE_WHATSAPP_AUTOPILOT_DESC)}
          </Typography>
        </div>

        <div className="flex items-center justify-center cursor-pointer">
          <XIcon width="32" color="#000" onClick={close} />
        </div>
      </div>

      <div className="px-4">
        {isOnlinePaymentAvailable ? (
          <ul className="mb-8">
            {cashbackNote && (
              <li className="mb-2 flex items-start">
                <CheckIcon width="16" color="#000" />
                <div className="flex-1">
                  <Typography variant="body12">
                    <Text value={cashbackNote} className="px-2" />
                  </Typography>
                </div>
              </li>
            )}
          </ul>
        ) : (
          <ul className="mb-8">
            <li className="mb-2 flex items-start">
              <CheckIcon width="16" color="#000" />
              <Typography variant="body12">
                <Text value={ONLINE_PAYMENT_NOTE_1} className="px-2" />
              </Typography>
            </li>

            <li className="mb-2 flex items-start">
              <CheckIcon width="16" color="#000" />
              <Typography variant="body12">
                <Text value={ONLINE_PAYMENT_NOTE_2} className="px-2" />
              </Typography>
            </li>
            <li className="mb-2 flex items-start">
              <CheckIcon width="16" color="#000" />
              <Typography variant="body12">
                <Text value={ONLINE_PAYMENT_NOTE_3} className="px-2" />
              </Typography>
            </li>
          </ul>
        )}

        <div className="flex justify-end gap-2">
          <Button rounded variant="secondary" text={translate(CANCEL)} onClick={close} />
          <Button
            rounded
            variant="primary"
            text={translate(isOnlinePaymentAvailable ? ACTIVATE : ENABLE_ONLINE_PAYMENTS)}
            onClick={handleActivate}
            isLoading={disableActivateButton}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

ActivateModal.propTypes = {
  close: PropTypes.func.isRequired,
  setEngageController: PropTypes.func.isRequired,
  isOnlinePaymentAvailable: PropTypes.bool.isRequired,
  cashbackNote: PropTypes.shape({
    EN: PropTypes.string,
    AR: PropTypes.string,
  }),
};

export default ActivateModal;
