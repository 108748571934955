import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import cx from 'classnames';

import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import { Text } from 'components/service';

const WalletDataMoney = ({ title, value, valueClassName, toolTipText, id }) => (
  <div className="flex justify-between py-3">
    <div className="flex flex-col py-3">
      <Text value={title} className="font-normal opacity-75 mb-1" />
      <Text value={value} className={cx('text-2xl font-medium mb-1', valueClassName)} />
    </div>
    <AlertCircle
      data-tooltip-id={id}
      className="text-gray-400 fill-current inline mr-2 ml-1 mt-1"
      style={{ transform: 'scale(0.75)' }}
    />
    <ReactTooltip place="bottom" type="dark" effect="solid" id={id}>
      <Text value={toolTipText} />
    </ReactTooltip>
  </div>
);

export default WalletDataMoney;
