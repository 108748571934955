import { generatedSales } from 'service/queries';
import { QueryEngageGeneratedSalesArgs, EngageGeneratedSale } from 'service/types/generated/backend_client_index';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';

type useFetchGeneratedSalesType = (args: QueryEngageGeneratedSalesArgs) => QueryHookResponse<EngageGeneratedSale>;

const useFetchGeneratedSales: useFetchGeneratedSalesType = ({ subdomain }) => {
  const token = userService.getToken();
  return useQuery<EngageGeneratedSale>({
    query: generatedSales,
    variables: { subdomain },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    requestHeaders: { Authorization: `Bearer ${token}` },
    handler: (res: any) => res,
    config: { revalidateOnMount: true },
  });
};

export default useFetchGeneratedSales;
