import React from 'react';
import * as yup from 'yup';

import { Text } from 'components/service';
import * as translations from '../../../../../constants/translations';

const validationSchema = yup.object().shape({
  name: yup.string().required(<Text value={translations.PROGRAM_NAME_REQUIRED} />),
  startsAt: yup.date().required(<Text value={translations.PROGRAM_START_DATE_REQUIRED} />),
  hasEndDate: yup.boolean().required(),
  enableEndDate: yup.boolean(),
  endsAt: yup.date().when(['hasEndDate', 'enableEndDate'], {
    is: true,
    then: yup
      .date()
      .required(<Text value={translations.PROGRAM_END_DATE_REQUIRED} />)
      .min(yup.ref('startsAt'), <Text value={translations.START_DATE_BEFORE_END} />),
  }),
  percentage: yup.string().required(),
});

export default validationSchema;
