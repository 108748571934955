import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import DeliveryActionButtons from './DeliveryActionButtons';
import { isDeliveryOrder as isDeliveryOrderHelper } from '../helpers';
import PickupActionButtons from './PickupActionButtons';
import useOrderActionButtonUtils from '../utils/orderActionButtonsUtils';
import MerchantDeliveryActionButtons from './MerchantDeliveryActionButtons';

const OrderDetailsActionButtons = ({
  order,
  shouldDisableActionBtns,
  fromOrderDetails,
  ordersVariables,
  newOrders,
}) => {
  const { selectedStore, settings } = useContext(userContext);
  const { restaurantCourierSetting } = selectedStore;

  const { status, deliveryCourierId, id, deliveryType } = order || {};
  const isDeliveryOrder = order && isDeliveryOrderHelper(deliveryType);
  const { isUpdatingStatus, sendChangeStatus } = useOrderActionButtonUtils({
    ordersVariables,
    orderDetailsStatus: status,
    id,
    deliveryType,
    newOrders,
    deliveryCourierId,
    order,
  });
  const isVerd = settings.enableVerd;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );

  const onFleetCourierActions = (
    <DeliveryActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      fromOrderDetails={fromOrderDetails}
    />
  );

  const noCourierOrVerdActions = (
    <MerchantDeliveryActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      fromOrderDetails={fromOrderDetails}
    />
  );

  const pickupActions = (
    <PickupActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      fromOrderDetails={fromOrderDetails}
    />
  );

  if (isDeliveryOrder) {
    if ((isVerd || filteredRestaurantCourierSetting.length) && order.status !== ORDER_STATUS.CANCELED)
      return onFleetCourierActions;
    return noCourierOrVerdActions;
  }

  return pickupActions;
};

OrderDetailsActionButtons.propTypes = {
  order: PropTypes.shape({
    expectedAt: PropTypes.string.isRequired,
    deliveryType: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  shouldDisableActionBtns: PropTypes.bool,
  selectedStore: PropTypes.shape({ timeZone: PropTypes.string }).isRequired,
};
export default OrderDetailsActionButtons;
