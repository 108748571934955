import React, { useContext } from 'react';

import { context as featureBlockingContext } from 'context/feature-blocking';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';
import * as paths from 'paths.js';
import { ReactComponent as Illustration } from 'assets/illustration-campaigns.svg';
import BlokcedCampaignButton from '../../BlockedCampaignButton';

export default () => {
  const { blockCampaignCreation } = useContext(featureBlockingContext);

  return (
    <div className="flex flex-col items-center mt-24 pb-32 px-10 text-center">
      <Illustration />
      <Text className="text-lg mb-1 font-semibold mt-4" value={translations.START_FIRT_CAMPAIGN} />
      <Text className="text-gray-700 mb-6" value={translations.YOU_CAN_START_ADDING_CAMPAIGNS} />

      {
        blockCampaignCreation ? (
          <div className="w-48"><BlokcedCampaignButton /></div>
        ) : (
          <Link className="w-48 mt-4" to={paths.createCampaign}>
            <Button size="full" kind="primary">
              <Text value={translations.CREATE} />
            </Button>
          </Link>
        )
      }
      
    </div>
  );
};
