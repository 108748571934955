import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as CheckCircle } from 'assets/check-circle.svg';
import { ReactComponent as PickupIcon } from 'assets/pickup-icon.svg';
import { context as localeContext } from 'context/locale';
import { ORDER_STATUS } from 'constants/order';
import { Button } from 'components/kit';
import * as translations from 'constants/translations';

const PickupActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  fromOrderDetails,
}) => {
  const { translate } = useContext(localeContext);
  const { status: orderStatus } = order || {};
  const showNextStatus = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <Button
            full
            isRounded
            kind="primary"
            icon={<CheckCircle />}
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })}
            data-testid="accept-order-button"
          >
            {translate(translations.ACCEPT_ORDER)}
          </Button>
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            full
            isRounded
            kind="primary"
            icon={<PickupIcon fill="white" />}
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false })}
            data-testid="ready-order-button"
          >
            {translate(translations.READY_FOR_PICKUP)}
          </Button>
        );
      case ORDER_STATUS.READY:
        return (
          <Button
            full
            isRounded
            kind="primary"
            icon={<CheckCircle />}
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false })}
            data-testid="fulfill-order-button"
          >
            {translate(translations.FULFILL_ORDER)}
          </Button>
        );
      default:
        return null;
    }
  };
  return (
    <div className={cx(!fromOrderDetails && 'w-full')} onClick={e => e.stopPropagation()} aria-hidden="true">
      {showNextStatus()}
    </div>
  );
};

PickupActionButtons.propTypes = {
  order: PropTypes.shape({ status: PropTypes.string.isRequired }),
  isUpdatingStatus: PropTypes.bool,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
};

export default PickupActionButtons;
