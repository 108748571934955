import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useSelectedStore } from 'hooks';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { ORDERS } from 'components/common/orders/Frame/schemas';
import { DELIVERY_ENUMS } from '../../constants';
import * as schemas from '../../schemas';

const useOrderActionButtonUtils = ({ ordersVariables, orderDetailsStatus, id, deliveryType, newOrders, order }) => {
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const { courier } = useContext(userContext);
  const { deliveryCourierId, inBetweenTransitions: orderInbetweentransitions } = order || {};

  const updateStatusUpdateCache = (
    cache,
    {
      data: {
        updateOrderStatus: { status: newStatus },
      },
    },
  ) => {
    const orders = cache.readQuery({
      query: ORDERS,
      variables: ordersVariables,
    });
    const statuses = orders.orders.statusCount;
    const oldStatus = orders.orders.orders.find(({ id: oId }) => oId === id).status;

    cache.writeQuery({
      query: ORDERS,
      variables: ordersVariables,
      data: {
        orders: {
          ...orders.orders,
          statusCount:
            oldStatus !== newStatus
              ? {
                  ...statuses,
                  [orderDetailsStatus]: statuses[orderDetailsStatus] - 1,
                  [newStatus]: statuses[newStatus] + 1,
                }
              : { ...statuses },
        },
      },
    });
  };

  const updateStatusAsyncUpdateCache = (
    cache,
    {
      data: {
        updateOrderStatusAsync: { status: newStatus, inBetweenTransitions },
      },
    },
  ) => {
    const orders = cache.readQuery({
      query: ORDERS,
      variables: ordersVariables,
    });
    const statuses = orders.orders.statusCount;
    const oldOrder = orders.orders.orders.find(({ id: oId }) => oId === id);
    const ordersWithoutOldOne = orders.orders.orders.filter(({ id: oId }) => oId !== id);
    const newUpdatedOrders = [{ ...oldOrder, inBetweenTransitions }, ...ordersWithoutOldOne];

    cache.writeQuery({
      query: ORDERS,
      variables: ordersVariables,
      data: {
        orders: {
          ...orders.orders,
          orders: newUpdatedOrders,
          statusCount:
            oldOrder.status !== newStatus
              ? {
                  ...statuses,
                  [orderDetailsStatus]: statuses[orderDetailsStatus] - 1,
                  [newStatus]: statuses[newStatus] + 1,
                }
              : { ...statuses },
        },
      },
    });
  };

  const updateStatusOnError = ({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ extensions }) => {
        const { body } = extensions.exception;
        if (body.error) {
          return notifications.show(body.error, 'error');
        }
        if (body.delivery_courier) {
          return notifications.show(body.delivery_courier, 'error');
        }
        return notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      });
  };

  const [updateStatusAsync] = useMutation(schemas.UPDATE_STATUS_ASYNC, {
    update: updateStatusAsyncUpdateCache,
    onError: updateStatusOnError,
  });

  const [updateStatus, { loading: isUpdatingStatus }] = useMutation(schemas.UPDATE_STATUS, {
    update: updateStatusUpdateCache,
    onError: updateStatusOnError,
  });

  const sendChangeStatus = async ({ status, sendCourierId, async = false }) => {
    const payload = {
      variables: {
        storeId,
        orderId: id,
        status,
        ...(deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL &&
          courier &&
          sendCourierId && {
            deliveryCourierId,
          }),
      },
    };
    async ? await updateStatusAsync(payload) : await updateStatus(payload);
    newOrders.markAsRead(id);
  };

  return { isUpdatingStatus: isUpdatingStatus || orderInbetweentransitions, sendChangeStatus };
};

export default useOrderActionButtonUtils;
