import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text, Field } from 'components/service';
import * as translations from 'constants/translations';
import { Input } from 'components/form/elements';
import { Error } from 'components/form/generic';
import { context as notificationsContext } from 'context/notifications';
import { useDeliveryZoneMsToken } from 'hooks';
import handleValidateError from './utils';
import VALIDATE_API_KEY from './schemas';

const Table = ({ branches, courierName, setValidAPIKeys, validAPIKeys, setIsValidating, setIsDisabled }) => {
  const [branchesAPIKeys, setBranchesAPIKeys] = useState([]);
  const { lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);

  const headCellClassName = 'text-justify font-semibold border-b whitespace-nowrap w-1/3';

  const cellClassName = 'py-2 px-3 border-b border-gray-300 whitespace-nowrap';

  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const [validateAPIKey] = useMutation(VALIDATE_API_KEY, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
  });

  useEffect(() => {
    setIsDisabled(!validAPIKeys || !branches || Object.keys(validAPIKeys)?.length !== branches?.length);
  }, [validAPIKeys]);

  const checkValidation = async (branchId, APIKey) => {
    if (APIKey.length) {
      setIsValidating(true);
      try {
        const res = await validateAPIKey({
          variables: {
            courierName,
            APIKey,
          },
        });
        let tempBranches = [...branchesAPIKeys];
        tempBranches = tempBranches.map(branch => {
          const tempBranch = branch;
          if (tempBranch.id === branchId) {
            tempBranch.valid = res.data.validateAPIKey.valid;
            if (tempBranch.valid) {
              setValidAPIKeys({ ...validAPIKeys, [branchId]: { id: branchId, externalId: APIKey } });
            } else {
              const temp = { ...validAPIKeys };
              delete temp[branchId];
              setValidAPIKeys(temp);
            }
          }
          return tempBranch;
        });
        setBranchesAPIKeys(tempBranches);
        setIsValidating(false);
      } catch (err) {
        if (!err.graphQLErrors) throw err;
        else {
          const message = handleValidateError(err);
          notifications.show(message, 'error');
        }
        setIsValidating(false);
      }
    } else {
      const temp = { ...validAPIKeys };
      delete temp[branchId];
      setValidAPIKeys(temp);
    }
  };

  useEffect(() => {
    const tempBranches = [];
    branches.forEach(element => {
      tempBranches.push({ ...element, valid: null, externalId: '' });
    });
    setBranchesAPIKeys(tempBranches);
  }, [branches]);

  return (
    <table style={{ direction }} className="border shadow rounded-lg mt-4 w-full">
      <thead>
        <tr className="bg-gray-200">
          <th className={headCellClassName}>
            <Text value={translations.BUSINESS_LOCATION} className="whitespace-nowrap inline-block m-4" />
          </th>
          <th className={headCellClassName}>
            <Text value={translations.KEY} className="whitespace-nowrap inline-block m-4" />
          </th>
          <th className={headCellClassName}>
            <Text value="" className="whitespace-nowrap inline-block m-4" />
          </th>
        </tr>
      </thead>
      <tbody>
        {branchesAPIKeys.map(item => (
          <tr key={item.id}>
            <td className={cellClassName}>
              <Text value={item} className="m-4" />
            </td>
            <td className={cellClassName}>
              <Field
                type="text"
                name={`apiKey${item.id}`}
                placeholder={lang === 'en' ? translations.KEY[0] : translations.KEY[1]}
                required
                component={Input}
                onBlur={event => checkValidation(item.id, event.currentTarget.value)}
              />
            </td>
            <td className={cellClassName}>
              {item.valid === false && (
                <Error direction={direction}>
                  {lang === 'en' ? translations.EXTERNAL_ID_INVALID[0] : translations.EXTERNAL_ID_INVALID[1]}
                </Error>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  setValidAPIKeys: PropTypes.func.isRequired,
  validAPIKeys: PropTypes.func.isRequired,
  courierName: PropTypes.string.isRequired,
  setIsValidating: PropTypes.func.isRequired,
  setIsDisabled: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default Table;
