import { gql } from 'graphql-request';

const generatedSales = gql`
  query($subdomain: String!) {
    engageGeneratedSales(subdomain: $subdomain) {
      totalSales
      graphData
    }
  }
`;

export default generatedSales;
