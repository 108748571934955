import React, { useContext } from 'react';
import { navigate, Match } from '@reach/router';
import styled from 'styled-components';
import cx from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '@zydalabs/zac-icons-react';

import { Link } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as featureBlockingContext } from 'context/feature-blocking';
import { useReplaceParams } from 'hooks/index';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import { getCookie } from 'utils';
import * as paths from '../../../../../paths';

const Item = ({ firstLink, testId, to = '/404', link = null, icon: Icon, children, nested = [], beta }) => {
  const replace = useReplaceParams();
  const { lang } = useContext(localeContext);
  const { user, selectedStore, setSidebarOpened, isNavBarCollapsed } = useContext(userContext);
  const { featureAccess } = useContext(featureBlockingContext);
  const sideAccess = featureAccess?.get(ACCESS_MAP_KEYS.SIDE);

  const logSmsTabClicked = () => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.track(
          'View SMS Tab',
          {
            user_name: user.name,
            user_role: user.roles[0]?.role,
          },
          {
            groupId: selectedStore.id,
            userId: user.id,
          },
        );
      }
    }
  };

  return (
    <div className={cx(isNavBarCollapsed && 'py-1')}>
      <StyledLink
        to={to}
        data-testid={testId}
        onClick={e => {
          e.preventDefault();
          setSidebarOpened(false);
          if (link) window.open(link, '_blank');
          navigate(replace(firstLink));
        }}
        getProps={({ isPartiallyCurrent }) => ({
          className: cx(
            'flex flex-row rounded hover:bg-gray-200 items-center p-2',
            lang === 'ar' && 'flex-row-reverse',
            isPartiallyCurrent ? 'bg-gray-200 text-black' : 'text-primary-nav',
            isPartiallyCurrent && nested && 'active-link',
            isNavBarCollapsed ? 'justify-center' : 'my-1',
          ),
        })}
      >
        <div className={cx('text-sm material-icons', !isNavBarCollapsed && (lang === 'ar' ? 'ml-2' : 'mr-2'))}>
          <Match path={to + '/*'}>
            {props => (props.match ? <Icon width="20" color="#000000" /> : <Icon width="20" color="#373E5A" />)}
          </Match>
        </div>
        {children}
        <Match path={to + '/*'}>
          {props =>
            props.match ? (
              <>
                {beta && <BETABadge active />}
                {sideAccess?.get(to)?.isBlocked && <UpgradeRocketIcon />}
                {nested.length > 0 && <OpenedLink />}
              </>
            ) : (
              <>
                {beta && <BETABadge />}
                {sideAccess?.get(to)?.isBlocked && <UpgradeRocketIcon />}
                {nested.length > 0 && <ClosedLink />}
              </>
            )
          }
        </Match>
      </StyledLink>
      {nested && (
        <div className="hidden">
          {nested.map((item, i) => (
            <div className={cx('flex flex-col rounded hover:bg-gray-200', lang === 'ar' ? 'pr-8' : 'pl-8')} key={i}>
              <SubStyledLink
                key={i}
                data-testid={item.testId}
                to={item.to}
                onClick={() => {
                  setSidebarOpened(false);
                  if (item.to === paths.campaigns) logSmsTabClicked();
                }}
                getProps={({ isPartiallyCurrent }) => ({
                  className: cx(
                    'flex items-center py-2',
                    lang === 'ar' && 'flex-row-reverse',
                    isPartiallyCurrent ? 'text-primary-base' : 'text-primary-nav',
                    item.subNested && isPartiallyCurrent && 'active-link',
                  ),
                })}
              >
                {item.title}
                {item.beta && <BETABadge />}
                {sideAccess?.get(item.to)?.isBlocked && <UpgradeRocketIcon />}
              </SubStyledLink>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const StyledLink = styled(Link)`
  &.active-link + div {
    display: block;
  }
`;

const SubStyledLink = styled(Link)`
  &.active-link + div {
    display: block;
  }
`;

const OpenedLink = () => {
  return (
    <div className="border-gray-300 m-2 md:rounded-none md:border-none md:m-0 cursor-pointer">
      <ChevronUpIcon width="16" color="#373E5A" />
    </div>
  );
};
const ClosedLink = () => {
  return (
    <div className="border-gray-300 m-2 md:rounded-none md:border-none md:m-0 cursor-pointer">
      <ChevronDownIcon width="16" color="#373E5A" />
    </div>
  );
};

const BETABadge = ({ active }) => (
  <div
    style={{ backgroundColor: active && 'rgba(31, 31, 31, 0.12)' }}
    className={cx('mx-2 rounded text-xs px-2 py-1 h-5 flex items-center', !active && 'bg-gray-300')}
  >
    <Text value={translations.BETA} className="text-primary-nav" />
  </div>
);

export default Item;
