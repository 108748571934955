import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@zydalabs/zac-react';
import { PlayIcon, XIcon, ChevronsRightIcon } from '@zydalabs/zac-icons-react';
import cx from 'classnames';

import ImageView from 'assets/engage-phone-banner.png';
import { context as localeContext } from 'context/locale';
import {
  DEACTIVATE_ENGAGE_MESSAGE,
  WATCH_VIDEO,
  PROCEED_TO_DEACTIVATE,
  DEACTIVATE_ENGAGE_CONFIRM_TITLE,
} from 'constants/translations';
import { Text } from 'components/service';
import DeactivateConfirmModal from '../DeactivateConfirmModal';

const DeactivateModal = ({ open, close, setEngageController }) => {
  const { translate, direction } = useContext(localeContext);

  return (
    <div className="flex flex-col" style={{ direction }}>
      <div className="relative flex justify-center items-center h-48">
        <div className="absolute bot-0 top-0 right-0 w-full h-full">
          <img src={ImageView} className="w-full h-full object-cover" alt="engage" />
        </div>
        <div className="z-10">
          <Button
            rounded
            size="medium"
            text={translate(WATCH_VIDEO)}
            onClick={() => {
              window.open('https://youtu.be/Vd-a8NlBQOU');
            }}
            startIcon={<PlayIcon width="20px" color="white" />}
          />
        </div>
        <div className="absolute top-4 right-4 cursor-pointer">
          <Button
            rounded
            size="small"
            variant="secondary"
            onClick={close}
            endIcon={<XIcon width="32px" color="#c1c1c1" />}
          />
        </div>
      </div>
      <div className="mx-6 flex gap-2 flex-col mt-4">
        <Typography variant="heading20">
          <Text value={DEACTIVATE_ENGAGE_CONFIRM_TITLE} />
        </Typography>
        <Typography variant="body14">
          <Text value={DEACTIVATE_ENGAGE_MESSAGE} />
        </Typography>
        <div
          className="self-center flex gap-2 cursor-pointer mt-4 items-center opacity-60"
          aria-hidden
          onClick={e => {
            e.preventDefault();
            open({
              body: <DeactivateConfirmModal close={close} setEngageController={setEngageController} />,
              size: 'max-w-xl',
            });
          }}
        >
          <Typography variant="body14" color="#1F1F1F" cursor="pointer">
            <Text value={PROCEED_TO_DEACTIVATE} />
          </Typography>
          <div className={cx(direction === 'rtl' ? 'rotate-180' : '')}>
            <ChevronsRightIcon width="18" color="black" />
          </div>
        </div>
      </div>
    </div>
  );
};

DeactivateModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.shape({
    size: PropTypes.string,
  }).isRequired,
  setEngageController: PropTypes.func.isRequired,
};

export default DeactivateModal;
