import React, { useState, useContext } from 'react';
import { Button, Typography } from '@zydalabs/zac-react';
import { InfoIcon, CheckCircleIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import {
  ACTIVATE,
  breadcrumbs,
  DEACIVATE_ENGAGE_NOTE,
  DEACTIVATE,
  ENGAGE_LOW_ORDER_NOTE,
  SOMETHING_WENT_WRONG,
  ENGAGE_LOW_ORDER_NOTE_DESC,
} from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Modal } from 'components/kit';
import { Text } from 'components/service';
import { COUNTRY_CODES_ENUM } from 'utils/enums';
import EngageWizard from './EngageWizard';
import ConversationCount from './ConversationCount';
import GeneratedSales from './GeneratedSales';
import RetentionProgress from './RetentionProgress';
import DeactivateModal from './modals/DeactivateModal';
import ActivateModal from './modals/ActivateModal';
import EngageInfoList from './EngageInfoList';
import useCashbackPrograms from './hooks/useCashbackPrograms';
import usePaymentCredentials from './hooks/usePaymentCredentials';

const Engage = () => {
  const { settings, selectedStore, hasRole } = useContext(userContext);
  const { direction, translate } = useContext(localeContext);
  const { engageEnabled } = settings || {};
  const { countryCode } = selectedStore;
  const { cashbackNote } = useCashbackPrograms();
  const { isOnlinePaymentAvailable } = usePaymentCredentials();

  const [totalOrders, setTotalOrders] = useState(0);
  const [engageController, setEngageController] = useState({
    active: engageEnabled,
    registered: false,
  });

  if ((!hasRole('owner') && !hasRole('ops_manager')) || countryCode !== COUNTRY_CODES_ENUM.KUWAIT) {
    return (
      <Layout top={<Breadcrumbs path={breadcrumbs.MARKETING_AUTO_PILOT} />}>
        <Typography variant="body16">{translate(SOMETHING_WENT_WRONG)}</Typography>
      </Layout>
    );
  }

  return (
    <Layout
      top={
        <Breadcrumbs
          path={breadcrumbs.MARKETING_AUTO_PILOT}
          right={
            <Modal>
              {({ open, close }) => (
                <Button
                  rounded
                  variant={engageController.active ? 'secondary' : 'primary'}
                  text={translate(engageController.active ? DEACTIVATE : ACTIVATE)}
                  onClick={e => {
                    e.preventDefault();
                    open({
                      body: engageController.active ? (
                        <DeactivateModal open={open} close={close} setEngageController={setEngageController} />
                      ) : (
                        <ActivateModal
                          open={open}
                          close={close}
                          isOnlinePaymentAvailable={isOnlinePaymentAvailable}
                          setEngageController={setEngageController}
                          cashbackNote={cashbackNote}
                        />
                      ),
                      size: 'max-w-xl',
                    });
                  }}
                />
              )}
            </Modal>
          }
        />
      }
    >
      <>
        <div className="relative">
          {!engageController.active && engageController.registered && (
            <div className="p-4 mt-2 mb-4 bg-zyda-yellow-25 flex" style={{ direction }}>
              <InfoIcon width="20" color="#B48A00" className={direction === 'rtl' ? 'ml-2' : 'mr-2'} />
              <Modal>
                {({ open, close }) => (
                  <Typography variant="body14">
                    <Text
                      className="text-yellow-600 cursor-pointer"
                      value={DEACIVATE_ENGAGE_NOTE}
                      onClick={() => {
                        open({
                          body: (
                            <ActivateModal
                              open={open}
                              close={close}
                              isOnlinePaymentAvailable={isOnlinePaymentAvailable}
                              setEngageController={setEngageController}
                              cashbackNote={cashbackNote}
                            />
                          ),
                          size: 'max-w-xl',
                        });
                      }}
                    />
                  </Typography>
                )}
              </Modal>
            </div>
          )}
          {(engageController.active || engageController.registered) && (
            <>
              <div
                className="p-4 mt-2 mb-4 border border-zyda-green-150 bg-zyda-green-50 flex items-start"
                style={{ direction }}
              >
                <CheckCircleIcon color="#37995D" width="18px" />
                <div className="mx-2">
                  <Typography variant="heading14" mb={4}>
                    <Text value={ENGAGE_LOW_ORDER_NOTE} />
                  </Typography>
                  <Typography variant="body14">
                    <Text value={ENGAGE_LOW_ORDER_NOTE_DESC} />
                  </Typography>
                </div>
              </div>

              <div className="md:flex justify-between items-center p-4 bg-gray-100 gap-7 mb-5">
                <div className="mb-5 md:mb-0 md:w-3/5">
                  <GeneratedSales totalOrders={totalOrders} />
                </div>

                <div className="md:w-2/5">
                  <ConversationCount setTotalOrders={setTotalOrders} />
                </div>
              </div>
              <div className="mb-5">
                <RetentionProgress />
              </div>
            </>
          )}
        </div>
        {!engageController.active && !engageController.registered && <EngageInfoList />}
        <div>
          <EngageWizard
            engageController={engageController}
            setEngageController={setEngageController}
            isOnlinePaymentAvailable={isOnlinePaymentAvailable}
          />
        </div>
      </>
    </Layout>
  );
};

export default Engage;
