import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const OrderDetailsGift = ({ order }) => {
  const { lang, direction } = useContext(localeContext);
  const { userData, gift } = order || {};
  const { recipient } = userData || {};
  const { name, phoneNumber, giftNotes } = recipient || {};

  return (
    recipient &&
    gift && (
      <>
        <div className="mt-2">
          <Typography variant="heading14">
            <Text value={translations.RECIPIENT_INFORMATION} />
          </Typography>
        </div>
        <div className="flex w-full border-b border-gray-300 py-3 mt-2 flex-col">
          <div className="w-full">
            <span className={cx('block text-sm', lang === 'ar' && 'text-right')}>{name}</span>
            <a
              href={`tel:${phoneNumber}`}
              className={cx(lang === 'ar' && 'text-right', 'block text-primary-base text-sm pt-1')}
            >
              {phoneNumber}
            </a>
          </div>
          {giftNotes && (
            <div
              style={{ direction }}
              className="w-full flex items-center border border-blue-200 mt-2 mb-3 py-2 px-3 bg-blue-50"
            >
              <div className={cx(lang === 'ar' ? 'mr-3' : 'ml-3', 'flex items-center whitespace-pre-line')}>
                <Text
                  full={false}
                  value={translations.GIFT_NOTE}
                  className={cx(
                    lang === 'ar' ? 'ml-3' : 'mr-3',
                    'font-semibold text-sm text-primary-base flex-shrink-0',
                  )}
                />
                <Typography variant="body14">{giftNotes}</Typography>
              </div>
            </div>
          )}
        </div>
      </>
    )
  );
};

OrderDetailsGift.propTypes = {
  order: PropTypes.shape({
    gift: PropTypes.string,
    userData: PropTypes.shape({
      recipient: PropTypes.shape({
        name: PropTypes.string,
        phoneNumber: PropTypes.number,
        giftNotes: PropTypes.string,
      }),
    }),
  }),
};
export default OrderDetailsGift;
