import React, { useContext, useRef, useEffect, useState } from "react"
import { useQuery, useLazyQuery } from "@apollo/client"
import { Formik } from "formik"
import { Field, Text } from "components/service"
import { Button, SpinnerAlt, AutoComplete, DropDown } from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { context as modal } from "context/modal"
import { Stepper, MultiCheckbox, CheckboxAlt } from "components/form/elements"
import cx from "classnames"
import * as data from "./data"
import * as schemas from "../schemas"

export default ({ variants, storeId, onCancel, onSubmit, productId }) => {
  const { lang, direction, translate } = useContext(localeContext)
  const { setDivRef } = useContext(modal)
  const notifications = useContext(notificationsContext)
  const [selectedGroup, setSelectedGroup] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const ref = useRef()
  useEffect(() => {
    setDivRef(ref)
  }, [])

  const optionGroups = useQuery(schemas.OPTION_GROUPS, {
    variables: {
      restaurantId: storeId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [
    loadOptions,
    { loading: optionsLoading, data: optionsData, refetch: refetchOptions },
  ] = useLazyQuery(schemas.OPTIONS)

  useEffect(() => {
    loadOptions({
      variables: {
        restaurantId: storeId,
        propertySectionId: selectedGroup,
      },
    })
  }, [selectedGroup])

  return (
    <>
      <Formik
        initialValues={data.initialValues}
        validationSchema={data.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const options = optionsData && optionsData.propertyValues

          if (!options || !options.length) {
            notifications.show(
              <Text value={translations.OPTION_GROUPS_NO_OPTIONS} />,
              "error"
            )
            setSubmitting(false)
            return
          }
          return onSubmit(values)
        }}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <>
              <div
                ref={ref}
                className="px-6 overflow-y-auto"
                style={{ direction, height: "20rem" }}
              >
                <Container>
                  <Row>
                    <Field
                      title={
                        <Text
                          className="font-semibold"
                          value={translations.SELECT_GROUP_VARIANT}
                        />
                      }
                      name="variantIds"
                      testIdSelectAll="product-select-allVariants"
                      testIdOpenList="product-open-GroupVariants"
                      testIdOptionsList="product-list-variants"
                      testIdClearAll="product-clear-allVariants"
                      placeholder={
                        lang === "en"
                          ? translations.CHOOSE_VARIANT[0]
                          : translations.CHOOSE_VARIANT[1]
                      }
                      items={variants.map(item => ({ ...item, id: +item.id }))}
                      component={MultiCheckbox}
                    />
                  </Row>
                  {!optionGroups.data ? (
                    <SpinnerAlt color="primary-base" />
                  ) : (
                    <>
                      <div>
                        {optionGroups.data.propertySections === null ? (
                          <Text
                            className="italic"
                            value={translations.NO_OPTION_GROUP}
                          />
                        ) : (
                          <Row className="flex flex-col">
                            <Text
                              className="font-semibold mb-2"
                              value={translations.SELECT_GROUP}
                            />
                            <DropDown
                              position={direction === "rtl" ? "right" : "left"}
                              placeholder={
                                <Text
                                  className="text-gray-500"
                                  value={translations.INSERT_GROUP_OPTION}
                                />
                              }
                              testId_openDropDown="product-select-group"
                              testId_selectedData="product-dataSelected-group"
                              testId_listData="product-datalisted-group"
                              optionSelected={selectedGroup}
                              onSelect={groupSelected => {
                                setSelectedGroup(groupSelected)
                                let item = optionGroups.data.propertySections.find(
                                  item => item.id === groupSelected
                                )
                                setFieldValue("propertySections", item)
                                setFieldValue("optionsGroup", groupSelected)
                              }}
                              data={optionGroups.data.propertySections.map(
                                option => {
                                  return {
                                    id: option.id,
                                    title: translate(option),
                                  }
                                }
                              )}
                              icon="keyboard_arrow_down"
                              width="w-full"
                              scrollable
                            />
                          </Row>
                        )}
                      </div>
                      {values.optionsGroup &&
                        values.propertySections &&
                        !!values.propertySections.menuItems.length &&
                        values.propertySections.menuItems[0].id !==
                          productId && (
                          <div className="text-gray-500 text-xs mt-2">
                            <Text
                              value={translations.THIS_IS_SHARED}
                              payload={
                                lang === "en"
                                  ? values.propertySections.titleEn
                                  : values.propertySections.titleAr
                              }
                              className="inline"
                            />
                            {values.propertySections.menuItems
                              .filter(item => item.id !== productId)
                              .map(i => {
                                return (
                                  <Text
                                    prefix={"["}
                                    value={i}
                                    postfix={"]"}
                                    className="inline"
                                  />
                                )
                              })}
                          </div>
                        )}

                      {values.optionsGroup && optionsData && (
                        <Row spacing="0">
                          <Options
                            items={
                              optionsData && (optionsData.propertyValues || [])
                            }
                            setFieldValue={setFieldValue}/>
                        </Row>
                      )}
                    </>
                  )}

                  <Row className="bg-zyda-grey-50 p-2">
                    <CheckboxAlt
                      name="multipleAddOnsEnabled"
                      title={
                        <Text
                          className="text-gray-600"
                          value={translations.MULTIPLE_ADD_ON_CHECKBOX}
                        />
                      }
                      value={values.multipleAddOnsEnabled}
                      onChange={e =>
                        setFieldValue("multipleAddOnsEnabled", e.target.value)
                      }
                    />
                  </Row>
                  <Row expandItem={false} spacing="0" direction="col">
                    <Text
                      className="font-semibold mb-2"
                      value={translations.SET_SELECTION_QUANTITY}
                    />
                    <Text
                      className="text-xs text-gray-600 mb-4"
                      value={translations.SET_SELECTION_QUANTITY_NIM_MAX}
                    />
                    <div className="flex flex-col md:flex-row">
                      <span className="w-full flex flex-col mb-4 md:mb-0">
                        <Text
                          value={translations.MINIMUM_SELECTION}
                          className="text-sm"
                        />
                        <Field
                          min={0}
                          type="number"
                          name="minQuantity"
                          width="full"
                          component={Stepper}
                          validation={n => n >= 0}
                        />
                      </span>
                      <span className="w-full flex flex-col md:mx-4">
                        <Text
                          value={translations.MAXIMUM_SELECTION}
                          className="text-sm"
                        />
                        <Field
                          min={0}
                          type="number"
                          name="maxQuantity"
                          width="full"
                          component={Stepper}
                          validation={n => n >= 0}
                        />
                      </span>
                    </div>
                  </Row>
                </Container>
              </div>
              <Footer modalFooter>
                <div className="w-full flex items-center">
                  <div className="ml-auto">
                    <Row direction="row">
                      <Button onClick={onCancel} type="button" kind="tertiary">
                        <Text value={translations.CANCEL} />
                      </Button>
                      <Button
                        isSpinning={isSubmitting}
                        onClick={() => submitForm()}
                        kind="primary"
                        data-testid="product-insertGroup-save"
                      >
                        <Text value={translations.INSERT} />
                      </Button>
                    </Row>
                  </div>
                </div>
              </Footer>
            </>
          )
        }}
      </Formik>
    </>
  )
}

const Options = ({ items , setFieldValue}) => {
  const { selectedStore: selected } = useContext(userContext)
  const currency = selected.currency
  const { lang, translate } = useContext(localeContext)

  useEffect(()=>{
    items.length && setFieldValue('maxQuantity', items.length)
  },[items])

  if (!items.length)
    return <Text className="text-sm mt-1" value={translations.NO_OPTIONS} />

  return (
    <Label title={<Text className="text-sm mt-1" value={translations.OPTIONS} />}>
      <div className="w-full rounded border text-sm">
        {items.map(item => (
          <div className="flex p-3 border-b" key={item.id}>
            <span>{translate(item)}</span>
            <span
              className={cx(
                lang === "en" ? "ml-auto" : "mr-auto",
                "text-gray-500"
              )}
            >
              <Text className="text-sm" postfix={item.price} value={currency} />
            </span>
          </div>
        ))}
      </div>
    </Label>
  )
}
