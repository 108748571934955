import { apiMutate } from 'service/utils/mutation';

import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { CreateBulkDeliveryZonesInput, CreateBulkDeliveryZonesPayload } from '../../types/generated';
import { createBulkDeliveryZoneMutation } from '../../mutations';

type useCreateBulkDeliveryZoneType = () => ({
  input,
}: {
  input?: CreateBulkDeliveryZonesInput;
}) => Promise<void | Partial<CreateBulkDeliveryZonesPayload> | { hasError: boolean }>;

const useCreateBulkDeliveryZone: useCreateBulkDeliveryZoneType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return input =>
    apiMutate({
      mutation: createBulkDeliveryZoneMutation,
      variables: { input },
      clientName: GRAPHQL_CLIENT_NAMES.DELIVERY_ZONES,
      requestHeaders: { token: deliveryZoneMsToken },
      handler: (res: any) => res,
    });
};

export default useCreateBulkDeliveryZone;
