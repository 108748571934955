import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { Accordion, Tag } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';

import { useMobile, useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import RefundOptionsModal from 'components/common/orders/RefundOptions/RefundOptionsModal';
import { Spinner, Modal, Button } from 'components/kit';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import * as schemas from '../../schemas';
import { isCourierAvailable, generateWhatsAppUrl } from '../helpers';
import RefundInfo from '../RefundInfo';
import OrderDetailsUserCar from './OrderDetailsUserCar';
import OrderDetailsItems from './OrderDetailsItems';
import OrderDetailsUserAddress from './OrderDetailsUserAddress';
import OrderDetailsUserContact from './OrderDetailsUserContact';
import OrderDetailsStatusHistory from './OrderDetailsStatusHistory';
import OrderDetailsFees from './OrderDetailsFees';
import OrderDetailsPrint from './OrderDetailsPrint';
import OnFleetDeliveryActionButtons from './verdSection/OnFleetDeliveryActionButtons';
import OrderDetailsGift from './OrderDetailsGift';
import OrderDetailsPaymentInfo from './OrderDetailsPaymentInfo';
import { CancelOrder } from './OrderDetailsStatus';
import { DELIVERY_ENUMS } from '../../constants';
import { REFUND_TYPE_ENUM } from './OrderDetailsStatus/CancelOrderBody/data';
import OrderDetailsUmbrella from './OrderDetailsUmbrella';
import OrderDetailsPayments from './OrderDetailsPayments';
import DeliveryFees from './DeliveryFees';

const OrderDetails = ({ id, ordersVariables, OrderActionButton }) => {
  const storeId = useSelectedStore();
  const user = useContext(userContext);
  const order = useQuery(schemas.ORDER, { variables: { storeId, orderId: id } });
  const {
    selectedStore: { restaurantCourierSetting },
    settings,
  } = user;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );
  const isVerdEnabled = settings?.enableVerd;

  const { lang, translate, direction } = useContext(localeContext);
  const isMobile = useMobile();
  const orderData = order?.data?.order;
  const {
    id: orderId,
    number,
    userData = {},
    total,
    beachUmbrella,
    deliveryCourier,
    paidByWallet,
    paidByCreditCard,
    status: orderStatus,
    updatingStatus,
    deliveryCourierId,
    deliveryType,
    status,
    paidThrough,
    deliveryZone,
    typeOfRefund,
    paymentStatus,
    orderItems,
  } = orderData || {};

  const { area: { lat: areaLat, lng: areaLng } = {} } =
    userData.address && userData.address.area ? userData.address : {};

  const { lat, lng } = userData.address ? userData.address : {};

  const userLat = lat || areaLat;
  const userLng = lng || areaLng;
  const addressGMapURL = userLat && userLng && `https://www.google.com/maps/search/?api=1&query=${userLat},${userLng}`;
  const isBulkChange = updatingStatus?.orderGettingUpdated;
  const isOrderStatusCancelled = status === ORDER_STATUS.CANCELED;
  const isDeliveryOrder = deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL;
  const {
    selectedStore,
    isPosCourier: isPosBusiness,
    isDeliveryCourier: isDeliveryBusiness,
    courier,
    legalData,
  } = useContext(userContext);

  const { currency } = selectedStore;
  const orderCurrency = lang === 'en' ? currency.titleEn : currency.titleAr;

  const orderTotalWithCurrecy = `${total} ${orderCurrency}`;
  const { zoneName } = deliveryZone || {};

  const orderWhatsAppUrl =
    orderData &&
    generateWhatsAppUrl({
      lang,
      currency,
      number,
      total,
      userData,
      addressGMapURL,
      paidThrough,
      zoneName,
    });

  const hasCourier = isDeliveryBusiness && deliveryCourierId;

  const canBeSendToCourier =
    orderData &&
    isCourierAvailable({
      deliveryType,
      restaurantCourierSetting: courier,
      isDeliveryBusiness,
    });

  return (
    <Modal>
      {({ open, close }) =>
        order.loading ? (
          <Spinner />
        ) : (
          <>
            <div
              className={cx('flex', lang === 'ar' && 'flex-row-reverse', 'flex-wrap items-start bg-gray-100 p-4')}
              data-testid="order-item-body"
            >
              <div
                className={cx(
                  'sm:w-full md:w-3/5 p-4 flex flex-col bg-white items-start',
                  lang === 'ar' ? 'md:pl-3' : 'md:pr-3',
                  isMobile && 'mb-4',
                )}
              >
                <span className="text-base font-semibold w-full mb-4">
                  <Text value={translations.ITEMS} payload={orderItems?.length} />
                </span>
                <OrderDetailsItems order={orderData} currency={currency} />
                <OrderDetailsFees order={orderData} currency={currency} deliveryType={deliveryType} />
                <div className="w-full flex flex-col">
                  <div className="w-full mt-4 flex justify-between gap-4" style={{ direction }}>
                    <OrderDetailsPayments
                      lang={lang}
                      paidThrough={paidThrough}
                      paidByWallet={paidByWallet}
                      paidByCreditCard={paidByCreditCard}
                      currency={currency}
                    />
                    <div className="mb-4 flex-1">
                      <OrderDetailsPrint
                        order={orderData}
                        canBeSendToCourier={canBeSendToCourier}
                        selected={selectedStore}
                        legalData={legalData}
                        isDeliveryBusiness={isDeliveryBusiness}
                        deliveryCourier={deliveryCourier}
                        courierDetails={courier?.courierDetails}
                        orderStatus={orderStatus}
                      />
                    </div>
                  </div>
                  <DeliveryFees order={orderData} currency={currency} />
                </div>

                <div className="flex flex-col w-full mt-4">
                  {[
                    REFUND_TYPE_ENUM.CREDIT_CARD,
                    REFUND_TYPE_ENUM.ORDERFAST_WALLET,
                    REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
                  ].includes(typeOfRefund) && <RefundInfo {...orderData} lang={lang} />}

                  <div
                    className={cx(
                      'flex',
                      lang === 'ar' && 'flex-row-reverse',
                      isMobile && 'flex-wrap-reverse md:flex-nowrap',
                      'justify-between',
                    )}
                  >
                    {(user.hasRole('owner') || user.hasRole('ops_manager')) &&
                      status === ORDER_STATUS.CANCELED &&
                      [ORDER_STATUS.PAID, ORDER_STATUS.REFUND_FAILED].includes(paymentStatus) &&
                      ![
                        REFUND_TYPE_ENUM.CREDIT_CARD,
                        REFUND_TYPE_ENUM.ORDERFAST_WALLET,
                        REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
                      ].includes(typeOfRefund) &&
                      !['CARDONDELIVERY', 'Cash', 'CASH'].includes(paidThrough) && (
                        <Button
                          onClick={() =>
                            open({
                              title: (
                                <Text
                                  value={translations.REFUND_OPTIONS_MODAL_TITLE(number, orderTotalWithCurrecy)}
                                  className="text-black font-medium"
                                />
                              ),
                              body: (
                                <RefundOptionsModal
                                  open={open}
                                  close={close}
                                  lang={lang}
                                  orderPaidThrough={paidThrough}
                                  orderId={orderId}
                                  orderNumber={number}
                                  currency={orderCurrency}
                                  orderTotal={total}
                                />
                              ),
                              size: 'max-w-sm',
                            })
                          }
                          kind="tertiary"
                          textColor="text-red-600"
                          size="base"
                        >
                          <Text value={translations.REFUND_ORDER} />
                        </Button>
                      )}
                  </div>
                </div>
                <OrderDetailsPaymentInfo order={orderData} selected={selectedStore} />
              </div>
              <div className={cx('md:w-2/5 sm:w-full', lang === 'ar' ? 'md:pr-3' : 'md:pl-3')}>
                <div className="flex flex-col">
                  <div className="bg-white p-2" style={{ direction }}>
                    <Accordion
                      accordionItems={[
                        {
                          title: <Text value={translations.CONTACT_INFORMATION} className="text-base cursor-pointer" />,
                          content: (
                            <>
                              <OrderDetailsUserContact order={orderData} />
                              <OrderDetailsGift order={orderData} />
                              {beachUmbrella && <OrderDetailsUmbrella order={orderData} />}
                              <OrderDetailsUserAddress
                                order={orderData}
                                addressGMapURL={addressGMapURL}
                                orderWhatsAppUrl={orderWhatsAppUrl}
                              />
                              <OrderDetailsUserCar order={orderData} deliveryType={deliveryType} />
                            </>
                          ),
                          isExpanded: true,
                        },
                      ]}
                      size="small"
                      topDivider={false}
                      bottomDivider={false}
                    />
                  </div>
                  {isDeliveryOrder &&
                    !isOrderStatusCancelled &&
                    (isVerdEnabled || filteredRestaurantCourierSetting.length > 0) && (
                      <div className="bg-white p-2 mt-2" style={{ direction }}>
                        <Accordion
                          accordionItems={[
                            {
                              title: (
                                <div className="flex items-center">
                                  <Text value={translations.DELIVERY_BY_VERD} className="text-base cursor-pointer" />
                                  <div className="mx-2">
                                    <Tag
                                      color="warning"
                                      inner={{
                                        text: translate(translations.NEW),
                                      }}
                                      size="medium"
                                      className="cursor-pointer"
                                    />
                                  </div>
                                </div>
                              ),
                              content: <OnFleetDeliveryActionButtons order={orderData} />,

                              isExpanded: true,
                            },
                          ]}
                          size="small"
                          topDivider={false}
                          bottomDivider={false}
                        />
                      </div>
                    )}

                  <div className="bg-white p-2 mt-2" style={{ direction }}>
                    <Accordion
                      accordionItems={[
                        {
                          title: <Text value={translations.ORDER_TIMELINE} className="text-base cursor-pointer" />,
                          content: (
                            <OrderDetailsStatusHistory
                              order={orderData}
                              selected={selectedStore}
                              isDeliveryBusiness={isDeliveryBusiness}
                              hasCourier={hasCourier}
                            />
                          ),

                          isExpanded: false,
                        },
                      ]}
                      size="small"
                      topDivider={false}
                      bottomDivider={false}
                    />
                  </div>
                </div>
              </div>
              <div className={cx('sm:w-full md:w-3/5 mt-2 flex justify-between', lang === 'ar' && 'flex-row-reverse')}>
                <CancelOrder
                  isPosBusiness={isPosBusiness}
                  order={orderData}
                  isBulkChange={isBulkChange}
                  open={open}
                  ordersVariables={ordersVariables}
                  close={close}
                  status={status}
                  hasCourier={hasCourier}
                  isMobile={isMobile}
                  lang={lang}
                />
                {OrderActionButton}
              </div>
            </div>
          </>
        )
      }
    </Modal>
  );
};

OrderDetails.propTypes = {
  status: PropTypes.string,
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  id: PropTypes.number.isRequired,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  deliveryType: PropTypes.string,
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
  newOrders: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.number,
    sort: PropTypes.objectOf({ order: PropTypes.string, field: PropTypes.string }),
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
};
export default OrderDetails;
