import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'components/service';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { DropDown } from 'components/kit';
import { useMobile } from 'hooks/index';

export default ({
  items,
  stateless = false,
  selectedItem,
  collapsedItems,
  ordersVariables,
  scrollable = false,
  rightItems,
  bgColor,
  isMenu = false,
  isProduct = false,
}) => {
  const { lang, direction } = useContext(localeContext);
  const linkClassName = cx(
    'cursor-pointer pb-2 flex border-b-4 hover:border-primary-base -mb-px',
    isMenu ? 'mx-3 px-1' : 'px-1',
    isProduct && 'mx-3',
  );
  const selectedClassName = 'min-w-max border-primary-base text-primary-base font-semibold active-link';
  const unselectedClassName = 'min-w-max border-transparent';
  const [flatTabItems, setFlatTabItems] = useState(items ? [...items] : []);
  const [rightTabItems, setrightFlatTabItems] = useState(rightItems ? [...rightItems] : []);
  const [moreTabItems, setMoreTabItems] = useState(collapsedItems ? [...collapsedItems] : []);
  const [reloadTabs, setReloadTabs] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    setFlatTabItems(items ? [...items] : []);
  }, [items]);

  useEffect(() => {
    setrightFlatTabItems(rightItems ? [...rightItems] : []);
  }, [rightItems]);

  useEffect(() => {
    const handleResize = () => {
      setFlatTabItems(items ? [...items] : []);
      setrightFlatTabItems(rightItems ? [...rightItems] : []);
      setMoreTabItems(collapsedItems ? [...collapsedItems] : []);
      setReloadTabs(true);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [lang, ordersVariables]);

  useEffect(() => {
    if (reloadTabs === true) {
      const tabsContainer = document.querySelector('#tabs-container');
      const tabsContainerWidth = tabsContainer.offsetWidth;
      const moreTab = document.querySelector('#dropdown-tab');
      let stopWidth = moreTab && moreTab.offsetWidth;
      let spliceIndex = -1;

      if (moreTab && stopWidth) {
        items.forEach((item, i) => {
          let currentTab = document.querySelector(`#${item.id}`);
          if (spliceIndex < 0 && currentTab) {
            if (tabsContainerWidth >= stopWidth + currentTab.offsetWidth) {
              stopWidth += currentTab.offsetWidth;
            } else {
              spliceIndex = i;
            }
          }
        });
      }

      if (spliceIndex > -1) {
        const removedItems = flatTabItems.splice(spliceIndex);
        setMoreTabItems(removedItems.concat(moreTabItems));
      }
      setReloadTabs(false);
      tabsContainer.classList.remove('hidden');
    }
  }, [reloadTabs]);

  return (
    <div
      style={{ direction }}
      className={cx(
        'flex max-w',
        scrollable ? 'flex-nowrap overflow-x-scroll overflow-y-hidden' : 'flex-wrap',
        isProduct ? 'visible' : 'hidden',
        'text-zyda-black-100 border-b border-gray-300',
        !isMobile && 'justify-between',
      )}
      id="tabs-container"
    >
      <div className="flex tabs min-w-max gap-0.5">
        {flatTabItems.map((item, i) =>
          !stateless ? (
            <Link
              data-testid={`stockManagement-tabs-${i}`}
              key={i}
              to={item.to}
              appendQuery
              id={item.id}
              getProps={({ className, isCurrent, isPartiallyCurrent }) => ({
                className: cx(
                  bgColor && `${bgColor} pt-3`,
                  linkClassName,
                  i === 0 && isMenu && (direction == 'rtl' ? 'mr-8' : isMobile ? 'ml-5' : 'ml-8'),
                  isCurrent || isPartiallyCurrent ? selectedClassName : unselectedClassName,
                  `px-5`,
                ),
              })}
            >
              {item.title}
            </Link>
          ) : (
            <span
              key={i}
              id={item.id}
              className={cx(linkClassName, item.selected ? selectedClassName : unselectedClassName)}
              onClick={item.onClick}
            >
              {item.title}
            </span>
          ),
        )}
        {moreTabItems && !!moreTabItems.length && (
          <span
            className={cx(
              linkClassName,
              bgColor && `${bgColor} pt-3`,
              moreTabItems.findIndex(item => item.id === selectedItem) > -1 ? selectedClassName : unselectedClassName,
              `px-5`,
            )}
            id="dropdown-tab"
          >
            <DropDown
              noBorder
              noMargin
              isTab
              shadow
              wFull
              float={lang !== 'ar' ? (isMobile ? 'right' : 'left') : isMobile ? 'left' : 'right'}
              position={lang !== 'ar' ? (isMobile ? 'right' : 'left') : isMobile ? 'left' : 'right'}
              placeholder={<Text value={translations.MORE} />}
              optionSelected={moreTabItems.findIndex(item => item.id === selectedItem) > -1 ? selectedItem : null}
              onSelect={option => {
                moreTabItems.find(item => item.id === option).onSelect();
              }}
              data={moreTabItems}
              icon="keyboard_arrow_down"
            />
          </span>
        )}
      </div>
      <div className={cx('flex', lang == 'ar' ? 'ml-6' : 'mr-6')}>
        {rightTabItems.map((item, i) =>
          !stateless ? (
            <Link
              data-testid={`stockManagement-tabs-${i}`}
              key={i}
              to={item.to}
              appendQuery
              id={item.id}
              getProps={({ className, isCurrent, isPartiallyCurrent }) => ({
                className: cx(
                  linkClassName,
                  i === 1 && isMobile && isMenu && (direction == 'rtl' ? 'pl-6' : 'pr-6'),
                  isCurrent || isPartiallyCurrent ? selectedClassName : unselectedClassName,
                ),
              })}
            >
              {item.title}
            </Link>
          ) : (
            <span
              key={i}
              id={item.id}
              className={cx(linkClassName, item.selected ? selectedClassName : unselectedClassName)}
              onClick={item.onClick}
            >
              {item.title}
            </span>
          ),
        )}
      </div>
    </div>
  );
};
