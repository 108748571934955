import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';

import { SET_AS_BUSY, SET_BRANCH_BUSY, SET_AVAILABILITY, EDIT_AVALIABILITY } from 'constants/translations';
import { Text } from 'components/service';
import EditBranchesAvailability from 'components/common/branch/Busy/EditBranchesAvailability';
import SetAsBusy from 'components/common/branch/Busy/SetAsBusy';
import { context as localeContext } from 'context/locale';

const OrderFrameSetAsBusyButton = ({
  isEditAvailablityButton,
  lang,
  open,
  selectedBranch,
  branches,
  selectedStore,
  close,
  setIsBusy,
}) => {
  const { translate } = useContext(localeContext);

  return (
    <Button
      text={translate(isEditAvailablityButton ? EDIT_AVALIABILITY : SET_AS_BUSY)}
      size="medium"
      variant={isEditAvailablityButton ? 'ghost' : 'tertiary'}
      data-testid={isEditAvailablityButton ? 'edit-avalibility-btn' : 'set-busy-btn'}
      onClick={() =>
        open({
          title: (
            <div className={cx('flex justify-between items-center', lang === 'ar' && 'flex-row-reverse')}>
              <div className="flex flex-col">
                <Text
                  value={!selectedBranch ? SET_AS_BUSY : SET_BRANCH_BUSY}
                  payload={selectedBranch && (lang === 'en' ? selectedBranch.titleEn : selectedBranch.titleAr)}
                />
                <Text className="text-xs text-zyda-grey-500 font-normal" value={SET_AVAILABILITY} />
              </div>
            </div>
          ),
          body: !selectedBranch ? (
            <EditBranchesAvailability
              branches={branches}
              lang={lang}
              timeZone={selectedStore.timeZone}
              open={open}
              close={close}
              setIsBusy={setIsBusy}
            />
          ) : (
            <SetAsBusy
              branchId={selectedBranch.id}
              onCancel={close}
              setIsBusy={setIsBusy}
              busyModeTranslated={selectedBranch.id && selectedBranch.busyMode}
            />
          ),
          size: 'max-w-md',
        })
      }
    />
  );
};

export default OrderFrameSetAsBusyButton;

OrderFrameSetAsBusyButton.propTypes = {
  isEditAvailablityButton: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  open: PropTypes.func.isRequired,
  selectedBranch: PropTypes.shape({ titleEn: PropTypes.string, titleAr: PropTypes.string, id: PropTypes.string })
    .isRequired,
  selectedStore: PropTypes.shape({ timeZone: PropTypes.string }).isRequired,
  close: PropTypes.func.isRequired,
  setIsBusy: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf({
    busy: PropTypes.bool,
    busyFrom: PropTypes.string,
    busyMode: PropTypes.string,
    busyUntil: PropTypes.string,
    deliveryOrdersEnabled: PropTypes.bool,
    externalId: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    pickupOrdersEnabled: PropTypes.bool,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }).isRequired,
};
