import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import { useMutation, useQuery } from '@apollo/client';

import { context as localeContext } from 'context/locale';
import { useSelectedStore, useDeliveryZoneMsToken } from 'hooks';
import { Footer, Label } from 'components/form/generic';
import { Select } from 'components/form/elements';
import { Field, Text } from 'components/service';
import { Button, Spinner, Stack } from 'components/kit';
import { context as notificationsContext } from 'context/notifications';
import { IMPORT_PRODUCTS_FROM_POS, GET_LIST_OF_TAGS } from '../List/schemas';
import { SUCCESS_IMPORT_CATALOG_FROM_FOODICS, CANCEL, SAVE } from 'constants/translations';

const SyncMenuForm = ({ onCancel }) => {
  const { lang, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const { data, loading } = useQuery(GET_LIST_OF_TAGS, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    variables: { storeId, posName: 'foodics', partnerType: 'pos' },
  });

  const [importProductsFromFoodics] = useMutation(IMPORT_PRODUCTS_FROM_POS, {
    onCompleted: data => {
      notifications.show(<Text value={SUCCESS_IMPORT_CATALOG_FROM_FOODICS} />, 'info');
    },
    onError: error => {
      const msg = error?.graphQLErrors[0]?.extensions?.exception?.body?.message;
      notifications.show(msg ? msg : 'Unexpected error', 'error');
    },
  });

  const onSubmitSyncMenu = async values => {
    try {
      await importProductsFromFoodics({
        variables: { storeId, menuGroupId: values.menuGroupId },
      });
    } catch (error) {
      console.log(error);
    }
    onCancel();
  };

  return loading ? (
    <Spinner />
  ) : data?.tagsList ? (
    <Formik key="sync-branches-formik" onSubmit={onSubmitSyncMenu} initialValues={{ menuGroupId: '' }}>
      {({ isSubmitting, handleChange }) => (
        <Form key="sync-branches-form">
          <div className="px-4 flex">
            <Label title={<Text value={data.tagsList} />}>
              <Field
                name="menuGroupId"
                onChange={e => {
                  handleChange(e);
                }}
                component={Select}
                type="squared"
                required
              >
                {data.tagsList.results.map((item, i) => (
                  <option key={i} value={item.id}>
                    {translate(item)}
                  </option>
                ))}
              </Field>
            </Label>
          </div>
          <Footer modalFooter>
            <div className={cx(lang === 'ar' ? 'mr-auto' : 'ml-auto')}>
              <Stack direction="row">
                <Button data-testid="cancel-sort-catalog-btn" onClick={onCancel} type="button" kind="tertiary">
                  <Text value={CANCEL} />
                </Button>
                <Button
                  data-testid="save-sort-catalog-btn"
                  kind="primary"
                  isSpinning={isSubmitting}
                  disabled={isSubmitting}
                >
                  <Text value={SAVE} />
                </Button>
              </Stack>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  ) : (
    <div>Empty State</div>
  );
};

SyncMenuForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default SyncMenuForm;
