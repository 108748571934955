import React, { useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Typography, Button, useTheme } from '@zydalabs/zac-react';
import { MapPinIcon, GridSquare3X3Icon } from '@zydalabs/zac-icons-react';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import OrderRiderStatus from 'components/common/verd/OrderRiderStatus';
import { ShimmerCircle, SpinnerAlt } from 'components/kit';
import { ORDER_STATUS } from 'constants/order';
import { EXTERNAL_ORDER_IDENTIFIER_TYPE } from '../../../constants';

const StoreCourierInfo = ({
  order,
  deliveryOrderStatus,
  driverName,
  referenceId,
  hasDriverInfo,
  driverAssigned,
  orderDeliveryPhoneNumber,
  trackingLink,
  isScheduledOrder,
  firingTime,
  assignedCourier,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const isArabic = lang === 'ar';
  const { selectedStore, isDeliveryCourier } = useContext(userContext);
  const { colors } = useTheme();
  const { status: orderStatus, deliveryCourier } = order || {};
  const { externalOrderIdentifierLink, externalOrderIdentifierType } = deliveryCourier || {};
  const shouldShowQrCodeButton =
    isDeliveryCourier &&
    externalOrderIdentifierLink &&
    orderStatus !== ORDER_STATUS.CANCELED &&
    externalOrderIdentifierType?.toLowerCase() === EXTERNAL_ORDER_IDENTIFIER_TYPE.QRCODE;

  return (
    <div className={cx('bg-gray-100 mb-4', shouldShowQrCodeButton ? 'pb-2' : 'pb-4')}>
      <div className="bg-gray-100 flex justify-between items-center px-3 py-4">
        <div className="flex items-center">
          <div className="w-6 h-6">
            {!assignedCourier?.logoUrl ? (
              <ShimmerCircle />
            ) : (
              <img
                className="rounded-full border border-gray-100 shadow-sm"
                src={assignedCourier?.logoUrl}
                alt="courier logo"
              />
            )}
          </div>
          <Typography variant="heading14" mx={12}>
            {isArabic ? assignedCourier?.courierDetails?.displayNameAr : assignedCourier?.courierDetails?.displayNameEn}
          </Typography>
        </div>
        {trackingLink && (
          <Button
            variant="ghost"
            size="small"
            href={trackingLink}
            onClick={() => window.open(trackingLink, '_blank')}
            startIcon={<MapPinIcon width="20px" color={colors.accent.primary} />}
            text={translate(translations.TRACK_RIDER)}
          />
        )}
      </div>
      {isScheduledOrder && deliveryOrderStatus === ORDER_STATUS.PENDING ? (
        <div className={cx('flex justify-between p-4 mx-3 mb-4 bg-white', isArabic ? 'flex-col-reverse' : 'flex-col')}>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <Typography variant="heading12" mb={8}>
                {translate(translations.REQUEST_STATUS)}
              </Typography>
              <Typography variant="element16" color={colors.orange[600]}>
                {translate(translations.WILL_BE_ASSIGNED)}
              </Typography>

              <div className="flex">
                <span className="text-zyda-grey-500 text-xs">
                  {moment(firingTime)
                    .tz(selectedStore.timeZone)
                    .locale(isArabic ? 'ar' : 'en-gb')
                    .format('h:mm A, DD MMM YYYY')}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between p-4 mx-3 bg-white">
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <Typography variant="heading12" mb={8}>
                {translate(translations.REQUEST_STATUS)}
              </Typography>
              <OrderRiderStatus status={deliveryOrderStatus} lang={lang} />
            </div>
            {hasDriverInfo && deliveryOrderStatus !== ORDER_STATUS.DECLINED && (
              <div className="flex flex-col mt-2">
                <Typography variant="heading12" mb={8}>
                  {translate(translations.DRIVER_NAME)}
                </Typography>
                <div className="flex items-center">
                  {!driverAssigned && (
                    <div className="flex">
                      <span className={cx('w-4 h-4', direction === 'rtl' ? 'ml-2' : 'mr-2')}>
                        <SpinnerAlt color="primary-base" />
                      </span>
                    </div>
                  )}
                  {driverAssigned ? (
                    <div className="flex">
                      <Typography variant="element16">{driverName}</Typography>
                    </div>
                  ) : (
                    <Text value={translations.SEARCHING_FOR_A_DRIVER} />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <Typography variant="heading12" mb={8}>
                {translate(translations.REQUEST_ID)}
              </Typography>
              <div className="flex">
                <Typography variant="element16">{referenceId || '-'}</Typography>
              </div>
            </div>
            {driverAssigned && (
              <div className="flex flex-col mt-2">
                <Typography variant="heading12" mb={8}>
                  {translate(translations.PHONE_NUMBER)}
                </Typography>
                <div className="flex">
                  <Typography variant="element16">{orderDeliveryPhoneNumber || '-'}</Typography>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {shouldShowQrCodeButton && (
        <div className="mt-2">
          <Button
            onClick={() => window.open(externalOrderIdentifierLink, '_blank')}
            variant="ghost"
            size="small"
            startIcon={<GridSquare3X3Icon width="20px" color={colors.accent.primary} />}
            text={translate(translations.OPEN_QRCODE)}
          />
        </div>
      )}
    </div>
  );
};

export default StoreCourierInfo;
