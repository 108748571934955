import { gql } from 'graphql-request';

const restaurantTopSellingProducts = gql`
  {
    restaurantAnalyticsSummary {
      topSellingProducts {
        photoUrl
        quantitySold
        titleAr
        titleEn
        totalOrders
        totalSales
      }
    }
  }
`;

export default restaurantTopSellingProducts;
