import { GraphQLClient } from 'graphql-request';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';

const deliveryZonesClient = new GraphQLClient(`${process.env.REACT_APP_API_GATEWAY_URL}/v1/delivery_zones/graphql`);
const verdClient = new GraphQLClient(`${process.env.REACT_APP_API_GATEWAY_URL}/v1/delivery/graphql`);
const backendClient = new GraphQLClient(process.env.REACT_APP_BACKEND_GRAPHQL_SERVER);

const getClient = (clientName: string): any => {
  switch (clientName) {
    case GRAPHQL_CLIENT_NAMES.VERD:
      return verdClient;
    case GRAPHQL_CLIENT_NAMES.DELIVERY_ZONES:
      return deliveryZonesClient;
    case GRAPHQL_CLIENT_NAMES.BACKEND:
      return backendClient;
    default:
      return backendClient;
  }
};

export default getClient;
