import { gql } from '@apollo/client';

export const SUBSCRIPTION = gql`
  query($storeId: String!) {
    subscription(restaurantId: $storeId) {
      id
      status
      total
      expiresAt
      goActiveAt
      serviceData {
        interval
      }
      countryPlan {
        plan {
          titleEn
        }
      }
      invoices {
        createdAt
      }
    }
  }
`;

export const LIST = gql`
  query($sort: RestaurantSorter) {
    restaurants(sort: $sort) {
      id
      titleEn
      titleAr
      published
      logoUrl
      subscriptionPlanType
    }
  }
`;

export const PERMISSION = gql`
  query($storeId: String!) {
    campaignsPermission(restaurantId: $storeId)
  }
`;

export const USER = gql`
  query($storeId: String!, $id: String!) {
    user(restaurantId: $storeId, id: $id) {
      id
      name
      email
      phoneNumber
      isSupport
      createdAt
      intercomUserHash
      roles {
        role
        resourceId
      }
      storeRingerSetting {
        ringerType
      }
    }
    branches(restaurantId: $storeId) {
      id
      titleEn
      titleAr
      lat
      lng
      busy
      busyFrom
      busyUntil
      busyMode
      deliveryOrdersEnabled
      pickupOrdersEnabled
      externalId
    }
    restaurant(id: $storeId) {
      id
      createdAt
      titleEn
      titleAr
      descriptionEn
      descriptionAr
      fbadTrackId
      defaultProductPrepTime
      gaTrackId
      snapTrackId
      gtmTrackId
      capiAccessToken
      taxPercentage
      taxInclusive
      countryId
      isStockEnabled
      published
      logoUrl
      timeZone
      countryLat
      countryLng
      fullUrl
      subdomain
      countryCode
      paymentMethods
      campaignsSmsCredit
      isFbeConnected
      fbeBusinessManagerId
      blockTabs
      monthlyTotalSales
      deploymentLocation
      isCommissionBased
      subscriptionPlanType
      currency {
        decimals
        titleEn
        titleAr
      }
      preferredLanguage
      restaurantCourierSetting {
        courierId
        isOtherDeliveryEnabled
        supportMsValidationRequest
        businessType
        supportCancellation
        courierDetails {
          id
          name
          country
          description
          supportNumber
          businessId
          businessName
          displayNameEn
          displayNameAr
        }
        isManualStatusUpdate
        isInternalDelivery
        driverMaxCapacity
        authenticationType
        isMenuPull
        isBranchListingEnabled
        isTaxInclusive
        isFeeFromCourier
        isTimeFromCourier
        supportZoneMapping
        default
        showRiderModal
        autoAssignMechanism
        assignmentFlow
        networkFees
      }
    }
    settings(restaurantId: $storeId) {
      id
      scheduleInterval
      scheduleMaxTimePeriod
      scheduleOrdersEnabled
      isAutoAccept
      dashboardLayout
      isImportingPosMenu
      enableVerd
      showShareWhatsapp
      useDeliveryzonesMs
      defaultProductPrepTime
      onlinePaymentEnabled
      engageEnabled
      printingType
      printingLanguage
    }
    invoices(restaurantId: $storeId, status: "unpaid", page: 1, limit: 1) {
      id
      paymentUrl
    }
    legalData(restaurantId: $storeId) {
      companyNameEn
      companyNameAr
      vatIdNumber
    }
  }
`;

export const UPDATE_PRINTING_PARAMS = gql`
  mutation($restaurantId: String!, $printingType: String!, $printingLanguage: String!) {
    updatePrintingParams(
      restaurantId: $restaurantId
      printingType: $printingType
      printingLanguage: $printingLanguage
    ) {
      printingType
      printingLanguage
    }
  }
`;
