import React from 'react';
import PropTypes from 'prop-types';
import { UmbrellaIcon, StoreIcon } from '@zydalabs/zac-icons-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'style.css';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { ReactComponent as DeliveryIcon } from 'assets/delivery-icon.svg';
import { ReactComponent as Gift } from 'assets/gift.svg';
import { ReactComponent as Phone } from 'assets/phone-income.svg';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import getOrderStatusTranslation from 'utils/getOrderStatusTranslation';
import { ORDER_STATUS } from 'constants/order';

const getOrderIconColor = deliveryStatus => {
  switch (deliveryStatus) {
    case ORDER_RIDER_STATUS.SUBMITTED:
    case ORDER_RIDER_STATUS.PENDING:
      return 'orange';
    case ORDER_RIDER_STATUS.DECLINED:
      return 'red';
    case ORDER_RIDER_STATUS.ASSIGNED:
    case ORDER_RIDER_STATUS.ACCEPTED:
    case ORDER_RIDER_STATUS.PUSHED:
      return 'blue';
    case ORDER_RIDER_STATUS.DISPATCHED:
      return 'purple';
    case ORDER_RIDER_STATUS.CANCELED:
      return 'red';
    case ORDER_RIDER_STATUS.DELIVERED:
      return 'green';
    default:
      return '';
  }
};

export const getOrderIcon = (orderDeliveryType, gift, deliveryStatus, currentTabStatus) => {
  let orderIcon = <StoreIcon width="22px" />;
  if (orderDeliveryType === DELIVERY_BUSY_MODE.DELIVERY) {
    if (gift) {
      orderIcon = <Gift />;
    } else {
      orderIcon = <DeliveryIcon fill={currentTabStatus === ORDER_STATUS.PAST && getOrderIconColor(deliveryStatus)} />;
    }
  } else if (orderDeliveryType === DELIVERY_BUSY_MODE.BEACH) {
    orderIcon = <UmbrellaIcon width="18" />;
  }
  return orderIcon;
};

export const getDeliveryTypeText = orderDeliveryType => {
  let deliveryText = <Text value={translations.PICKUP} />;
  if (orderDeliveryType === DELIVERY_BUSY_MODE.DELIVERY) {
    deliveryText = <Text value={translations.DELIVERY} />;
  } else if (orderDeliveryType === DELIVERY_BUSY_MODE.BEACH) {
    deliveryText = <Text value={translations.BEACH} />;
  }
  return deliveryText;
};

export const ManualOrderMark = () => (
  <>
    <Phone className="w-4 h-4 z-100" data-tooltip-id="actionByTip" />
    <ReactTooltip id="actionByTip" place="left" type="dark" effect="solid">
      <Text value={translations.MANUAL_ORDER_DROPDOWN} />
    </ReactTooltip>
  </>
);

export const BulkChangeTip = ({ status }) => (
  <ReactTooltip id="bulkChangeTip" place="top" type="dark" effect="solid">
    <Text value={translations.CHANGING_TO} className="inline" />
    <Text value={getOrderStatusTranslation(status)} className="inline" />
  </ReactTooltip>
);

BulkChangeTip.propTypes = {
  status: PropTypes.string,
};
