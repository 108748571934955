import React, { useContext } from 'react';
import * as R from 'ramda';
import { Redirect, Location } from '@reach/router';
import { useReplaceParams } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as paths from 'paths.js';

export default () => <Main />;

const Main = () => {
  const { stores, hasRole, isCustomerSupport, user } = useContext(userContext);
  const isSupport = isCustomerSupport(user);
  const isManagerOrHigher = hasRole('owner') || hasRole('ops_manager');
  const { lang } = useContext(localeContext);
  const replace = useReplaceParams();

  if (!stores || stores?.length === 0) {
    return (
      <Layout
        top={
          <Breadcrumbs
            path={breadcrumbs.ORDERS}
            className={cx('flex-row items-center justify-between', lang === 'ar' && 'flex-row-reverse')}
          />
        }
      >
        <>Empty State</>
      </Layout>
    );
  }

  return (
    <Location>
      {({ location }) => {
        const storeIdFromUrl = location.pathname.split('/').filter(R.identity)[0];

        return (
          <Redirect
            noThrow
            to={replace(isManagerOrHigher && isSupport ? paths.overview : paths.pendingOrders, {
              omitQuery: true,
              storeId: storeIdFromUrl || stores?.[0]?.id || '',
            })}
          />
        );
      }}
    </Location>
  );
};
