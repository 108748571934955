/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { Link, Typography, useTheme } from '@zydalabs/zac-react';

import { context as localContext } from 'context/locale';
import { useReplaceParams } from 'hooks';

type SectionTitleProps = {
  title: Array<string>;
  subtitle: Array<string>;
  linkText?: Array<string>;
  linkPath?: string;
};
const SectionTitle: React.FC<SectionTitleProps> = ({ title, subtitle, linkText, linkPath }) => {
  const { lang, translate } = useContext(localContext);
  const { colors } = useTheme();
  const replace = useReplaceParams();
  return (
    <div className="w-full flex-row">
      <Typography mb={1} variant="heading16">
        {translate(title)}
      </Typography>
      <div className="flex flex-row">
        <Typography
          mr={lang === 'en' && 12}
          ml={lang === 'ar' && 12}
          variant="body12"
          color={colors.positive.secondary}
        >
          {translate(subtitle)}
        </Typography>
        {linkPath && (
          <Link
            size="small"
            inner={{ text: translate(linkText), arrowDirection: lang === 'en' ? 'forward' : 'back' }}
            onClick={() => navigate(replace(linkPath))}
          />
        )}
      </div>
    </div>
  );
};

export default SectionTitle;
