import React, { useContext } from 'react';
import { Spinner, Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import Popup from 'components/kit/Popup';
import { BULK_CREATE_WAITING_MSG } from 'constants/translations';

const BulkResponseModal = () => {
  const { translate } = useContext(localeContext);

  return (
    <Popup preventClosure>
      <div className="p-5">
        <div className="flex items-center justify-center mb-5">
          <Spinner radius={32} />
        </div>
        <div className="text-center">
          <Typography variant="body14">{translate(BULK_CREATE_WAITING_MSG)}</Typography>
        </div>
      </div>
    </Popup>
  );
};

export default BulkResponseModal;
