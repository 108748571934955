import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { SORT_BY, CREATION_TIME, SCHEDULED_TIME } from 'constants/translations';
import { DropDown } from 'components/kit';
import { Text } from 'components/service';
import { FRAME_ENUM } from './utils';
import ExportOrders from '../ExportOrders';

const OrderFrameSortByDropdown = ({ isMobile, lang, query, setQuery, canExportWFilters, ordersVariables, storeId }) => (
  <div className={cx('flex', isMobile && 'w-full', lang === 'ar' && 'flex-row-reverse')}>
    <DropDown
      noBorder
      float={lang !== 'ar' ? 'left' : 'right'}
      containerStyle={{ width: '100%', backgroundColor: '#f3f4f6', height: '100%' }}
      width="w-full md:w-auto"
      className={cx(lang !== 'ar' ? 'xl:mr-4' : 'xl:ml-4')}
      position={lang !== 'ar' ? 'left' : 'right'}
      label={<Text value={SORT_BY} />}
      optionSelected={query.sort_by}
      onSelect={option => setQuery({ sort_by: option })}
      testId_openDropDown="sort-by"
      testId_selectedData="default-sort-by"
      testId_listData="sort-by-list"
      data={[
        {
          id: FRAME_ENUM.CREATED_AT,
          title: <Text value={CREATION_TIME} />,
        },
        {
          id: FRAME_ENUM.EXPECTED_AT,
          title: <Text value={SCHEDULED_TIME} />,
        },
      ]}
      icon="keyboard_arrow_down"
    />
    {!isMobile && (
      <ExportOrders canExportWFilters={canExportWFilters} ordersVariables={ordersVariables} storeId={storeId} />
    )}
  </div>
);

export default OrderFrameSortByDropdown;

OrderFrameSortByDropdown.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  setQuery: PropTypes.func.isRequired,
};
