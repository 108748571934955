import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { useDeliveryZoneMsToken } from 'hooks';
import { TAX_INCLUSIVE, TAX_EXCLUSIVE } from 'constants/integrations';
import { BRANCHES, CONNECTED_POS_BRANCHES } from './schemas';

export const context = createContext();

export const Provider = ({ children, defaultValues }) => {
  const { storeId, defaultTaxType } = defaultValues;
  const [initialValues, setInitialValues] = useState({});
  const [fields, setFields] = useState([]);
  const [branches, setBranches] = useState([]);
  const [taxType, setTaxType] = useState(defaultTaxType ? TAX_INCLUSIVE : TAX_EXCLUSIVE);
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const { data: dataBranches } = useQuery(BRANCHES, {
    variables: { restaurantId: storeId },
  });

  useEffect(() => {
    if (dataBranches?.branches?.length > 0) {
      const tempInitialValues = {};
      const tempFields = [];
      const tempBranches = dataBranches.branches.map(branch => {
        tempInitialValues[branch.id] = branch.id;
        tempFields.push({
          required: false,
          connected: false,
          name: branch.id,
          label: {
            en: branch.titleEn,
            ar: branch.titleAr,
          },
        });
        return {
          id: branch.id,
          externalId: null,
        };
      });

      setInitialValues({ ...tempInitialValues });
      setFields([...tempFields]);
      setBranches([...tempBranches]);
    }
  }, [dataBranches]);

  const updateBranchesStatus = connectedPosBranches => {
    if (connectedPosBranches) {
      // get deliverect branches data
      // update connected flag to true if zyda branch is linked to deliverect branch
      setFields(_fields =>
        _fields.map(field => {
          const newField = field;
          const connectedBranch = connectedPosBranches?.find(_branch => _branch.branchInternalId === field.name);
          if (connectedBranch?.branchExternalId) {
            newField.connected = true;
          }
          return newField;
        }),
      );
    }
  };

  const { refetch: refetchConnectedBranches } = useQuery(CONNECTED_POS_BRANCHES, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    variables: { storeId },
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.connectedPosBranches) {
        updateBranchesStatus(res.connectedPosBranches);
      }
    },
  });

  return (
    <context.Provider
      value={{
        storeId,
        initialValues,
        fields,
        branches,
        taxType,
        setInitialValues,
        setFields,
        setBranches,
        setTaxType,
        handleRefresh: () => refetchConnectedBranches(),
      }}
    >
      {children}
    </context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.shape({
    storeId: PropTypes.string.isRequired,
    defaultTaxType: PropTypes.bool,
  }),
};
