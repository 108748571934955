import React, { useContext, useLayoutEffect } from 'react';
import { navigate } from '@reach/router';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { breadcrumbs } from 'constants/translations';
import { fbRedirectUrl } from 'constants/fb';
import { parseQueryString } from 'utils';
import ActiveOrders from './ActiveOrders';
import TopSales from './TopSales';
import Summary from './Summary';

const Overview: React.FC = () => {
  const { direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);

  useLayoutEffect(() => {
    if (window.location.search && window.location.search.includes('redirect_uri')) {
      const { redirect_uri: redirectUri } = parseQueryString(window.location.search);
      navigate(decodeURIComponent(redirectUri.concat(fbRedirectUrl(selectedStore))));
    }
  }, []);

  return (
    <Layout top={<Breadcrumbs path={breadcrumbs.OVERVIEW} />} direction={direction}>
      <>
        <Summary />
        <ActiveOrders />
        <TopSales />
      </>
    </Layout>
  );
};

export default Overview;
