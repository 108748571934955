import { gql } from 'graphql-request';

const restaurantAnalyticsSummary = gql`
  {
    restaurantAnalyticsSummary {
      avgOrderValue
      avgOrderPercentDiff
      newCustomers
      newCustomersPercentDiff
      totalSales
      totalOrders
      salesPercentDiff
      returningCustomers
      returningCustomersPercentDiff
      ordersPercentDiff
    }
  }
`;

export default restaurantAnalyticsSummary;
