import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';
import * as translations from 'constants/translations';
import PaidThrough from '../PaidThrough';

const OrderDetailsPayments = ({ paidThrough, lang, paidByWallet = 0, paidByCreditCard = 0, currency }) => {
  const { direction } = useContext(localeContext);

  return (
    <div className="flex w-1/2 flex-col flex-1" style={{ direction: 'ltr' }}>
      <div className="mb-5 pb-3 border-b-2 border-gray-100 w-full">
        <Typography variant="heading16">
          <Text value={translations.PAYMENT_METHODS} />
        </Typography>
      </div>
      {paidThrough === PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD ? (
        <div className="mt-3 pb-2">
          <div className={cx('flex flex-flex w-full ', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
            <span className="text-xs flex items-center">
              <Text value={translations.CREDIT_CARD} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {paidByCreditCard.toFixed(currency.decimals)}
            </span>
          </div>
          <div className={cx('flex flex-flex w-full py-2', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
            <span className="text-xs flex items-center">
              <Text value={translations.ORDERFAST_WALLET} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {paidByWallet.toFixed(currency.decimals)}
            </span>
          </div>
        </div>
      ) : (
        <div className={cx('flex w-full pb-4', lang === 'ar' && 'flex-row-reverse')}>
          <Typography variant="element16">
            <PaidThrough paidThrough={paidThrough} />
          </Typography>
        </div>
      )}
    </div>
  );
};

OrderDetailsPayments.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  paidByWallet: PropTypes.number,
  paidByCreditCard: PropTypes.number,
  currency: PropTypes.string,
};

export default OrderDetailsPayments;
