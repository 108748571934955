import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '@zydalabs/zac-react';
import { PlayIcon } from '@zydalabs/zac-icons-react';

import VERD from 'assets/verd.png';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const VerdVideo = () => {
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false);
  const { translate } = useContext(localeContext);

  return (
    <>
      {shouldPlayVideo ? (
        <>
          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <div style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}>
              <iframe
                src="https://fast.wistia.net/embed/iframe/ylfv2lngap?videoFoam=true"
                title="IMG_3803 2_2 Video"
                allow="autoplay; fullscreen"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <Helmet>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
          </Helmet>
        </>
      ) : (
        <>
          <img src={VERD} className="w-full h-auto" alt="menu-item" />
          <div className="absolute top-1/2 left-1/2 -ml-16 -mt-5">
            <Button
              rounded
              size="medium"
              text={translate(translations.WATCH_VIDEO)}
              onClick={() => setShouldPlayVideo(true)}
              startIcon={<PlayIcon width="20px" color="white" />}
            />
          </div>
        </>
      )}
    </>
  );
};

export default VerdVideo;
