import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { bulkAssignDriverToOrdersMutation } from '../../mutations/verd';

type useBulkAssignDriverToOrdersType = (variables: any) => any;

const useBulkAssignDriverToOrders: useBulkAssignDriverToOrdersType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return variables =>
    apiMutate({
      mutation: bulkAssignDriverToOrdersMutation,
      variables,
      clientName: GRAPHQL_CLIENT_NAMES.VERD,
      requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
      handler: (res: any) => res,
    });
};

export default useBulkAssignDriverToOrders;
