import { useContext } from 'react';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { context as userContext } from 'context/user';
import { restaurantAnalyticsSummary } from 'service/queries';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { RestaurantAnalyticsSummary } from '../../types/generated/backend_client_index';

type useFetchAnalyticsSummaryType = () => QueryHookResponse<RestaurantAnalyticsSummary>;

const useFetchAnalyticsSummary: useFetchAnalyticsSummaryType = () => {
  const { selectedStore } = useContext(userContext);
  const token = userService.getToken();
  const { subdomain } = selectedStore || {};
  return useQuery<RestaurantAnalyticsSummary>({
    query: restaurantAnalyticsSummary,
    variables: { subdomain },
    requestHeaders: { Authorization: `Bearer ${token}` },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    handler: (res: any) => res.restaurantAnalyticsSummary,
    config: { revalidateOnMount: true },
  });
};
export default useFetchAnalyticsSummary;
