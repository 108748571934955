/**
 * Utility library for using the clarity
 */
const clarity = {
  /**
   * Inititalizes clarity sdk
   */
  init() {
    (function(c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function() {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'h1r72r3ujz');
  },
  /**
   * Identify user clarity
   */
  identify({ id, email, name, phoneNumber, storeId }) {
    if (window.clarity) {
      window.clarity('consent');
      window.clarity('set', 'id', id);
      window.clarity('set', 'userId', email);
      window.clarity('set', 'name', name);
      window.clarity('set', 'phoneNumber', phoneNumber);
      window.clarity('set', 'email', email);
      window.clarity('set', 'storeId', storeId);
    }
  },
};

export default clarity;
