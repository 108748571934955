import { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { context as userContext } from 'context/user';
import { PAYMENT_CREDENTIALS } from '../schemas';

const usePaymentCredentials = () => {
  const { selectedStore } = useContext(userContext);
  const { id } = selectedStore;
  const [isOnlinePaymentAvailable, setIsOnlinePaymentAvailable] = useState(false);

  // check payment credentials if exist
  const { data: { paymentCredentials } = {}, loading: onlinePaymentLoading, error: onlinePaymentError } = useQuery(
    PAYMENT_CREDENTIALS,
    {
      variables: { storeId: id },
      fetchPolicy: 'cache-and-network',
    },
  );
  useEffect(() => {
    if ((!onlinePaymentLoading || !onlinePaymentError) && paymentCredentials) {
      const { restaurantPaymentMethods } = paymentCredentials || {};
      const isPaymentEnabled =
        restaurantPaymentMethods &&
        restaurantPaymentMethods.length > 0 &&
        restaurantPaymentMethods.some(method => method.enabled);

      setIsOnlinePaymentAvailable(isPaymentEnabled || false);
    } else {
      setIsOnlinePaymentAvailable(false);
    }
  }, [onlinePaymentLoading, onlinePaymentError, paymentCredentials]);

  return { isOnlinePaymentAvailable };
};

export default usePaymentCredentials;
