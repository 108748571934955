import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, Typography, Spinner } from '@zydalabs/zac-react';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import {
  GENERATED_SALES,
  LAST_30_DAYS,
  SOMETHING_WENT_WRONG,
  GENERATED_SALES_TOOLTIP,
  ORDERS,
} from 'constants/translations';
import { useFetchGeneratedSales } from 'service/hooks/engage';
import SalesLineChart from './SalesLineChart';

const GeneratedSales = ({ totalOrders }) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;

  const { data, isLoading, error } = useFetchGeneratedSales({
    subdomain: selectedStore.subdomain,
  });

  // handle loading state
  if (isLoading) {
    return <Spinner />;
  }

  // handle error state
  if (error) {
    return <Typography variant="body14">{translate(SOMETHING_WENT_WRONG)}</Typography>;
  }

  // get needed data
  const { engageGeneratedSales } = data;
  const { totalSales = 0, graphData } = engageGeneratedSales;

  return (
    <div dir={direction}>
      <div className="w-full mb-6">
        <div className="mb-4">
          <div className="flex justify-start items-center">
            <Typography variant="heading14">
              <Text value={LAST_30_DAYS} className={cx('w-auto', lang === 'ar' ? 'ml-2' : 'mr-2')} />
            </Typography>
            <div className="flex-shrink-0">
              <Tooltip
                info={<Text className="p-2" value={GENERATED_SALES_TOOLTIP} />}
                size="small"
                placement="bottom"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div>
            <Typography variant="heading24">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {Math.round(totalSales)}
              </div>
            </Typography>
            <Typography variant="heading12" mt="4">
              <Text value={GENERATED_SALES} />
            </Typography>
          </div>
          <div className={cx('border-gray-300', lang === 'ar' ? 'border-r mr-6 pr-6' : 'border-l ml-6 pl-6')}>
            <Typography variant="heading24">{totalOrders || 0}</Typography>
            <Typography variant="heading12" mt="4">
              <Text value={ORDERS} />
            </Typography>
          </div>
        </div>
      </div>
      {graphData && (
        <div className="w-full">
          <SalesLineChart data={graphData} />
        </div>
      )}
    </div>
  );
};

GeneratedSales.propTypes = {
  totalOrders: PropTypes.string.isRequired,
};

export default GeneratedSales;
