import { apiMutate } from 'service/utils/mutation';

import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { DeleteBulkDeliveryZonesInput, DeleteBulkDeliveryZonesPayload } from '../../types/generated';
import { deleteBulkDeliveryZonesMutation } from '../../mutations';

type useDeleteBulkDeliveryZoneType = () => ({
  input,
}: {
  input?: DeleteBulkDeliveryZonesInput;
}) => Promise<void | Partial<DeleteBulkDeliveryZonesPayload> | { hasError: boolean }>;

const useDeleteBulkDeliveryZone: useDeleteBulkDeliveryZoneType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return input =>
    apiMutate({
      mutation: deleteBulkDeliveryZonesMutation,
      variables: { input },
      clientName: GRAPHQL_CLIENT_NAMES.DELIVERY_ZONES,
      requestHeaders: { token: deliveryZoneMsToken },
      handler: (res: any) => res,
    });
};

export default useDeleteBulkDeliveryZone;
