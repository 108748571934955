import { gql } from 'graphql-request';

const updateStoreCourierSettings = gql`
  mutation(
    $autoAssignMechanism: AutoAssignMechanismFromEnum
    $courierId: Int!
    $driverMaxCapacity: Int
    $storeId: Int!
    $assignmentFlow: AssignmentFlowFromEnum
  ) {
    updateStoreCourierSettings(
      autoAssignMechanism: $autoAssignMechanism
      courierId: $courierId
      driverMaxCapacity: $driverMaxCapacity
      storeId: $storeId
      assignmentFlow: $assignmentFlow
    ) {
      storeCourierSettings {
        id
        driverMaxCapacity
        autoAssignMechanism
      }
    }
  }
`;

export default updateStoreCourierSettings;
