import React, { useContext, useState, useMemo } from 'react';
import { Button } from '@zydalabs/zac-react';
import { PlusIcon } from '@zydalabs/zac-icons-react';

import * as paths from 'paths.js';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import BranchesDropDown from 'common/components/BranchesDropDown';
import SearchInput from 'common/components/SearchInput';
import { Link } from 'components/service';
import { context as localeContext } from 'context/locale';
import { useMobile } from 'hooks/index';

const useRidersHeader = riders => {
  const { branches } = useContext(userContext);
  const { lang, translate } = useContext(localeContext);
  const isMobile = useMobile();
  const [selectedBranch, setSelectedBranch] = useState('all');
  const [searchInput, setSearchInput] = useState('');

  const filterRidersWithBranches = useMemo(() => {
    if (selectedBranch !== 'all') {
      return riders?.filter(rider => rider?.branches?.includes(parseInt(selectedBranch)));
    }
    return riders;
  }, [selectedBranch, riders]);

  const filterRidersWithSearch = useMemo(() => {
    if (searchInput !== '') {
      return filterRidersWithBranches?.filter(rider => rider.name.toLowerCase().includes(searchInput.toLowerCase()));
    }
    return filterRidersWithBranches;
  }, [searchInput, filterRidersWithBranches]);

  const handleSearch = e => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return {
    filterRidersWithSearch,
    ridersHeaderComponent: (
      <>
        <BranchesDropDown
          branches={branches}
          onSelect={option => {
            setSelectedBranch(option);
          }}
          currentOption={selectedBranch}
          lang={lang}
          isMobile={isMobile}
        />
        <SearchInput
          searchValue={searchInput}
          setSearchValue={handleSearch}
          bgColor="bg-gray-100"
          placeholder={translate(translations.SEARCH_RIDERS)}
        />
        <Link to={paths.createRider}>
          <Button startIcon={<PlusIcon />} text={translate(translations.ADD_NEW_RIDER)} onClick={() => ({})} rounded />
        </Link>
      </>
    ),
  };
};

export default useRidersHeader;
