import React from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { useMobile } from 'hooks';

type InfoBlockProps = {
  elementText?: string;
  bodyText: string;
};
const InfoBlock: React.FC<InfoBlockProps> = ({ elementText, bodyText }) => {
  const { colors } = useTheme();
  const isMobile = useMobile();
  return (
    <>
      <Typography variant={isMobile ? 'element12' : 'element14'} color={colors.positive.secondary}>
        {elementText}
      </Typography>
      <Typography variant={isMobile ? 'body14' : 'body16'} color={colors.positive.primary}>
        {bodyText}
      </Typography>
    </>
  );
};

export default InfoBlock;
