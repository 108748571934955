import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { createStoreDriverMutation } from '../../mutations/verd';

import { CreateBulkDeliveryZonesInput, CreateDeliveryZonePayload } from '../../types/generated';

type useCreateStoreDriverType = () => ({
  input,
}: {
  input?: CreateBulkDeliveryZonesInput;
}) => Promise<void | Partial<CreateDeliveryZonePayload> | { hasError: boolean }>;

const useCreateStoreDriver: useCreateStoreDriverType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return input =>
    apiMutate({
      mutation: createStoreDriverMutation,
      variables: { input },
      clientName: GRAPHQL_CLIENT_NAMES.VERD,
      requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
      handler: (res: any) => res,
    });
};

export default useCreateStoreDriver;
