import React, { useContext } from 'react';
import cx from 'classnames';
import { navigate } from '@reach/router';

import { context as localeContext } from 'context/locale';
import { useReplaceParams } from 'hooks';
import * as paths from 'paths.js';
import { context as userContext } from 'context/user';
import { YOU_HAVE_NO_ACTIVE_ORDERS } from 'constants/translations';
import EmptyCart from 'assets/EmptyCart.svg';
import OrderCard from './OrderCard';
import { OrderCardListProps } from './types';
import EmptyOverviewState from '../common/EmptyOverviewState';

const OrderCardsList: React.FC<OrderCardListProps> = ({ activeOrders }) => {
  const { lang, translate } = useContext(localeContext);
  const {
    selectedStore: { timeZone, currency },
  } = useContext(userContext);

  const replace = useReplaceParams();

  const handleOnSelectingOrderCard = (orderNumber: string) => {
    navigate(`${replace(paths.allOrders)}?&search_value=${orderNumber}`);
  };

  if (!activeOrders.length) return <EmptyOverviewState title={YOU_HAVE_NO_ACTIVE_ORDERS} Image={EmptyCart} />;
  return (
    <>
      {activeOrders.map(order => (
        <div className="pb-2" key={order?.id}>
          <div
            role="presentation"
            className={cx(
              'w-full flex flex-col md:flex-row md:flex-wrap bg-gray-100 border items-center cursor-pointer',
            )}
            onClick={() => handleOnSelectingOrderCard(order.number)}
          >
            <OrderCard timeZone={timeZone} currency={currency} translate={translate} lang={lang} {...order} />
          </div>
        </div>
      ))}
    </>
  );
};
export default OrderCardsList;
