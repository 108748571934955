import React from 'react';
import { navigate } from '@reach/router';
import moment from 'moment';

import * as paths from 'paths.js';
import { DatePicker } from 'components/kit';
import { Text } from 'components/service';
import DATE_RANGES from 'constants/date';
import { dateQueryFormat, formatDate, stringifyRange } from 'utils/date';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import {
  MANUAL_ORDER_DROPDOWN,
  DELIVERY,
  PICKUP,
  TODAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  ALL_TIME,
  CUSTOM_DATE,
  BEACH,
  CURRENT_ORDERS,
  PAST_ORDERS,
} from 'constants/translations';
import { ORDER_STATUS } from 'constants/order';
import OrderFrameTabTitles from './OrderFrameTabTitles';
import { FRAME_ENUM, validateRange } from './utils';

export const orderFilterStatus = [
  {
    id: ORDER_STATUS.CURRENT,
    title: <Text value={CURRENT_ORDERS} />,
  },
  {
    id: ORDER_STATUS.PAST,
    title: <Text value={PAST_ORDERS} />,
  },
];

export const deliveryTypes = [
  {
    id: DELIVERY_BUSY_MODE.MANUAL,
    title: <Text full={false} value={MANUAL_ORDER_DROPDOWN} />,
  },
  {
    id: DELIVERY_BUSY_MODE.DELIVERY,
    title: <Text full={false} value={DELIVERY} />,
  },
  {
    id: DELIVERY_BUSY_MODE.PICKUP,
    title: <Text full={false} value={PICKUP} />,
  },
  {
    id: DELIVERY_BUSY_MODE.BEACH,
    title: <Text full={false} value={BEACH} />,
  },
];
export const fetchDateOptions = (range, notifications, setFilters, filters, timeZone) => [
  {
    value: DATE_RANGES.ZERO,
    title: <Text value={TODAY} />,
  },
  {
    value: DATE_RANGES.SEVEN_DAYS,
    title: <Text value={LAST_7_DAYS} />,
  },
  {
    value: DATE_RANGES.THIRTY_DAYS,
    title: <Text value={LAST_30_DAYS} />,
  },
  {
    value: DATE_RANGES.ALL,
    title: <Text value={ALL_TIME} />,
  },
  {
    value: DATE_RANGES.CUSTOM,
    className: 'px-4',
    hideRadioBtn: true,
    title: `${formatDate(range[0])} -> ${formatDate(range[1])}`,
    body: (
      <DatePicker
        range={range.map(date => moment(date, dateQueryFormat).toDate())}
        pickerTitle={<Text value={CUSTOM_DATE} className="text-base font-medium block mb-2" />}
        onChange={rangeMap => {
          const error = validateRange(rangeMap);
          if (error) {
            return notifications.show(error, 'error');
          }
          return setFilters({
            ...filters,
            date: DATE_RANGES.CUSTOM,
            range: stringifyRange(
              rangeMap.map((date, index) =>
                index === 0
                  ? moment(date)
                      .startOf('day')
                      .tz(timeZone, true)
                      .format()
                  : moment(date)
                      .endOf('day')
                      .tz(timeZone, true)
                      .format(),
              ),
            ),
          });
        }}
      />
    ),
  },
];

export const fetchAllTabsItems = (orders, setSelectedStatus, replace) => [
  {
    id: ORDER_STATUS.CURRENT,
    title: (
      <OrderFrameTabTitles
        name={<Text value={CURRENT_ORDERS} className="inline" />}
        status={ORDER_STATUS.CURRENT}
        data={orders.data}
      />
    ),
    to: paths.currentOrders,
    testId: 'current-orders',
    onSelect: () => {
      setSelectedStatus(ORDER_STATUS.CURRENT);
      navigate(replace(paths.currentOrders, { appendQuery: true, omitQuery: false }));
    },
  },
  {
    id: ORDER_STATUS.PAST,
    title: (
      <OrderFrameTabTitles
        name={<Text value={PAST_ORDERS} className="inline" />}
        status={ORDER_STATUS.PAST}
        data={orders.data}
      />
    ),
    to: paths.pastOrders,
    testId: 'past-orders',
    onSelect: () => {
      setSelectedStatus(ORDER_STATUS.PAST);
      navigate(replace(paths.pastOrders, { appendQuery: true, omitQuery: false }));
    },
  },
];

export const initialOrderQuery = {
  branch: FRAME_ENUM.ALL,
  search_value: '',
  search_status: '',
  date: ORDER_STATUS.ALL,
  payment_methods: [],
  delivery_type: [],
  is_manual_order: false,
  areas: [],
  delivery_zone_in: [],
  driver_id: '',
  sort_by: FRAME_ENUM.CREATED_AT,
};

export const initalOrderFilters = query => {
  const filterObject = {
    payment_methods: typeof query.payment_methods === 'string' ? [query.payment_methods] : query.payment_methods,
    delivery_type: typeof query.delivery_type === 'string' ? [query.delivery_type] : query.delivery_type,
    areas: typeof query.areas === 'string' ? [query.areas] : query.areas,
    delivery_zone_in: typeof query.delivery_zone_in === 'string' ? [query.delivery_zone_in] : query.delivery_zone_in,
    driver_id: query.driver_id,
    date: query.date,
    is_manual_order: query.is_manual_order,
  };
  return filterObject;
};
