import React, { useContext, Fragment } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { UserIcon } from '@heroicons/react/outline';

import { context as localeContext } from 'context/locale';
import { ReactComponent as Verd } from 'assets/verd.svg';
import { Text } from 'components/service';
import { ACTION_BY, CANCELLATION_REASON } from 'constants/translations';
import { CANCEL_OPTIONS, CANCEL_REASON } from 'constants/cancel';
import { USER_TYPES_ENUMS } from '../../constants';
import OrderStatus from '../OrderStatus';

const OrderDetailsStatusHistory = ({ order, selected }) => {
  const { lang, direction } = useContext(localeContext);
  const { stateHistories, cancellationReason, otherReason } = order || {};
  const isArabic = lang === 'ar';

  const cancelTypeCheck = Number.isInteger(cancellationReason);

  const cancelledFor = () => {
    if (!cancelTypeCheck) {
      return '';
    }
    if (cancellationReason !== CANCEL_REASON.OTHER) {
      const reason = CANCEL_OPTIONS.filter(option => option.value === cancellationReason)[0];
      return reason.title;
    }
    return otherReason;
  };

  const reasonToCancel = cancelledFor();
  return (
    <>
      <div className="w-full border-t border-b max-h-72 overflow-y-scroll border-zyda-grey-100 mt-1 py-1">
        {stateHistories.map((item, i) => {
          const { state, createdAt, actionBy, userType, entityType, assignee, assigneeAr, partnerError } = item || {};
          const stateItemIndex = `${state},${createdAt},${actionBy},${i}`;
          return (
            <Fragment key={stateItemIndex}>
              <div className={cx('flex justify-between items-center w-full py-1')}>
                <div className={cx('flex items-center')}>
                  {userType === USER_TYPES_ENUMS.DRIVER || userType === USER_TYPES_ENUMS.COURIER ? (
                    <Verd className="h-5 w-5" />
                  ) : (
                    <UserIcon className="h-5 w-5" />
                  )}
                  <div className={cx('flex flex-col', isArabic ? 'mr-2' : 'ml-2')}>
                    <span className="flex-col text-sm" style={{ direction }}>
                      <OrderStatus
                        status={state}
                        entityType={entityType}
                        assignee={assignee}
                        assigneeAr={assigneeAr}
                        partnerError={partnerError}
                      />
                    </span>
                    {actionBy && (
                      <span className="flex-col text-xs text-zyda-grey-500">
                        <Text value={ACTION_BY} payload={actionBy} />
                      </span>
                    )}
                  </div>
                </div>
                <span className="block text-zyda-grey-500 text-xs">
                  {moment(createdAt)
                    .tz(selected.timeZone)
                    .locale(isArabic ? 'ar' : 'en-gb')
                    .format('h:mm A, DD MMM YYYY')}
                </span>
              </div>
            </Fragment>
          );
        })}
      </div>
      {cancelTypeCheck && (
        <div className="mt-4">
          <span className="block text-sm font-semibold">
            <Text value={CANCELLATION_REASON} />
          </span>
          <div className={cx('w-full border-b border-zyda-grey-100 pb-3 mt-1 text-sm', lang === 'ar' && 'text-right')}>
            {reasonToCancel}
          </div>
        </div>
      )}
    </>
  );
};

OrderDetailsStatusHistory.propTypes = {
  order: PropTypes.shape({
    stateHistories: PropTypes.arrayOf(
      PropTypes.shape({
        actionBy: PropTypes.string,
        createdAt: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    isScheduled: PropTypes.bool,
    firingTime: PropTypes.string,
    cancellationReason: PropTypes.string,
    otherReason: PropTypes.string,
  }),
  selected: PropTypes.shape({ timeZone: PropTypes.string }),
};
export default OrderDetailsStatusHistory;
