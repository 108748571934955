import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const DeliveryCircularProgress = ({ order }) => {
  const { lang, translate } = useContext(localeContext);
  const [progressState, setProgressState] = useState({ value: 100, text: '', color: '' });
  const { expectedAt, submittedAt, isScheduled } = order || {};

  const changeColorBasedOnPerc = perc => {
    if (perc > 50) return '#37995D';
    else if (perc <= 50 && perc > 25) return '#EDB600';
    else if (perc === 0) return '#d6d6d6';
    else if (perc <= 25) return '#E04848';

    return '';
  };

  const checkIfTimeIsMinOrHours = date => {
    const duration = moment.duration(date, 'minutes');

    return `${Math.floor(duration.asMinutes())} ${translate(translations.MINS)}`;
  };

  const handleProgressText = ({ firstLine, secondLine, style }) => (
    <>
      <tspan x="50" dy="-0.5em" style={style}>
        {firstLine}
      </tspan>
      <tspan x="50" dy="1.2em" style={style}>
        {secondLine}
      </tspan>
    </>
  );

  const shouldStartCountDown = () => {
    const expMinusNow = moment(expectedAt).diff(moment(), 'minutes');

    const remainsThreeHours = expMinusNow < 60 * 2;
    return !isScheduled || (isScheduled && remainsThreeHours);
  };

  const scheduledTimeText = moment(expectedAt)
    .locale(lang === 'ar' ? 'ar' : 'en')
    .calendar()
    .split(lang === 'ar' ? ' عند الساعة ' : ' at ')
    .join(' ');

  const calculateDeliveryTimeProgress = () => {
    const expMinusSub = moment(expectedAt).diff(moment(submittedAt), 'minutes');
    const expMinusNow = moment(expectedAt).diff(moment(), 'minutes');
    const timePerc = (expMinusNow / expMinusSub) * 100;

    const timeText = checkIfTimeIsMinOrHours(expMinusNow);

    const getProgressValue = () => {
      if (!shouldStartCountDown()) return 0;
      if (timePerc > 0) return timePerc;
      return 100;
    };

    const getProgressText = () => {
      if (shouldStartCountDown()) {
        const timeTextArr = timeText.split(' ');
        return handleProgressText({
          firstLine: timeTextArr[0],
          secondLine: timeTextArr[1],
          style: { fontSize: '20px', fontWeight: 600 },
        });
      }
      const scheduledTextArr = scheduledTimeText.split(' ');
      const day = scheduledTextArr.shift();
      const time = scheduledTextArr.join(' ');

      return handleProgressText({
        firstLine: day,
        secondLine: time,
        style: { fontSize: '18px', fontWeight: 600 },
      });
    };

    setProgressState({
      value: getProgressValue(),
      text: getProgressText(),
      color: changeColorBasedOnPerc(timePerc),
    });
  };

  useEffect(() => {
    calculateDeliveryTimeProgress();
    const intervalId = setInterval(() => {
      calculateDeliveryTimeProgress();
    }, 1 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [expectedAt, lang, order]);

  return (
    <div className="h-full w-4/6">
      <CircularProgressbar
        value={progressState.value}
        text={progressState.text}
        counterClockwise
        strokeWidth={10}
        background={!shouldStartCountDown()}
        styles={{
          ...(!shouldStartCountDown()
            ? {
                path: { strokeWidth: 0 },
                trail: { strokeWidth: 0 },
                background: { fill: 'rgba(31, 31, 31, 0.09)' },
              }
            : {
                path: { stroke: progressState.color },
              }),
          text: { fill: 'black' },
        }}
      />
    </div>
  );
};

export default DeliveryCircularProgress;
