import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { QTY, ITEM, PRICE, SUBTOTAL } from 'constants/translations';
import { ReactComponent as ProductIcon } from 'assets/product-place-holder.svg';
import { Text } from 'components/service';

import OrderDetailsCard from '../../Table/Order/OrderDetails/OrderDetailsCard';

const OrderInvoiceItems = ({
  lang,
  translate,
  order,
  isGift,
  store,
  direction,
  shouldHidePricing,
  shouldShowInvoiceImages = false,
}) => {
  const { currency } = store || {};
  const { orderItems, subtotal } = order || {};
  return (
    <div>
      <div className="bg-zyda-grey-50 w-full py-1 px-4 mb-4">
        <div className={cx('flex justify-between', lang === 'ar' && 'flex-row-reverse')}>
          <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
            <span className="w-9 inline-block text-base">{translate(QTY)}</span>
            <span className="text-base mx-5">{translate(ITEM)}</span>
          </div>
          <div>{!isGift && !shouldHidePricing && <span className="text-base">{translate(PRICE)}</span>}</div>
        </div>
      </div>
      <div className="w-full px-4">
        {orderItems.map(item => {
          const { quantity, variant, totalPrice, properties, notes, menuItem, id: menuItemId, imageUrl } = item || {};
          const variantTitles = { titleAr: variant.titleAr, titleEn: variant.titleEn };
          return (
            <div key={menuItemId} className={cx('flex mb-5', lang === 'ar' && 'flex-row-reverse')}>
              <span
                className={cx('flex text-base font-semibold', lang === 'ar' && 'flex-row-reverse')}
                style={{ minWidth: '2.25rem', direction }}
              >
                {quantity} X
              </span>
              <div className={cx('flex flex-col w-full ', lang === 'ar' ? 'mr-5' : 'ml-5')}>
                <div className={cx('flex justify-between w-full', lang === 'ar' && 'flex-row-reverse')}>
                  <div className="flex flex-col">
                    <div className={cx('flex justify-between', lang === 'ar' && 'flex-row-reverse')}>
                      {shouldShowInvoiceImages && (
                        <div className={cx('flex-2 w-10 h-10 mb-4', lang === 'ar' ? 'ml-3' : 'mr-3')}>
                          {imageUrl ? (
                            <img src={imageUrl} className="w-full border rounded-lg h-full" alt="menu-item" />
                          ) : (
                            <ProductIcon width={40} height={40} />
                          )}
                        </div>
                      )}
                      <span className="text-sm font-semibold mb-1" style={{ direction }}>
                        <Text selectedLang={lang} value={menuItem} className="inline" />
                        {variant && (
                          <>
                            <span> - </span>
                            <Text selectedLang={lang} value={variantTitles} className="inline" />
                            {variant.barCode && (
                              <span className={cx(lang === 'ar' ? 'mr-1' : 'ml-1')}>({variant.barCode})</span>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    {notes && notes.replace(/\s/g, '').length !== 0 && (
                      <div style={{ direction }} className="text-xs pt-1 pb-2">
                        <span>{notes}</span>
                      </div>
                    )}
                  </div>
                  {!isGift && !shouldHidePricing && (
                    <div className="text-base font-bold" style={{ direction }}>
                      <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                      {totalPrice.toFixed(currency.decimals)}
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  {properties.length > 0 &&
                    properties.map(property => {
                      const propertyTitles = { titleAr: property.titleAr, titleEn: property.titleEn };
                      return property.propertyValues.map(propertyValue => {
                        const propertyValueNames = { titleAr: propertyValue.titleAr, titleEn: propertyValue.titleEn };
                        return (
                          <OrderDetailsCard
                            title={propertyTitles}
                            quantity={propertyValue.quantity * quantity}
                            itemQuantity={quantity}
                            optionName={propertyValueNames}
                            price={propertyValue.price}
                            currency={currency}
                            numberOfEach={propertyValue.quantity}
                            lang={lang}
                            direction={direction}
                            isGift={isGift}
                            shouldHidePricing={shouldHidePricing}
                          />
                        );
                      });
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {!isGift && !shouldHidePricing && (
        <div
          className={cx(
            'flex justify-between bg-zyda-grey-50 w-full py-1 px-4 mb-2',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <span className="text-base">{translate(SUBTOTAL)}</span>
          <div className="text-base" style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
            {subtotal.toFixed(currency.decimals)}
          </div>
        </div>
      )}
    </div>
  );
};

OrderInvoiceItems.propTypes = {
  shouldHidePricing: PropTypes.bool.isRequired,
  lang: PropTypes.string,
  translate: PropTypes.func,
  order: PropTypes.shape({
    orderItems: PropTypes.arrayOf({
      quantity: PropTypes.number,
      variant: PropTypes.shape({
        barCode: PropTypes.string,
        discountEnabled: PropTypes.bool.isRequired,
        discountedPrice: PropTypes.number,
        externalId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        prepTime: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
        sku: PropTypes.string,
        titleAr: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
      }),
      totalPrice: PropTypes.number.isRequired,
      propertyValues: PropTypes.shape({ quantity: PropTypes.number, price: PropTypes.number, id: PropTypes.string }),
      notes: PropTypes.string,
      menuItem: PropTypes.shape({ titleEn: PropTypes.string.isRequired, titleAr: PropTypes.string.isRequired }),
      id: PropTypes.string.isRequired,
    }),
    subtotal: PropTypes.number,
  }),
  isGift: PropTypes.bool,
  store: PropTypes.shape({ currency: PropTypes.string }),
  direction: PropTypes.string,
  shouldShowInvoiceImages: PropTypes.bool,
};
export default OrderInvoiceItems;
