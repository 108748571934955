import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, Typography } from '@zydalabs/zac-react';
import { XIcon, CheckIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { UPDATE_PAYMENT_METHODS_ENABLED } from './schemas';

const DisablePaymentGateWay = ({ restaurantId, engageEnabled, isPaymentEnabled, id, refetch, onClose }) => {
  const { translate } = useContext(localeContext);
  const [updateEnabled, { loading: updating }] = useMutation(UPDATE_PAYMENT_METHODS_ENABLED, {
    variables: { restaurantId, id, enabled: false },
    onCompleted: refetch,
  });

  return (
    <>
      <div>
        <div className="p-4 border-b border-zyda-grey-50 flex justify-between items-center mb-4">
          <div>
            <Typography variant="heading16">{translate(translations.PAYMENT_METHOD_DISABLE)}</Typography>
          </div>

          <div className="flex items-center justify-center cursor-pointer">
            <XIcon width="32" color="#000" onClick={onClose} />
          </div>
        </div>

        <div className="px-4">
          {engageEnabled && isPaymentEnabled ? (
            <ul className="mb-8">
              <li className="mb-2 flex items-start">
                <CheckIcon width="16" color="#000" />
                <div className="flex-1">
                  <Typography variant="body12">
                    <Text value={translations.IF_YOU_DELETE_PAYMENT_GATEWAY} className="px-2" />
                  </Typography>
                </div>
              </li>
            </ul>
          ) : (
            <Typography variant="body14" mb="16px">
              <Text value={translations.PAYMENT_METHOD_DISABLE_NOTE} />
            </Typography>
          )}

          <div className="flex justify-end gap-2">
            <Button rounded variant="secondary" text={translate(translations.CANCEL)} onClick={onClose} />
            <Button
              rounded
              variant="primary"
              disabled={updating}
              isLoading={updating}
              text={translate(translations.CONFIRM)}
              onClick={updateEnabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

DisablePaymentGateWay.propTypes = {
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  restaurantId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  engageEnabled: PropTypes.bool.isRequired,
  isPaymentEnabled: PropTypes.bool.isRequired,
};

export default DisablePaymentGateWay;
