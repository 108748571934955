import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { updateStoreCourierSettings } from '../../mutations/verd';

type useUpdateStoreCourierSettingsType = () => ({
  input,
}: {
  input?: any;
}) => Promise<void | Partial<any> | { hasError: boolean }>;

const useUpdateStoreCourierSettings: useUpdateStoreCourierSettingsType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return input =>
    apiMutate({
      mutation: updateStoreCourierSettings,
      variables: { ...input },
      clientName: GRAPHQL_CLIENT_NAMES.VERD,
      requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
      handler: (res: any) => res,
    });
};

export default useUpdateStoreCourierSettings;
