import { gql } from 'graphql-request';

const restaurantRecentOrder = gql`
  {
    restaurantRecentOrders {
      id
      number
      isScheduled
      expectedAt
      isManualOrder
      customerName
      areaNameEn
      areaNameAr
      timeSlot
      deliveryType
      branchName
      paidThrough
      status
      total
      createdAt
      submittedAt
    }
  }
`;

export default restaurantRecentOrder;
