import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { QuerySuggestedZonesArgs, SuggestedZones } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { suggestedDeliveryZonesQuery } from '../../queries/deliveryZones';

type useFetchSuggestedDeliveryZonesType = (args: QuerySuggestedZonesArgs) => QueryHookResponse<SuggestedZones>;

const useFetchSuggestedDeliveryZones: useFetchSuggestedDeliveryZonesType = args => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  const variables = args.geoPoint ? { ...args } : null;
  return useQuery<SuggestedZones>({
    query: suggestedDeliveryZonesQuery,
    variables,
    clientName: GRAPHQL_CLIENT_NAMES.DELIVERY_ZONES,
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res?.suggestedZones,
    config: { revalidateOnMount: true },
  });
};
export default useFetchSuggestedDeliveryZones;
