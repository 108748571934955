import React from 'react';

import { useMobile } from 'hooks';
import { getDay, getOrderIcon, getPaymentIcon, getTime, getTimeSlot } from './utils';
import OrderDateBlock from './OrderDateBlock';
import OrderInfoBlock from './OrderInfoBlock';
import { OrderCardType } from './types';

const OrderCard: React.FC<OrderCardType> = ({
  submittedAt,
  createdAt,
  number,
  paidThrough,
  total,
  customerName,
  branchName,
  isScheduled,
  status,
  deliveryType,
  expectedAt,
  timeSlot,
  lang,
  translate,
  currency,
  timeZone,
}) => {
  const date = submittedAt || createdAt;
  const isMobile = useMobile();
  const displayedDay = getDay(timeZone, date, lang);
  const displayedHour = getTime(timeZone, date, lang);
  const [PaymentIcon, paymentTypeInText] = getPaymentIcon(paidThrough, translate);
  const [Icon, deliveryTypeInText] = getOrderIcon(deliveryType, translate);
  const theTimeSlot = getTimeSlot(expectedAt, timeZone, timeSlot, lang);

  return (
    <>
      <OrderDateBlock
        lang={lang}
        total={total}
        displayedDay={displayedDay}
        displayedHour={displayedHour}
        PaymentIcon={PaymentIcon}
        currency={currency}
        isMobile={isMobile}
      />

      <OrderInfoBlock
        lang={lang}
        number={number}
        customerName={customerName}
        Icon={Icon}
        deliveryTypeInText={deliveryTypeInText}
        branchName={branchName}
        PaymentIcon={PaymentIcon}
        paymentTypeInText={paymentTypeInText}
        total={total}
        currency={currency}
        isScheduled={isScheduled}
        translate={translate}
        status={status}
        theTimeSlot={theTimeSlot}
        isMobile={isMobile}
      />
    </>
  );
};

export default OrderCard;
