import React, { forwardRef, useContext } from 'react';
import cx from 'classnames';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { useMobile } from 'hooks/index';
import Menu from '../Menu';
import { ReactComponent as Logo } from 'assets/Appicon.svg';
import { getStoreURl } from '../utils';
import MenuIcons from '../Menu/MenuIcons';

export default forwardRef(({ header }, ref) => {
  const { lang } = useContext(localeContext);
  const { selectedStore, isNavBarCollapsed } = useContext(userContext);
  const isMobile = useMobile();

  const ios =
    navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i);

  const android = navigator.userAgent.match(/Android/i);
  const storeUrl = selectedStore ? getStoreURl(selectedStore) : '';

  return (
    <div
      ref={ref}
      className={cx(
        'pb-4 justify-between flex flex-col h-screen bg-gray-100',
        isNavBarCollapsed ? 'w-16 p-2' : 'w-64 p-4',
      )}
    >
      {header && <div className="mb-2">{header}</div>}
      {isNavBarCollapsed ? <MenuIcons /> : <Menu />}
      {isMobile && storeUrl && (
        <div>
          {isMobile && android && (
            <MobileApp link="https://play.google.com/store/apps/details?id=com.zyda" lang={lang} />
          )}
          {isMobile && ios && <MobileApp link="https://apps.apple.com/us/app/zyda/id1489582791" lang={lang} />}
        </div>
      )}
    </div>
  );
});

const MobileApp = ({ link, lang }) => {
  return (
    <div
      className={cx(
        '-mx-4 px-4 py-5 flex justify-between items-center border-t border-gray-300',
        lang === 'ar' && 'flex-row-reverse',
      )}
    >
      <div>
        <Logo />
      </div>
      <div>
        <Text value={translations.DOWNLOAD_ZYDA_APP} className="text-xs font-extralight" />
      </div>
      <div>
        <Button isLink={true} kind="primary" textColor="text-primary-base" size="sm" href={link}>
          <Text value={translations.GET} className="text-xs font-extralight" />
        </Button>
      </div>
    </div>
  );
};
