import { useContext, useState } from 'react';

import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { useAssignDriverToOrder } from 'service/hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import updateDeliveryCourierCache from 'utils/updatingCache/updateDeliveryCourierCache';
import { useSelectedStore } from 'hooks/index';

const useHandleAssignUnAssignDriverToOrder = ({ order, mutateFetchVerdDrivers }) => {
  const {
    user,
    selectedStore: { restaurantCourierSetting },
  } = useContext(userContext);
  const notifications = useContext(notificationsContext);

  const [isRiderInfoLoading, setIsRiderInfoLoading] = useState(false);

  const assignDriverToOrder = useAssignDriverToOrder();
  const selectedStoreId = useSelectedStore();

  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);

  const handleUpdateOrderInCache = selectedDriver => {
    const updatedOrder = {
      ...order,
      driverId: selectedDriver ? parseInt(selectedDriver.id) : null,
      deliveryStatus: selectedDriver ? ORDER_RIDER_STATUS.PENDING : ORDER_RIDER_STATUS.CANCELED,
      deliveryCourier: {
        ...order.deliveryCourier,
        courierId: selectedDriver ? Number(verd.courierId) : '',
        driverPhoneNumber: selectedDriver ? selectedDriver?.phone : '',
        driverName: selectedDriver ? selectedDriver?.name : '',
        isInternalDelivery: selectedDriver && true,
      },
      deliveryCourierId: selectedDriver ? Number(verd.courierId) : null,
    };
    const orderVariables = {
      orderId: order.id,
      storeId: selectedStoreId,
    };
    updateDeliveryCourierCache(orderVariables, updatedOrder);
    setIsRiderInfoLoading(false);
  };

  const handleAssignDriverToOrder = async (selectedDriver, notifyUser, newExpectedAt) => {
    const driverId = selectedDriver.id;
    setIsRiderInfoLoading(true);
    const response = await assignDriverToOrder({
      driverId,
      orderNumber: order.number,
      courierId: verd?.courierId,
      userId: user?.id,
      ...(notifyUser && { notifyUser }),
      ...(newExpectedAt && { scheduledDeliveryAt: newExpectedAt }),
    });
    if (response.errors?.length) {
      response.errors.map(error => notifications.show(error.message, 'error'));
    } else {
      await mutateFetchVerdDrivers();
      handleUpdateOrderInCache(selectedDriver);
    }
    setIsRiderInfoLoading(false);
  };

  const handleUnassignDriverToOrder = async () => {
    setIsRiderInfoLoading(true);
    const response = await assignDriverToOrder({ driverId: -1, orderNumber: order.number, userId: user?.id });
    if (response.errors?.length) {
      response.errors.map(error => notifications.show(error.message, 'error'));
    } else {
      await mutateFetchVerdDrivers();
      handleUpdateOrderInCache();
    }
    setIsRiderInfoLoading(false);
  };

  return { handleAssignDriverToOrder, handleUnassignDriverToOrder, isRiderInfoLoading };
};

export default useHandleAssignUnAssignDriverToOrder;
