// @ts-nocheck
import React, { useContext } from 'react';
import { Formik } from 'formik';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { Wizard } from 'components/kit';
import {
  OCIMS_STEP_1,
  OCIMS_STEP_2,
  OCIMS_STEP_3,
  AUTHORIZED_SUCCESSFULLY,
  OCIMS_VALIDATION_EMAIL_FAIL,
} from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { useSelectedStore, useDeliveryZoneMsToken } from 'hooks';
import { Text } from 'components/service';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import { context } from './context';
import ThirdStep from './Steps/ThirdStep';
import { OCIMS_CONFIRMATION, VALIDATE_OCIMS } from './schemas';
import { OCIMS_ENUMS, validateOcimsForm } from './data';
import { RESTAURANT, CONNECTED_DELIVERECT_BRANCHES } from '../../schemas';

const Ocims = ({ courier, onCancel, defaultStep }) => {
  const {
    integrationData: { courierIntegrationInfo, courierDynamicSection, branchLinkingSection },
  } = courier;
  const storeId = useSelectedStore();
  const { setSelectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const { initialValues, ocimsValues, setOcimsValues, setOcimsBranches, ocimsBranches } = useContext(context);
  const { merchantId, externalId, menuId, discountId } = ocimsValues;
  const ocimsIds = { merchantId, externalId, menuId, discountId };
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const [validateOcimsCredentials, { loading: isValidating }] = useMutation(VALIDATE_OCIMS, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    onCompleted: res => {
      if (res.validateOcimsCredentials.valid === false) {
        notifications.show(<Text value={OCIMS_VALIDATION_EMAIL_FAIL} />, 'error');
      }
    },
  });

  const { refetch: refetchConnectedBranches } = useQuery(CONNECTED_DELIVERECT_BRANCHES, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    variables: { storeId },
    fetchPolicy: 'cache-and-network',
  });

  const [getRestaurantCourierSettings] = useLazyQuery(RESTAURANT, {
    variables: {
      storeId,
    },
    onCompleted: ({ restaurant }) => {
      refetchConnectedBranches();
      setSelectedStore(restaurant);
    },
    onError: err => {
      throw err;
    },
    fetchPolicy: 'cache-and-network',
  });

  const [ocimsConfirmation, { loading: isConfirming }] = useMutation(OCIMS_CONFIRMATION, {
    context: { headers: { token: deliveryZoneMsToken } },
    onCompleted: data => {
      if (data?.updateOcimsConfirmation?.message === OCIMS_ENUMS.OK) {
        getRestaurantCourierSettings();
        notifications.show(<Text value={AUTHORIZED_SUCCESSFULLY} />, 'success');
      }
    },
  });
  const executeFunction = () =>
    validateOcimsCredentials({
      variables: { username: ocimsValues.username, password: ocimsValues.password },
    });
  const steps = [
    {
      title: OCIMS_STEP_1,
      Component: () => <FirstStep courierIntegrationInfo={courierIntegrationInfo} setOcimsValues={setOcimsValues} />,
    },
    {
      title: OCIMS_STEP_2,
      Component: () => (
        <SecondStep courierDynamicSection={courierDynamicSection} ocimsIds={ocimsIds} setOcimsValues={setOcimsValues} />
      ),
    },
    {
      title: OCIMS_STEP_3,
      Component: () => (
        <ThirdStep
          setOcimsBranches={setOcimsBranches}
          branchLinkingSection={branchLinkingSection}
          ocimsBranches={ocimsBranches}
        />
      ),
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async () => {
        const isValid = validateOcimsForm(ocimsValues, notifications);
        if (isValid) {
          await ocimsConfirmation({
            variables: {
              storeId,
              credentials: {
                username: ocimsValues.username,
                password: ocimsValues.password,
              },
              branches: ocimsBranches,
              menuId: ocimsValues.menu_id,
              externalStoreId: ocimsValues.merchant_id,
              zydaExternalId: ocimsValues.external_store_id,
              discountId: ocimsValues.discount_id,
              zydaBrandName: ocimsValues.brand_name,
            },
          }).then(res => {
            if (res) {
              getRestaurantCourierSettings();
              onCancel && onCancel();
            }
          });
        }
      }}
    >
      {({ errors, setFieldValue, setFieldError, validateForm, submitForm, setFieldTouched }) => (
        <Wizard
          isModal
          defaultStep={defaultStep}
          isLoading={isConfirming || isValidating}
          executeFunction={executeFunction}
        >
          {steps.map((step, i) => (
            <step.Component
              title={step.title}
              key={step.title}
              stepKey={i}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              errors={errors}
              validateForm={validateForm}
              submitForm={submitForm}
            />
          ))}
        </Wizard>
      )}
    </Formik>
  );
};

Ocims.propTypes = {
  courier: PropTypes.shape({
    integrationData: PropTypes.shape({
      courierIntegrationInfo: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          body: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
        }),
      ),
      courierDynamicSection: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          inputField: PropTypes.shape({
            backendName: PropTypes.string.isRequired,
            hintAr: PropTypes.string.isRequired,
            hintEn: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }),
        }),
      ),
      branchLinkingSection: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          inputField: PropTypes.shape({
            backendName: PropTypes.string.isRequired,
            hintAr: PropTypes.string.isRequired,
            hintEn: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }),
  onCancel: PropTypes.func.isRequired,
  defaultStep: PropTypes.number.isRequired,
};

export default Ocims;
