import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';

const OrderRiderStatus = ({ status }) => {
  const { translate } = useContext(localeContext);
  const { colors } = useTheme();

  switch (status) {
    case ORDER_RIDER_STATUS.SUBMITTED:
    case ORDER_RIDER_STATUS.PENDING:
      return (
        <Typography variant="element16" color={colors.orange[600]}>
          {translate(translations.PENDING_ACCEPTANCE)}
        </Typography>
      );
    case ORDER_RIDER_STATUS.DECLINED:
      return (
        <Typography variant="element16" color={colors.red[600]}>
          {translate(translations.DECLINED_THE_REQUEST)}
        </Typography>
      );
    case ORDER_RIDER_STATUS.ASSIGNED:
      return (
        <Typography variant="element16" color={colors.green[600]}>
          {translate(translations.ASSIGNED_SUCCESSFULLY)}
        </Typography>
      );
    case ORDER_RIDER_STATUS.ACCEPTED:
    case ORDER_RIDER_STATUS.PUSHED:
      return (
        <Typography variant="element16" color={colors.green[600]}>
          {translate(translations.ASSIGNED_SUCCESSFULLY)}
        </Typography>
      );
    case ORDER_RIDER_STATUS.DISPATCHED:
      return (
        <Typography variant="element16" color={colors.blue.primary}>
          {translate(translations.DELIVERING)}
        </Typography>
      );
    case ORDER_RIDER_STATUS.CANCELED:
      return (
        <Typography variant="element16" color={colors.red[600]}>
          {translate(translations.CANCELED_ORDERS)}
        </Typography>
      );
    case ORDER_RIDER_STATUS.DELIVERED:
      return (
        <Typography variant="element16" color={colors.green[600]}>
          {translate(translations.DELIVERED)}
        </Typography>
      );
    default:
      return '';
  }
};

OrderRiderStatus.propTypes = {
  status: PropTypes.oneOf([
    ORDER_RIDER_STATUS.PENDING,
    ORDER_RIDER_STATUS.DECLINED,
    ORDER_RIDER_STATUS.ACCEPTED,
    ORDER_RIDER_STATUS.PUSHED,
    ORDER_RIDER_STATUS.DECLINED,
    ORDER_RIDER_STATUS.PICKED,
    ORDER_RIDER_STATUS.DELIVERED,
  ]),
};
export default OrderRiderStatus;
