import React from 'react';
import { Frame, Table } from 'components/common/orders';

export default () => {
  return (
    <Frame status="past">
      <Table />
    </Frame>
  );
};
