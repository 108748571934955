import React from 'react';
import moment from 'moment';

import { ReactComponent as DeliveryCard } from 'assets/card-delivery.svg';
import { ReactComponent as Cash } from 'assets/cash.svg';
import { ReactComponent as Knet } from 'assets/knet.svg';
import { ReactComponent as ApplePay } from 'assets/apple-pay.svg';
import { ReactComponent as Mada } from 'assets/mada.svg';
import { ReactComponent as Meeza } from 'assets/meeza-icon.svg';

import {
  TODAY,
  YESTERDAY,
  DELIVERY,
  PICKUP,
  BEACH,
  CASH,
  CARDONDELIVERY,
  CREDITCARD,
  MEEZA,
  KNET,
  APPLE_PAY,
  MADA,
  WALLET,
} from 'constants/translations';
import { ReactComponent as Delivery } from 'assets/delivery.svg';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { ShoppingBagIcon, UmbrellaIcon, CreditCardIcon, WalletIcon } from '@zydalabs/zac-icons-react';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';

/**
 * Function used to return the date in format of DD MM YYYY
 * @param timezone the store timezone fetched from the context
 * @param date the date where order is created or submitted
 * @param lang the language of the dashboard
 * @returns the date in form of DD MM YYYY
 */
export const getDay = (timezone: string, date: string, lang: string): string => {
  const isToday = moment(date).isSame(new Date(), 'days');
  const isYesterday = moment(date).isSame(moment().subtract(1, 'days'), 'day');
  const normalDay = moment(date)
    .tz(timezone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('DD MMM YYYY');

  if (isToday) return TODAY[lang === 'en' ? 0 : 1];
  if (isYesterday) return YESTERDAY[lang === 'en' ? 0 : 1];
  return normalDay;
};

/**
 * Function used to return time in format of H:MM: A
 * @param timezone the store timezone fetched from the context
 * @param date the date where order is created or submitted
 * @param lang the language of the dashboard
 * @returns the time in form of H:MM: A
 */
export const getTime = (timezone: string, date: string, lang: string): string =>
  moment(date)
    .tz(timezone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('h:mm A');

/**
 * Function that returns the order icon along with the order type in text
 * @param orderDeliveryType the delivery type of the order
 * @returns corresponding icon that refers to the delivery type with the delivery type
 */
export const getOrderIcon = (
  orderDeliveryType: string,
  translate: (item: string[]) => string,
): [React.FunctionComponent<React.SVGProps<SVGSVGElement>>, string] => {
  switch (orderDeliveryType) {
    case DELIVERY_BUSY_MODE.DELIVERY:
      return [Delivery, translate(DELIVERY)];
    case DELIVERY_BUSY_MODE.PICKUP:
      return [ShoppingBagIcon, translate(PICKUP)];
    case DELIVERY_BUSY_MODE.BEACH:
      return [UmbrellaIcon, translate(BEACH)];
    default:
      return [ShoppingBagIcon, translate(PICKUP)];
  }
};

/**
 * Function that returns the payment icon along with the payment type in text
 * @param paidThrough
 * @param translate
 * @returns
 */
export const getPaymentIcon = (
  paidThrough: string,
  translate: (item: string[]) => string,
): [React.FunctionComponent<React.SVGProps<SVGSVGElement>>, string] => {
  switch (paidThrough) {
    case PAYMENT_METHODS_ENUMS.CASH:
      return [Cash, translate(CASH)];
    case PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY:
      return [DeliveryCard, translate(CARDONDELIVERY)];
    case PAYMENT_METHODS_ENUMS.CREDIT_CARD:
    case PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD:
      return [CreditCardIcon, translate(CREDITCARD)];
    case PAYMENT_METHODS_ENUMS.MEEZA:
      return [Meeza, translate(MEEZA)];
    case PAYMENT_METHODS_ENUMS.KNET:
      return [Knet, translate(KNET)];
    case PAYMENT_METHODS_ENUMS.APPLE_PAY:
      return [ApplePay, translate(APPLE_PAY)];
    case PAYMENT_METHODS_ENUMS.MADA:
      return [Mada, translate(MADA)];
    case PAYMENT_METHODS_ENUMS.WALLET:
      return [WalletIcon, translate(WALLET)];
    default:
      return [CreditCardIcon, translate(CREDITCARD)];
  }
};

export const getTimeSlot = (expectedAt: string, timeZone: string, timeSlot: string, lang: string): string => {
  const fromDate = timeSlot && timeSlot.split(',')[0];
  const toDate = timeSlot && timeSlot.split(',')[1];
  const theFromDate =
    fromDate &&
    moment(fromDate)
      .tz(timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theToDate =
    toDate &&
    moment(toDate)
      .tz(timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theTimeSlot = theFromDate && theToDate && `${theFromDate} - ${theToDate}`;
  return theTimeSlot
    ? `${theTimeSlot} ${moment(expectedAt)
        .tz(timeZone)
        .locale(lang === 'ar' ? 'ar' : 'en-gb')
        .format('DD MMM YYYY')}`
    : expectedAt
    ? `${moment(expectedAt)
        .tz(timeZone)
        .locale(lang === 'ar' ? 'ar' : 'en-gb')
        .format('h:mm A, DD MMM YYYY')}`
    : '';
};
