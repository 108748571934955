import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const OrderDate = ({ date, timeZone }) => {
  const { lang } = useContext(localeContext);
  const { colors } = useTheme();

  const direction = lang === 'ar' ? 'rtl' : 'ltr';

  return moment(date).isSame(new Date(), 'day') ? (
    // same day
    <span className="flex flex-col w-20 items-start inline-block" style={{ direction }}>
      <Typography variant="element14" color={colors.positive.secondary}>
        <Text value={translations.TODAY} />
      </Typography>
      <Typography>
        {moment
          .tz(date, timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </Typography>
    </span>
  ) : moment(date).isSame(moment().subtract(1, 'days'), 'day') ? (
    // yesterday
    <span className="flex flex-col w-20 items-start inline-block" style={{ direction }}>
      <Typography variant="element14" color={colors.positive.secondary}>
        <Text value={translations.YESTERDAY} />
      </Typography>
      <Typography color={colors.yellow[600]}>
        {moment(date)
          .tz(timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </Typography>
    </span>
  ) : (
    // other
    <span className="flex flex-col items-start inline-block" style={{ direction }}>
      <Typography variant="element14" color={colors.positive.secondary}>
        {moment
          .tz(date, timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('DD MMM YYYY')}
      </Typography>
      <Typography color={colors.red[600]}>
        {moment
          .tz(date, timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </Typography>
    </span>
  );
};

OrderDate.propTypes = {
  date: PropTypes.string,
  timeZone: PropTypes.string,
};
export default OrderDate;
