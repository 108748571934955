import { gql } from 'graphql-request';

const totalEngage = gql`
  query($subdomain: String!) {
    totalEngagements(subdomain: $subdomain) {
      totalSentMessages
      totalEngageOrders
      totalLostEngageUsers
      totalEnqueuedEngageUsers
    }
  }
`;

export default totalEngage;
