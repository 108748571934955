import React, { useState, useContext, useRef, useEffect } from 'react';
import { Error } from 'components/form/generic';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';

export default ({
  error,
  hideError,
  label,
  icon = '',
  options,
  name,
  testId,
  value,
  initialValue,
  noBorder,
  width,
  height = 'h-10',
  mapInput,
  spacing = '',
  onChange,
  direction,
  after = false,
  prepend,
  append,
  showPassword = () => null,
  inputPadding,
  iconPadding,
  search,
  isMenu = false,
  isStock = false,
  bgColor,
  ...props
}) => {
  var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  const ios =
    navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i);

  const [isFocused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(!options ? value : value && value[0]);
  useEffect(() => {
    setInputValue(!options ? value : value && value[0]);
  }, [value]);
  const { lang } = useContext(localeContext);
  const ref = useRef();
  direction = direction ? direction : lang === 'ar' ? 'rtl' : 'ltr';
  return (
    <div className="flex flex-col w-full">
      <div
        className={cx(
          'flex-1 flex flex-row',
          'justify-center items-center',
          isMenu && !isStock && 'xl:w-60',
          height,
          noBorder ? '' : 'border',
          error ? 'border-zyda-red-500 bg-zyda-red-50' : isFocused ? 'border-primary-base' : 'border-gray-300',
          width && `w-${width}`,
          bgColor ? bgColor : 'bg-white',
        )}
        style={{ direction }}
      >
        {prepend && (
          <div className="flex -mr-px">
            <span
              className={cx(
                'flex-2 flex justify-center items-center w-auto rounded-l bg-gray-200 text-center px-4',
                isSafari || ios ? 'h-auto' : height,
                props.prependColor ? props.prependColor : 'text-gray-500',
              )}
            >
              {prepend}
            </span>
          </div>
        )}
        {!after && icon && (
          <i
            className={cx(
              'material-icons text-2xl text-zyda-grey-400 group-hover:text-primary-base flex-2 ',
              iconPadding ? iconPadding : lang === 'en' ? (isMenu ? 'pl-1' : 'pl-4 pr-2') : 'pr-4 pl-2',
            )}
          >
            {icon}
          </i>
        )}
        <input
          style={{ direction }}
          ref={ref}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          className={cx(
            'w-full text-sm flex-shrink flex-grow flex-auto text-zyda-black-100 appearance-none rounded-l border-zyda-red-500 ',
            isSafari || ios ? 'h-10' : height,
            'text-zyda-black-100 leading-tight focus:outline-none',
            spacing,
            inputPadding ? inputPadding : 'px-2',
            bgColor ? bgColor : 'bg-white',
          )}
          name={name}
          data-testid={testId}
          value={inputValue || inputValue === 0 ? inputValue : ''}
          defaultValue={initialValue}
          onChange={e => {
            setInputValue(e.target.value);
            onChange &&
              (!options
                ? onChange(e)
                : onChange({
                    target: {
                      name,
                      value: [e.target.value, value[1]],
                    },
                  }));
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...props}
        />
        {after && icon && (
          <i
            className={cx(
              'material-icons text-2xl text-gray-700 group-hover:text-primary-base flex-2 ',
              lang === 'en' ? 'pl-4 pr-2' : 'pr-4 pl-2',
            )}
            onClick={() => showPassword()}
          >
            {icon}
          </i>
        )}
        {search && inputValue && (
          <i
            className={cx(
              'material-icons flex-2 cursor-pointer text-gray-700 text-l',
              lang === 'en' ? 'pl-4 pr-2' : 'pr-4 pl-2',
            )}
            onClick={() => {
              setInputValue('');
              onChange({
                target: {
                  name,
                  value: '',
                },
              });
            }}
          >
            close
          </i>
        )}
        {(label || options) && (
          <div className={cx('flex items-center bg-zyda-grey-80 py-1 px-2 text-xs -ml-px h-full', props.appendColor)}>
            {append}
          </div>
        )}
      </div>
      {!hideError && error && <Error direction={direction}>{error}</Error>}
    </div>
  );
};
