import React, { useContext, useEffect } from 'react';
import { Redirect, navigate, useLocation } from '@reach/router';
import { context as userContext } from 'context/user';
import { redirectTo } from './utils';
import Unauthenticated from './Unauthenticated';
import Authenticated from './Authenticated';
import * as paths from '../../../paths';
import { useReplaceParams } from 'hooks';
import { DASHBOARD_LAYOUT } from '../../../services/user';

export default ({ name, guard, component: Component, ...props }) => {
  const location = useLocation();
  const replace = useReplaceParams();
  const {
    hasRole,
    isAuthenticated,
    settings,
    selectedStoreId,
    setFacebookConnect,
    user,
    isCustomerSupport,
  } = useContext(userContext);
  const isSupport = isCustomerSupport(user);
  useEffect(() => {
    if (location.pathname.includes(paths.facebookConnect))
      setFacebookConnect({ connecting: true, query: location.search });
  }, [location]);
  if (!isAuthenticated && (!guard || guard(isAuthenticated))) {
    return <Unauthenticated component={Component} name={name} {...props} />;
  }

  if (!isAuthenticated && !guard(false)) {
    return <Redirect noThrow to={redirectTo(false)} />;
  }

  if (settings?.dashboardLayout === DASHBOARD_LAYOUT.RESTAURANT && props.path.includes('catalog')) {
    navigate(
      replace(paths.menu, {
        storeId: selectedStoreId,
      }),
    );
  }

  if (settings?.dashboardLayout === DASHBOARD_LAYOUT.RETAIL && props.path.includes('menu')) {
    navigate(
      replace(paths.productsCatalog, {
        storeId: selectedStoreId,
      }),
    );
  }

  if (!hasRole('operator') && props.path.includes('itemTracking')) {
    navigate(
      replace(paths.variantItemTracking, {
        storeId: selectedStoreId,
      }),
    );
  }

  if ((hasRole('operator') || !isSupport) && props.path === '/:storeId/overview') {
    navigate(
      replace(paths.currentOrders, {
        storeId: selectedStoreId,
      }),
    );
  }

  if (hasRole('operator') && props.path.includes('item-tracking')) {
    navigate(
      replace(paths.itemTracking, {
        storeId: selectedStoreId,
      }),
    );
  }

  if (!hasRole('operator') && props.path.includes('optionGroups')) {
    navigate(
      replace(paths.menuOptionGroups, {
        storeId: selectedStoreId,
      }),
    );
  }

  if (hasRole('operator') && props.path.includes('option-groups')) {
    navigate(
      replace(paths.operatorOptionGroups, {
        storeId: selectedStoreId,
      }),
    );
  }

  return <Authenticated name={name} component={Component} guard={guard} {...props} />;
};
