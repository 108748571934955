import React, { useContext } from 'react';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import EngageIcon1 from 'assets/engageIcon1.svg';
import EngageIcon2 from 'assets/engageIcon2.svg';
import EngageIcon3 from 'assets/engageIcon3.svg';

import {
  ENGAGE_DISABLE_NOTE_1,
  ENGAGE_DISABLE_NOTE_1_DESC,
  ENGAGE_DISABLE_NOTE_2,
  ENGAGE_DISABLE_NOTE_2_DESC,
  ENGAGE_DISABLE_NOTE_3,
  ENGAGE_DISABLE_NOTE_3_DESC,
} from 'constants/translations';

const EngageInfoList = () => {
  const { translate } = useContext(localeContext);

  return (
    <div className="mb-8 md:flex gap-7">
      <div className="md:w-1/3 md:mb-0 mb-6">
        <div className="mb-6">
          <img src={EngageIcon1} alt="Icon1" />
        </div>
        <div className="w-90p">
          <Typography variant="heading20" mb={8}>
            {translate(ENGAGE_DISABLE_NOTE_1)}
          </Typography>
          <Typography variant="body14">{translate(ENGAGE_DISABLE_NOTE_1_DESC)}</Typography>
        </div>
      </div>
      <div className="md:w-1/3 md:mb-0 mb-6">
        <div className="mb-6">
          <img src={EngageIcon2} alt="Icon2" />
        </div>

        <div className="w-90p">
          <Typography variant="heading20" mb={8}>
            {translate(ENGAGE_DISABLE_NOTE_2)}
          </Typography>
          <Typography variant="body14">{translate(ENGAGE_DISABLE_NOTE_2_DESC)}</Typography>
        </div>
      </div>
      <div className="md:w-1/3">
        <div className="mb-6">
          <img src={EngageIcon3} alt="Icon3" />
        </div>

        <div className="w-90p">
          <Typography variant="heading20" mb={8}>
            {translate(ENGAGE_DISABLE_NOTE_3)}
          </Typography>
          <Typography variant="body14">{translate(ENGAGE_DISABLE_NOTE_3_DESC)}</Typography>
        </div>
      </div>
    </div>
  );
};

export default EngageInfoList;
