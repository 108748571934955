import React, { useContext } from "react"

import cx from "classnames"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import * as translations from "constants/translations"
import moment from "moment"
import { PaidMethodsIcon } from "utils/imagesResolver"

const PaymentTransactionTable = ({
  paymentTransactions,
  selectedStore,
  footer,
}) => {
  const { lang, direction } = useContext(localeContext)
  const headCellClassName =
    "w-1/5 text-justify font-semibold border-b border-gray-300 py-3 px-5 whitespace-nowrap"

  const cellClassName = cx(
    "w-1/4 px-4 py-3 border-b border-gray-300 whitespace-nowrap last:w-full"
  )
  return (
    <>
      <table
        style={{ direction }}
        className="py-2 w-full table-auto border-collapse border-t border-r border-l overflow-auto"
      >
        <thead>
          <tr className="bg-gray-200">
            <th className={headCellClassName}>
              <Text
                value={translations.ORDER_ID}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.REFERENCE_ID}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.PAYMENT_DATE}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.PAYMENT_STATUS}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.PAYMENT_METHOD}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.BRANCH_NAME}
                className="whitespace-nowrap inline-block"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {paymentTransactions?.map((item, i) => (
            <tr key={item.id}>
              <td
                className={cx(
                  "px-4 border-b border-gray-300 truncate py-3"
                )}
              >
                <div className="cursor-default flex items-center border border-transparent">
                  {item.orderNumber}
                </div>
              </td>
              <td className={cx(cellClassName)}>{item.chargeId}</td>
              <td className={cx(cellClassName)}>
                {moment(item.paymentDate)
                  .tz(selectedStore.timeZone)
                  .format("h:mm A, DD MMM YYYY")}
              </td>
              <td className={cx(cellClassName)}>
                <PaymentTransactionStatus status={item.status} lang={lang} />
              </td>
              <td
                className={cx(
                  "flex items-center justify-start py-3 border-b border-gray-300",
                  lang === "ar" ? "flex-row-reverse" : "flex-row"
                )}
              >
                <div className="h-4">
                  {/* I would suggest using tailwind to pass the props width and use it inform of className,
                   I don't know if you are gonna change the parent component 
                   PaidMethodsIcon in that case or not */}
                  <PaidMethodsIcon
                    paidThrough={item.paymentMethod}
                    width="1.25rem"
                    height="1.25rem"
                  />
                </div>
                <div
                  className={cx(
                    "px-2",
                    lang === "ar" ? "text-right" : "text-left"
                  )}
                >
                  <span className="block text-gray-700 text-sm">
                    <PaidThrough paidThrough={item.paymentMethod} />
                  </span>
                  <span style={{ direction }} className="block">
                    {item.amount.toFixed(item.currency.decimals)}
                    <Text value={item.currency} className="inline pl-1" />
                  </span>
                </div>
              </td>
              <td className={cx(cellClassName)}>
                {lang === "en" ? item.branchNameEn : item.branchNameAr}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {footer && (
        <div
          className={cx(
            "py-2 w-full md:w-auto flex items-center border-b border-l border-r border-gray-300"
          )}
        >
          {!!paymentTransactions.length && footer}
        </div>
      )}
    </>
  )
}

export default PaymentTransactionTable

// TODO :: Move to utils & Fix Magic Strings & Instead of returning entire text component return text value only
const PaidThrough = ({ paidThrough }) => {
  switch (paidThrough) {
    case "BENEFIT":
      return <Text value={translations.BENEFIT} />
    case "CREDIT_CARD":
      return <Text value={translations.CREDIT_CARD} />
    case "MEEZA":
      return <Text value={translations.MEEZA} />
    case "KNET":
      return <Text value={translations.KNET} />
    case "APPLE_PAY":
      return <Text value={translations.APPLE_PAY} />
    case "MADA":
      return <Text value={translations.MADA} />
    default:
      return ""
  }
}

// TODO :: Re-design to a single component with dynamic classNames
const PaymentTransactionStatus = ({ status, lang }) => {
  switch (status) {
    case "INITIATED":
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-primary-base" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_INITIATED}
              className={cx(
                lang === "en" ? "ml-2" : "mr-2",
                "text-primary-base"
              )}
            />
          </div>
        </div>
      )
    case "FAILED":
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-red-500" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_FAILED}
              className={cx(
                lang === "en" ? "ml-2" : "mr-2",
                "text-red-600"
              )}
            />
          </div>
        </div>
      )
    case "DECLINED":
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-red-500" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_DECLINED}
              className={cx(
                lang === "en" ? "ml-2" : "mr-2",
                "text-red-600"
              )}
            />
          </div>
        </div>
      )
    case "CAPTURED":
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-green-500" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_CAPTURED}
              className={cx(
                lang === "en" ? "ml-2" : "mr-2",
                "text-green-600"
              )}
            />
          </div>
        </div>
      )
    case "ABANDONED":
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-yellow-300" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_ABANDONED}
              className={cx(
                lang === "en" ? "ml-2" : "mr-2",
                "text-yellow-600"
              )}
            />
          </div>
        </div>
      )
    default:
      return ""
  }
}
