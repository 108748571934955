import React, { useRef, useContext, useState } from 'react';
import cx from 'classnames';

import { useClickOutside, useMobile } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { Spinner } from 'components/kit';

const Modal = ({
  children,
  clickOutsideOptions,
  top,
  isMenu = false,
  emptyState = false,
  allowClose = false,
  isDzmsTableView = false,
  customWidth,
  customHeight,
}) => {
  const [modal, setModal] = useState();
  const [canClose, setCanClose] = useState(true);
  const close = () => {
    return canClose && setModal(null);
  };
  const isMobile = useMobile();

  return (
    <>
      {children({
        open: setModal,
        close,
        closeable: setCanClose,
      })}
      {modal && (
        <div
          className={cx(
            'fixed top-0 left-0 w-full h-full flex justify-center z-20',
            isMobile ? 'items-center overflow-y-auto' : top ? 'items-start py-24 overflow-y-auto' : 'items-center',
          )}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        >
          <Content
            emptyState={emptyState}
            modal={modal}
            onClose={close}
            isMobile={isMobile}
            clickOutsideOptions={clickOutsideOptions}
            isMenu={isMenu}
            allowClose={allowClose}
            isDzmsTableView={isDzmsTableView}
            customWidth={customWidth}
            customHeight={customHeight}
          />
        </div>
      )}
    </>
  );
};

const Content = ({
  modal,
  clickOutsideOptions,
  onClose,
  isMobile,
  isMenu,
  emptyState,
  allowClose,
  isDzmsTableView,
  customWidth,
  customHeight,
}) => {
  const ref = useRef();
  useClickOutside(ref, onClose, clickOutsideOptions);
  const { lang } = useContext(localeContext);

  return (
    <div
      data-testid={modal.testId}
      ref={ref}
      className={cx(
        'bg-white sm:w-full',
        emptyState && 'max-h-80 lg:max-h-65',
        !isDzmsTableView && 'overflow-y-auto',
        customWidth ? customWidth : isMenu && isMobile ? 'w-full h-screen fix-height' : 'max-w-xs w-11/12',
        `shadow rounded-none ${modal.size || 'sm:max-w-sm md:max-w-md xl:max-w-xl'}`,
        'relative',
        !!customHeight && customHeight,
      )}
    >
      {modal.title && (
        <header>
          <div
            className={cx(
              'flex items-center justify-between px-4 mb-4 py-3 border-b border-gray-300',
              lang === 'ar' && !isMenu && !isDzmsTableView && 'flex-row-reverse',
            )}
          >
            <div className={cx(isDzmsTableView && 'flex-1', lang === 'en' ? 'mr-5' : 'ml-5')}>
              <div className="font-semibold">{modal.title}</div>
              {modal.subTitle && (
                <div className="w-full">
                  <div className="text-gray-600 text-xs">{modal.subTitle}</div>
                </div>
              )}
            </div>
            {!allowClose && (
              <i className="material-icons text-zyda-black-100 text-base cursor-pointer" onClick={() => onClose()}>
                close
              </i>
            )}
          </div>
        </header>
      )}
      {modal.loading ? (
        <Spinner />
      ) : (
        <div className={cx(!customHeight && 'pb-4', !!customHeight && 'h-full')}>{modal.body}</div>
      )}
    </div>
  );
};

export default Modal;
