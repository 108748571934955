import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { RESTAURANT_COURIER_AUTHENTICATION_TYPE } from 'constants/restaurant';
import { Text } from 'components/service';
import { Section } from 'components/form/generic';
import * as translations from 'constants/translations';
import PosIntegration from './POS';
import CourierIntegration from './Courier';

const IntegrationSection = ({ branchId, setFieldValue, posBranchId, couriers }) => {
  const { courier, isDeliveryCourier, isPosCourier } = useContext(userContext);
  const isAuthPerBranch = courier?.authenticationType === RESTAURANT_COURIER_AUTHENTICATION_TYPE.BRANCH_KEY;

  return (
    <Section title={<Text value={translations.INTEGRATIONS} />}>
      {isPosCourier && (
        <PosIntegration
          {...{
            branchId,
            setFieldValue,
            posBranchId,
          }}
        />
      )}

      {isAuthPerBranch && isDeliveryCourier && (
        <CourierIntegration
          {...{
            branchId,
            setFieldValue,
            couriers,
          }}
        />
      )}
    </Section>
  );
};

IntegrationSection.propTypes = {
  branchId: PropTypes.string.isRequired,
  posBranchId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default IntegrationSection;
