import React, { useContext, useState, useRef } from "react"
import moment from "moment"
import cx from "classnames"
import { useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { Field, Text } from "components/service"
import { Stack, DropDown, SpinnerAlt } from "components/kit"
import { LangSwitch, Row, Section, Label, Error } from "components/form/generic"
import {
  Input,
  Textarea,
  RadioGroup,
  RadioList,
  InfoCheckbox,
  CheckboxAlt,
  DatePicker,
  TimePicker,
  Stepper,
} from "components/form/elements"
import * as translations from "constants/translations"
import * as data from "./data"
import { transformUser } from "components/common/team/utils"
import smsCount from "./smsCount"
import { ReactComponent as IconsInfo } from "assets/icons-info.svg"
import * as yup from "yup"
import { ESTIMATE_REACH } from "./schemas"

export default ({ bottom, isEdit, initialValues, onSubmit, isAnnouncement }) => {
  const { translate, lang, direction } = useContext(localeContext)
  const { selectedStore } = useContext(userContext)
  const notifications = useContext(notificationsContext)
  const typingTimeout = useRef()
  var today = new Date()
  var tomorrow = today.setDate(today.getDate() + 1)
  const [getEstimateReach, { loading }] = useMutation(ESTIMATE_REACH, {
    variables: {
      storeId: selectedStore.id,
    },
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body
      for (let key of Object.keys(body)) {
        notifications.show(`${key} ${body[key][0]}`, "error")
      }
    },
  })

  return (
    <Formik
      initialValues={{
        ...data.initialValues,
        ...initialValues,
      }}
      validationSchema={
        isEdit
          ? data.editValidationSchemaCreation
          : data.createValidationSchemaCreation
      }
      onSubmit={data => {
        onSubmit({
          ...data,
          title: data.title,
          smsBody: data.smsBody,
          type: data.type,
          inactivityPeriod: parseInt(data.inactivityPeriod),
          lookbackWindow: data.lookbackWindow.toString(),
          smsSendingLimit: data.smsSendingLimit,
          startsAt: data.startsAt,
          expiresAt: data.expiresAt || null,
          voucherType: data.voucherType,
          voucherAmount:
            data.type !== "free_delivery" ? data.voucherAmount : 0.0,
          voucherMinSubtotalAmount:
            data.minimumRequirements === "none" ? 0.0 : data.minSubtotalAmount,
          redemptionLimitPerUser: data.redemptionLimitPerUserEnabled
            ? data.voucherRedemptionLimitPerUser === ""
              ? 0
              : data.voucherRedemptionLimitPerUser
            : 0,
        })
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        submitForm,
        errors,
      }) => (
        <Form
          className={cx(
            "w-full md:w-2/3 pb-20",
            direction === "rtl" && "ml-auto"
          )}
        >
          <Section title={<Text value={translations.BASIC_DETAILS} />}>
            <Row>
              <Label title={<Text value={translations.CAMPAIGN_TITLE} />}>
                <Field
                  type="text"
                  name="title"
                  disabled={isEdit}
                  maxLength={50}
                  placeholder={translate(translations.EX_CHECK_OUR_OFFERS)}
                  component={Input}
                />
              </Label>
            </Row>
            <Row>
              <Label title={<Text value={translations.SMS_CONTENT} />}>
                <Field
                  rows="6"
                  name="smsBody"
                  disabled={isEdit}
                  placeholder={translate(translations.PLACEHOLDER_SMS_BODY)}
                  component={SmsBody}
                  validate={value =>
                    !isEdit && yup
                      .string()
                      .required(translations.SMS_CONTENT_REQUIRED)
                      .max(smsCount(value).perMessage, translations.MAXIMUM_CHARACTERS)
                      .validate(value)
                      .then(() => undefined)
                      .catch(({ errors }) => errors[0])
                  }
                  onChange={e => {
                    handleChange(e)
                    setFieldTouched("smsBody", true)
                  }}
                  setFieldValue={setFieldValue}
                  lang={lang}
                />

              </Label>
            </Row>
          </Section>
          {!isEdit && (
            <>
              <Section title={<Text value={translations.CAMPAIGN_TYPE} />}>
                <Field
                  name="type"
                  direction="col"
                  wFull
                  border
                  items={[
                    {
                      title: (
                        <Text value={translations.RETENTION} className="my-1" />
                      ),
                      subTitle: (
                        <Text
                          value={translations.ENCOURAGE_YOUR_CURRENT_CUSTOMERS}
                          className="mb-1"
                        />
                      ),
                      value: "Retention",
                    },
                    {
                      title: (
                        <div className="whitespace-nowrap my-1">
                          <Text
                            value={translations.CONVERSION}
                            className="inline font-semibold"
                          />
                          <Text
                            value={translations.COMING_SOON}
                            className="inline font-normal mx-2"
                          />
                        </div>
                      ),
                      subTitle: (
                        <Text
                          value={translations.ATTRACY_NEW_CUSTOMERS}
                          className="mb-1"
                        />
                      ),
                      value: "Conversion",
                      disabled: true,
                    },
                  ]}
                  component={RadioList}
                />
              </Section>

              <Section title={<Text value={translations.SETTINGS} />}>
                <div className="w-full md:w-2/3">
                  <Label
                    title={<Text value={translations.INACTVITY_PERIOD} />}
                    subtitle={
                      <Text value={translations.INACTVITY_PERIOD_DESC} />
                    }
                  >
                    <Field
                      name="inactivityPeriod"
                      type="number"
                      append={
                        <Text
                          value={translations.DAYS}
                          className="capitalize"
                        />
                      }
                      min={0}
                      step={1}
                      placeholder={translate(translations.EX_30)}
                      onChange={e => {
                        let value = e.target.value
                        handleChange(e)
                        clearTimeout(typingTimeout.current)
                        typingTimeout.current =
                          value &&
                          setTimeout(async () => {
                            await getEstimateReach({
                              variables: {
                                type: values.type,
                                inactivityPeriod: parseInt(e.target.value),
                                lookbackWindow: values.lookbackWindow,
                              },
                            }).then(({ data }) => {
                              setFieldValue(
                                "estimateReach",
                                data.estimateReach.estimateReach
                              )
                            })
                          }, 1000)
                      }}
                      component={Input}
                      validation={n => n >= 0}
                    />
                  </Label>
                </div>
                <div className="w-full md:w-2/3">
                  <Label
                    title={<Text value={translations.LOOK_BACK_WINDOW} />}
                    subtitle={
                      <Text value={translations.LOOK_BACK_WINDOW_DESC} />
                    }
                  >
                    <div style={{ direction: "ltr" }}>
                      <DropDown
                        float={"left"}
                        optionSelected={values.lookbackWindow}
                        onSelect={async option => {
                          setFieldValue("lookbackWindow", option)
                          option !== values.lookbackWindow &&
                            (await getEstimateReach({
                              variables: {
                                type: values.type,
                                inactivityPeriod:
                                  parseInt(values.inactivityPeriod) || 0,
                                lookbackWindow: option,
                              },
                            }).then(({ data }) => {
                              setFieldValue(
                                "estimateReach",
                                data.estimateReach.estimateReach
                              )
                            }))
                        }}
                        data={[
                          {
                            id: "all_customers",
                            title: <Text value={translations.ALL_CUSTOMERS} />,
                          },
                          {
                            id: "last_year",
                            title: <Text value={translations.LAST_YEAR} />,
                          },
                          {
                            id: "last_6_months",
                            title: <Text value={translations.LAST_6_MONTHS} />,
                          },
                          {
                            id: "last_3_months",
                            title: <Text value={translations.LAST_3_MONTHS} />,
                          },
                          {
                            id: "last_month",
                            title: <Text value={translations.LAST_MONTH} />,
                          },
                        ]}
                        icon="keyboard_arrow_down"
                        position={lang === "ar" ? "right" : "left"}
                        width="w-48"
                        wFull
                      />
                    </div>
                  </Label>
                </div>
                <div className="w-full md:w-2/3">
                  <Label
                    title={<Text value={translations.MESSAGES_LIMIT} />}
                    subtitle={<Text value={translations.MESSAGES_LIMIT_DESC} />}
                  >
                    <Field
                      name="smsSendingLimit"
                      type="number"
                      append={
                        <Text
                          value={translations.SMS}
                          className="whitespace-nowrap"
                        />
                      }
                      min={0}
                      step={1}
                      placeholder={translate(translations.EX_1000)}
                      component={Input}
                      validation={n => n >= 0}
                    />
                  </Label>
                </div>
                <div className="flex items-center text-gray-700">
                  <IconsInfo className="inline mx-2" />
                  <Text
                    value={translations.NOTED_COOLDOWN_CAMPAIGN}
                    className="inline"
                  />
                </div>
              </Section>
              <Section
                title={<Text value={translations.ESTIMATED_RESULTS} />}
                description={
                  <Text value={translations.ESTIMATED_RESULTS_DESC} />
                }
              >
                <div>
                  <Text value={translations.REACH} className="mb-2" />
                  {loading ? (
                    <SpinnerAlt color="primary-base" />
                  ) : (
                    <Text
                      value={translations.NUMBERS_CUSTOMERS}
                      payload={values.estimateReach}
                      className="font-semibold text-lg"
                    />
                  )}
                </div>
              </Section>
            </>
          )}
          {!isAnnouncement && (
            <Section title={<Text value={translations.TIME_PERIOD} />}>
              <Row>
                <Label title={<Text value={translations.START_DATE} />}>
                  <Field
                    name="startsAt"
                    disabled={isEdit && values.sentSmsCount !== 0}
                    minDate={today}
                    component={DatePicker}
                    onChange={e => {
                      handleChange(e)
                      setFieldTouched("expiresAt", true)
                    }}
                    dateFormat={lang === "en" ? "MMM d, yyyy" : "MMMM d, yyyy"}
                  />
                </Label>
                <Label title={<Text value={translations.END_DATE} />}>
                  <Field
                    name="expiresAt"
                    component={DatePicker}
                    onChange={e => {
                      handleChange(e)
                      setFieldTouched("expiresAt", true)
                    }}
                    minDate={tomorrow}
                    isRemovable
                    dateFormat={lang === "en" ? "MMM d, yyyy" : "MMMM d, yyyy"}
                  />
                </Label>
              </Row>
            </Section>
          )}
          
          {!isEdit && (
            <>
              <div className="mb-6">
                <Field
                  component={InfoCheckbox}
                  name="AddVoucher"
                  title={<Text value={translations.ADD_VOUCHER} />}
                  description={
                    <Text
                      value={
                        translations.YOU_CAN_CREATE_DEDICAT_VOUCHER_CAMPAIGN
                      }
                    />
                  }
                  body={
                    values.AddVoucher && (
                      <div className="px-4 -mt-4 mb-2">
                        <Stack spacing="3" direction="col">
                          <Label title={<Text value={translations.TYPE} />}>
                            <Field
                              name="voucherType"
                              items={[
                                {
                                  title: (
                                    <Text value={translations.PERCENTAGE} />
                                  ),
                                  value: "percentage",
                                },
                                {
                                  title: <Text value={translations.AMOUNT} />,
                                  value: "amount",
                                },
                                {
                                  title: (
                                    <Text value={translations.FREE_DELIVERY} />
                                  ),
                                  value: "free_delivery",
                                },
                              ]}
                              component={RadioGroup}
                              onChange={e => {
                                handleChange(e)
                                if (e.target.value === "free_delivery") {
                                  setFieldValue("appliedTo", "entire_order")
                                }
                              }}
                            />
                          </Label>
                          {(values.voucherType === "percentage" ||
                            values.voucherType === "amount") && (
                            <div className="max-w-sm w-full">
                              <Label
                                title={<Text value={translations.AMOUNT} />}
                              >
                                <Field
                                  name="voucherAmount"
                                  left={
                                    values.voucherType === "percentage" && "%"
                                  }
                                  max={
                                    values.voucherType === "percentage" && 100
                                  }
                                  min={1}
                                  step={1}
                                  placeholder={0}
                                  component={Stepper}
                                  validation={n => n >= 0}
                                />
                              </Label>
                            </div>
                          )}
                        </Stack>
                        <Stack spacing="3" direction="col">
                          <Label
                            title={
                              <Text value={translations.MINIMUM_REQUIREMENTS} />
                            }
                          >
                            <Field
                              name="minimumRequirements"
                              items={[
                                {
                                  title: <Text value={translations.NONE} />,
                                  value: "none",
                                },
                                {
                                  title: (
                                    <Text
                                      value={translations.MINIMUM_ORDER_AMOUNT}
                                    />
                                  ),
                                  value: "minimumOrderAmount",
                                },
                              ]}
                              component={RadioGroup}
                            />
                          </Label>
                          {values.minimumRequirements ===
                            "minimumOrderAmount" && (
                            <div className="w-64">
                              <Field
                                name="voucherMinSubtotalAmount"
                                left={translate(selectedStore.currency)}
                                min={0}
                                step={1}
                                placeholder={0}
                                component={Stepper}
                                validation={n => n >= 0}
                              />
                            </div>
                          )}
                        </Stack>
                        <Stack spacing="3" direction="col">
                          <Label
                            title={<Text value={translations.USAGE_LIMITS} />}
                          >
                            <div className="mt-2">
                              
                              <div className="mt-2">
                                <Field
                                  name="redemptionLimitPerUserEnabled"
                                  title={
                                    <Text
                                      value={translations.USAGE_LIMITS_OPTION_2}
                                    />
                                  }
                                  component={CheckboxAlt}
                                />
                                {values.redemptionLimitPerUserEnabled && (
                                  <div className="mt-3 w-64">
                                    <Field
                                      name="voucherRedemptionLimitPerUser"
                                      max={
                                        values.redemptionLimit !==
                                          (null || 0) &&
                                        values.redemptionLimit
                                      }
                                      min={0}
                                      step={1}
                                      placeholder={0}
                                      component={Stepper}
                                      validation={n => n >= 0}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Label>
                        </Stack>
                      </div>
                    )
                  }
                />
              </div>
            </>
          )}
          {isEdit
            ? bottom
            : bottom(submitForm, isSubmitting, values, setFieldValue)}
        </Form>
      )}
    </Formik>
  )
}

const SmsBody = ({
  error,
  lang,
  direction = "ltr",
  onChange,
  name,
  setFieldValue,
  disabled,
  ...props
}) => {
  const [currentLength, setCurrentLength] = useState(0)
  const [smsMax, setSmsMax] = useState(160)
  const ref = useRef()

  return (
    <div className="w-full">
      <div
        className={cx(
          "flex flex-col items-start border border-zyda-grey-80 focus:border-primary-base focus:ring",
          error && "border-zyda-red-500 bg-zyda-red-50",
          !disabled && "hover:border-primary-200"
        )}
      >
        <textarea
          ref={ref}
          style={{ direction }}
          className={cx(
            "block appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none resize-none"
          )}
          maxLength={smsMax}
          disabled={disabled}
          onChange={e => {
            let value = e.target.value
            setCurrentLength(smsCount(value).length)
            setSmsMax(smsCount(value).perMessage)
            onChange({
              target: {
                name,
                value: e.target.value,
              },
            })
          }}
          {...props}
        />
        <div className="w-full px-3 pb-4 text-xs">
          <Text value={translations.TOOLBOX} className="mb-2" />
          <div className="flex flex-wrap items-end">
            <div className="flex">
              <button
                type="button"
                className="py-1 px-3 border border-gray-300 disabled:cursor-default"
                onClick={e => {
                  e.preventDefault()
                  setFieldValue("smsBody", props.value.concat(" =DL "))
                  ref.current.focus()
                }}
                disabled={disabled}
              >
                <Text value={translations.DIRECT_LINK} />
              </button>
              <button
                type="button"
                className="py-1 px-3 border border-gray-300 mx-2 disabled:cursor-default"
                onClick={e => {
                  e.preventDefault()
                  setFieldValue("smsBody", props.value.concat(" =VC "))
                  ref.current.focus()
                }}
                disabled={disabled}
              >
                <Text value={translations.VOUCHER_CODE_TOOLBOX} />
              </button>
              <button
                type="button"
                className="py-1 px-3 border border-gray-300 disabled:cursor-default"
                onClick={e => {
                  e.preventDefault()
                  setFieldValue("smsBody", props.value.concat(" =VV "))
                  ref.current.focus()
                }}
                disabled={disabled}
              >
                <Text value={translations.VOUCHER_VALUE_TOOLBOX} />
              </button>
            </div>
            <div
              className={cx(
                lang === "en" ? "ml-auto" : "mr-auto",
                "mt-2 text-gray-700"
              )}
            >{`${currentLength} / ${smsMax}`}</div>
          </div>
        </div>
      </div>

      {error && <Error>{error}</Error>}
    </div>
  )
}
