import { useContext } from 'react';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { context as userContext } from 'context/user';
import { restaurantTopSellingProducts } from 'service/queries';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { AnalyticsProduct } from '../../types/generated/backend_client_index';

type useFetchTopSellingItemsType = () => QueryHookResponse<Array<AnalyticsProduct>>;

const useFetchTopSellingItems: useFetchTopSellingItemsType = () => {
  const { selectedStore } = useContext(userContext);
  const token = userService.getToken();
  const { subdomain } = selectedStore || {};
  return useQuery<Array<AnalyticsProduct>>({
    query: restaurantTopSellingProducts,
    variables: { subdomain },
    requestHeaders: { Authorization: `Bearer ${token}` },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    handler: (res: any) => res.restaurantAnalyticsSummary.topSellingProducts,
    config: { revalidateOnMount: true },
  });
};
export default useFetchTopSellingItems;
