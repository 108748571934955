import { gql } from 'graphql-request';

const retentionProgress = gql`
  query($subdomain: String!) {
    engageSalesStats(subdomain: $subdomain) {
      engagePhaseOne {
        ordersPlaced
        totalOrdersValue
      }
      engagePhaseTwo {
        ordersPlaced
        totalOrdersValue
      }
      engagePhaseThree {
        ordersPlaced
        totalOrdersValue
      }
    }
  }
`;

export default retentionProgress;
