// @ts-nocheck
import React, { useContext } from 'react';
import { Formik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Wizard } from 'components/kit';
import { AJWAN_STEP_1, AJWAN_STEP_2, AUTHORIZED_SUCCESSFULLY } from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { useSelectedStore, useDeliveryZoneMsToken } from 'hooks';
import { Text } from 'components/service';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import { context } from './context';
import { AJWAN_CONFIRMATION } from './schemas';
import { AJWAN_ENUMS, validateAjwanForm } from './data';
import { RESTAURANT } from '../../schemas';

const Ajwan = ({ courier, onCancel, defaultStep }) => {
  const {
    integrationData: { courierIntegrationInfo, courierDynamicSection, branchLinkingSection },
  } = courier;
  const storeId = useSelectedStore();
  const { setSelectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const { initialValues, ajwanValues, setAjwanValues, setAjwanBranches, ajwanBranches } = useContext(context);
  const { merchantId, externalId, menuId, discountId } = ajwanValues;
  const ajwanIds = { merchantId, externalId, menuId, discountId };
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const [getRestaurantCourierSettings] = useLazyQuery(RESTAURANT, {
    variables: {
      storeId,
    },
    onCompleted: ({ restaurant }) => {
      setSelectedStore(restaurant);
    },
    onError: err => {
      throw err;
    },
    fetchPolicy: 'cache-and-network',
  });

  const [ajwanConfirmation, { loading: isConfirming }] = useMutation(AJWAN_CONFIRMATION, {
    context: { headers: { token: deliveryZoneMsToken } },
    onCompleted: data => {
      if (data?.updateAjwanConfirmation?.message === AJWAN_ENUMS.OK) {
        getRestaurantCourierSettings();
        notifications.show(<Text value={AUTHORIZED_SUCCESSFULLY} />, 'success');
      }
    },
  });
  const steps = [
    {
      title: AJWAN_STEP_1,
      Component: () => (
        <FirstStep
          courierIntegrationInfo={courierIntegrationInfo}
          courierDynamicSection={courierDynamicSection}
          ajwanIds={ajwanIds}
          setAjwanValues={setAjwanValues}
        />
      ),
    },
    {
      title: AJWAN_STEP_2,
      Component: () => (
        <SecondStep
          setAjwanBranches={setAjwanBranches}
          branchLinkingSection={branchLinkingSection}
          ajwanBranches={ajwanBranches}
        />
      ),
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async () => {
        const isValid = validateAjwanForm(ajwanValues, notifications);
        if (isValid) {
          await ajwanConfirmation({
            variables: {
              storeId,
              branches: ajwanBranches,
              menuId: ajwanValues.menu_id,
              zydaBrandName: ajwanValues.brand_name,
              zydaBrandId: ajwanValues.brand_id,
            },
          }).then(res => {
            if (res) {
              getRestaurantCourierSettings();
              onCancel && onCancel();
            }
          });
        }
      }}
    >
      {({ errors, setFieldValue, setFieldError, validateForm, submitForm, setFieldTouched }) => (
        <Wizard isModal defaultStep={defaultStep} isLoading={isConfirming}>
          {steps.map((step, i) => (
            <step.Component
              title={step.title}
              key={step.title}
              stepKey={i}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              errors={errors}
              validateForm={validateForm}
              submitForm={submitForm}
            />
          ))}
        </Wizard>
      )}
    </Formik>
  );
};

Ajwan.propTypes = {
  courier: PropTypes.shape({
    integrationData: PropTypes.shape({
      courierIntegrationInfo: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          body: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
        }),
      ),
      courierDynamicSection: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          inputField: PropTypes.shape({
            backendName: PropTypes.string.isRequired,
            hintAr: PropTypes.string.isRequired,
            hintEn: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }),
        }),
      ),
      branchLinkingSection: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          inputField: PropTypes.shape({
            backendName: PropTypes.string.isRequired,
            hintAr: PropTypes.string.isRequired,
            hintEn: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }),
  onCancel: PropTypes.func.isRequired,
  defaultStep: PropTypes.number.isRequired,
};

export default Ajwan;
