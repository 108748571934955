/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import QRCodeStyling, { DotType } from 'qr-code-styling';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { breadcrumbs, SEE_PERFORMANCE } from 'constants/translations';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { getStoreURl } from 'components/common/dashboard/Layout/utils';
import { useMobile } from 'hooks';
import QRCodeBanner from './components/QRCodeBanner';
import QRCodeStyle from './components/QRCodeStyle';

const QRCodePage: React.FC = () => {
  const { colors } = useTheme();
  const [isChecked, setIsChecked] = useState(false);
  const [buttonSelected, setButtonSelected] = useState<DotType>('square');
  const { selectedStore } = useContext(userContext);
  const storeUrl = getStoreURl(selectedStore);
  const { direction, translate, lang } = useContext(localeContext);
  const ref = useRef(null);
  const isMobile = useMobile();

  const qrCode = new QRCodeStyling({
    width: 1200,
    height: 1200,
    dotsOptions: {
      color: colors.positive.absolute,
      type: buttonSelected,
    },
    data: `${storeUrl}/?utm_source=qr_code_restaurant`,
  });

  useEffect(() => {
    qrCode.append(ref.current);
  }, [qrCode]);

  const onDownloadClick = () => {
    qrCode.download({
      extension: 'svg',
    });
  };

  useEffect(() => {
    qrCode.update({
      dotsOptions: { type: buttonSelected },
      image: isChecked ? selectedStore?.logoUrl : null,
      cornersSquareOptions: {
        type:
          buttonSelected === 'square' || buttonSelected === 'classy-rounded'
            ? 'square'
            : buttonSelected === 'dots'
            ? 'dot'
            : buttonSelected === 'rounded'
            ? 'extra-rounded'
            : 'dot',
      },
      cornersDotOptions: {
        type: buttonSelected === 'square' ? 'square' : 'dot',
      },
    });
  }, [buttonSelected, qrCode, isChecked]);

  const handleOnChangeSize = (size: DotType) => setButtonSelected(size);
  const qrCodeClasses = `cursor-pointer ${lang === 'en' ? 'pr-3' : 'pl-3'}`;

  return (
    <Layout
      top={
        <div>
          <Breadcrumbs path={breadcrumbs.QR_CODE} />
        </div>
      }
      direction={direction}
    >
      <div className={cx('w-full', isMobile ? 'flex-col-reverse inline-flex' : 'flex')}>
        <div className={cx('w-full md:w-4/5', isMobile && 'flex flex-col')}>
          <QRCodeStyle
            translate={translate}
            handleOnChangeSize={handleOnChangeSize}
            buttonSelected={buttonSelected}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            onDownloadClick={onDownloadClick}
            qrCodeClasses={qrCodeClasses}
          />
          <QRCodeBanner translate={translate} />
        </div>

        <div className={cx('w-full md:w-1/5 flex flex-col', lang === 'en' ? !isMobile && 'pl-8' : !isMobile && 'pr-8')}>
          <div className="contents flex-row" ref={ref} />
          <div className="flex flex-row py-4">
            <Typography color={colors.positive.secondary} variant="element12">
              {translate(SEE_PERFORMANCE)}
            </Typography>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QRCodePage;
