import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import verdDriversQuery from '../../queries/verd/verdDrivers';

type useFetchVerdDriversType = (args: any) => QueryHookResponse<any>;

const useFetchVerdDrivers: useFetchVerdDriversType = args => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return useQuery<any>({
    query: verdDriversQuery,
    variables: { ...args },
    requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
    clientName: GRAPHQL_CLIENT_NAMES.VERD,
    handler: (res: any) => res?.allStoreDrivers,
    config: { revalidateOnMount: true },
  });
};

export default useFetchVerdDrivers;
