import { gql } from 'graphql-request';

const bulkAssignDriverToOrdersMutation = gql`
  mutation($driverId: Int!, $ordersNumbers: [String]!) {
    bulkAssignDriverToOrders(driverId: $driverId, ordersNumbers: $ordersNumbers) {
      ok
      message
      failedOrders
    }
  }
`;

export default bulkAssignDriverToOrdersMutation;
