import React from 'react';
import cx from 'classnames';

import InfoBlock from './InfoBlock';
import { OrderDateBlockType } from './types';

const OrderDateBlock: React.FC<OrderDateBlockType> = ({
  lang,
  total,
  displayedDay,
  displayedHour,
  PaymentIcon,
  currency,
  isMobile,
}) => (
  <div
    className={cx(
      'border-b md:border-b-0 border-gray-300',
      lang === 'ar' ? 'md:border-l' : 'md:border-r',
      isMobile && 'flex justify-between w-full',
    )}
  >
    <div className="w-32 flex flex-col items-start px-6 py-4 pr-4 flex-initial md:flex-2">
      <InfoBlock elementText={displayedDay} bodyText={displayedHour} />
    </div>
    {isMobile && (
      <div
        className={cx(
          'flex flex-row items-center justify-between py-4 flex-initial md:flex-2',
          lang === 'en' ? 'pr-4' : 'pl-4',
        )}
      >
        <span className={cx('flex w-5 h-5 items-center justify-center', lang === 'en' ? 'mr-2' : 'ml-2')}>
          <PaymentIcon />
        </span>
        <InfoBlock
          bodyText={`${total.toFixed(currency.decimals)} ${
            currency && lang === 'en' ? currency.titleEn : currency.titleAr
          }`}
        />
      </div>
    )}
  </div>
);

export default OrderDateBlock;
