import { gql } from '@apollo/client';

export const LIST = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      addressEn
      addressAr
      lat
      lng
      dineIn
      pickupOrdersEnabled
      deliveryOrdersEnabled
      busyFrom
      busyUntil
      busy
      busyMode
      externalId
    }
  }
`;

export const CITIES = gql`
  query cities($countryId: String!) {
    cities(countryId: $countryId) {
      id
      titleAr
      titleEn
      slug
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteBranch($id: String!, $restaurantId: String!) {
    deleteBranch(id: $id, restaurantId: $restaurantId) {
      id
    }
  }
`;

export const SUBSCRIPTION_PLAN = gql`
  query($storeId: String!) {
    subscription(restaurantId: $storeId) {
      countryPlan {
        plan {
          titleEn
        }
      }
    }
  }
`;
