import React, { useContext } from 'react';

import { Button } from 'components/kit';
import { Text } from 'components/service';
import { context as notificationsContext } from 'context/notifications';
import { BLOCKED_CAMPAIGN_NOTE, CREATE_CAMPAIGN } from 'constants/translations';

const BlokcedCampaignButton = ({ size = 'full' }) => {
  const notifications = useContext(notificationsContext);

  return (
    <Button
      size={size}
      kind="primary"
      onClick={() => notifications.show(<Text value={BLOCKED_CAMPAIGN_NOTE} />, 'error')}
    >
      <Text className="font-semibold" value={CREATE_CAMPAIGN} />
    </Button>
  );
};

export default BlokcedCampaignButton;
