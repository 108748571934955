import { gql } from '@apollo/client';

export const CREATE_BRANCH = gql`
  mutation CreateBranch(
    $restaurantId: String!
    $titleEn: String!
    $titleAr: String!
    $lat: Float!
    $lng: Float!
    $forceScheduledThreshold: Int!
    $pickupOrdersEnabled: Boolean!
    $pickupOrdersType: PickupType
    $deliveryOrdersEnabled: Boolean!
    $contactNumber: String!
    $posExternalId: String
    $courierExternalId: [CourierExternalIdsType]
    $addressEn: String
    $addressAr: String
    $buildingNumber: String
    $street: String
    $cityId: Int
    $block: String
    $deliveryNotes: String
    $dineIn: Boolean
    $busy: Boolean
    $openingHours: [WorkingDay]
  ) {
    createBranch(
      restaurantId: $restaurantId
      titleEn: $titleEn
      titleAr: $titleAr
      contactNumber: $contactNumber
      lat: $lat
      lng: $lng
      forceScheduledThreshold: $forceScheduledThreshold
      pickupOrdersEnabled: $pickupOrdersEnabled
      pickupOrdersType: $pickupOrdersType
      deliveryOrdersEnabled: $deliveryOrdersEnabled
      posExternalId: $posExternalId
      courierExternalId: $courierExternalId
      addressEn: $addressEn
      addressAr: $addressAr
      buildingNumber: $buildingNumber
      street: $street
      cityId: $cityId
      block: $block
      deliveryNotes: $deliveryNotes
      dineIn: $dineIn
      busy: $busy
      openingHours: $openingHours
    ) {
      id
      titleEn
      titleAr
      lat
      lng
      busyUntil
      openingHours {
        day
        openAt
        closeAt
        offDay
      }
    }
  }
`;

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      lat
      lng
      busyUntil
      externalId
    }
  }
`;
