import React from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';

const TextDetails = ({ children }) => {
  const { colors } = useTheme();

  return (
    <Typography variant="body12" color={colors.gray[600]}>
      {children}
    </Typography>
  );
};

export default TextDetails;
