import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Text } from 'components/service';
import { DropDown, SpinnerAlt } from 'components/kit';
import * as translations from 'constants/translations';
import { useClickOutside } from 'hooks/index';
import { Stepper, CheckboxAlt } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import * as schemas from './../Variants/schemas';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { PREP_TIME_DATE, handleSubmittingPrepTime } from './utils';
import { minsInitialConverts } from 'pages/setup/orders/Scheduling/utils';

const TableRow = ({ storeId, productId, item, pageType }) => {
  const { lang } = useContext(localeContext);
  const { selectedStore: selected } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const [updateVariant, { loading }] = useMutation(schemas.UPDATE_VARIANT, {
    variables: {
      restaurantId: storeId,
      menuItemId: productId,
      variantId: item.id,
    },
  });
  const prepTimeData = item && minsInitialConverts(item.prepTime);
  const [optionSelected, setOptionSelected] = useState(prepTimeData[1]);
  const options = [
    {
      id: PREP_TIME_DATE.MINS,
      title: <Text value={translations.MINUTES} />,
    },
    {
      id: PREP_TIME_DATE.HRS,
      title: <Text value={translations.HOURS} />,
    },
    {
      id: PREP_TIME_DATE.DAYS,
      title: <Text value={translations.DAYS} />,
    },
  ];

  return (
    <tr className="border-b border-gray-300">
      <td className="py-2 px-6" data-testid={`${pageType}-prepTime-variant-title`}>
        <Text value={item} className="capitalize" />
      </td>

      <Input
        onSave={async prepTime => {
          if (prepTime < selected.defaultProductPrepTime && prepTime !== -1) {
            notifications.show(<Text value={translations.PREP_TIME_LESS_ERROR} />, 'error');
          } else {
            await updateVariant({
              variables: {
                variantId: item.id,
                prepTime: parseInt(prepTime),
              },
            });
          }
        }}
        isSaving={loading}
        setOptionSelected={setOptionSelected}
        optionSelected={optionSelected}
        title={prepTimeData[0]}
        actualMinutes={item?.prepTime}
        testId={`${pageType}-prepTime-variant-checkbox`}
        renderInput={({ value, isChecked, onChange }) => (
          <Stepper
            data-testid={`${pageType}-prepTime-input`}
            name="prepTimeInput"
            min={0}
            step={1}
            right={
              <DropDown
                float={'right'}
                icon="expand_more"
                optionSelected={optionSelected}
                data={options}
                bg
                noBorder
                position={lang === 'ar' ? 'right' : 'left'}
                combined
                onSelect={option => {
                  setOptionSelected(option);
                }}
              />
            }
            value={value}
            disabled={isChecked}
            onChange={onChange}
            placeholder={translations.EX_50[lang === 'en' ? 0 : 1]}
            validation={n => n >= 0}
            prepTime
          />
        )}
      />
    </tr>
  );
};

TableRow.propTypes = {
  storeId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
  item: PropTypes.shape({ prepTime: PropTypes.number.isRequired }),
  pageType: PropTypes.string.isRequired,
};

export default TableRow;

const Input = ({ isSaving, title, onSave, renderInput, testId, setOptionSelected, actualMinutes, optionSelected }) => {
  const { selectedStore: selected } = useContext(userContext);
  const [value, setValue] = useState(title === -1 ? selected.defaultProductPrepTime : title);
  const [isChecked, setIsChecked] = useState(
    actualMinutes === selected.defaultProductPrepTime || actualMinutes < 0 ? true : false,
  );
  const ref = useRef();

  useClickOutside(ref, async () => {
    let prepTimeAfterConvert = handleSubmittingPrepTime(
      optionSelected,
      parseInt(value),
      selected.defaultProductPrepTime,
    );
    if (
      parseInt(prepTimeAfterConvert) !== parseInt(title) &&
      parseInt(prepTimeAfterConvert) !== selected.defaultProductPrepTime
    ) {
      if (prepTimeAfterConvert) {
        await onSave(prepTimeAfterConvert);
      }
    }
  });
  return (
    <>
      <td className="py-2 px-6">
        <div className="mt-3">
          <CheckboxAlt
            testId={testId}
            name={`setDefaultPrepTime`}
            title={<Text value={translations.SET_DEFAULT_PREPTIME} />}
            value={isChecked}
            onChange={async e => {
              setOptionSelected('mins');
              setIsChecked(e.target.value);
              if (e.target.value) {
                setValue(selected.defaultProductPrepTime);
                await onSave(-1);
              }
            }}
          />
        </div>
      </td>
      <td className="py-2 px-6">
        <div ref={ref} className="w-40 -ml-2 flex items-center">
          {renderInput({
            value,
            isChecked,
            onChange: e => setValue(e.target.value),
          })}
          {isSaving && (
            <span className="ml-3">
              <SpinnerAlt color="primary-base" />
            </span>
          )}
        </div>
      </td>
    </>
  );
};
