import { gql } from '@apollo/client';

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
    }
  }
`;

export const AJWAN_CONFIRMATION = gql`
  mutation updateAjwanConfirmation(
    $storeId: String
    $branches: [AjwanBranches]
    $menuId: String
    $zydaBrandName: String
    $zydaBrandId: String
  ) {
    updateAjwanConfirmation(
      storeId: $storeId
      branches: $branches
      menuId: $menuId
      zydaBrandName: $zydaBrandName
      zydaBrandId: $zydaBrandId
    ) {
      message
    }
  }
`;
