import { gql } from '@apollo/client';

export const SETTINGS = gql`
  query settings($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      isAutoAccept
      cardondeliveryPaymentEnabled
    }
  }
`;

export const LIST_COURIERS = gql`
  query couriers($businessType: String, $countryCode: String, $isPublished: Boolean) {
    couriers(businessType: $businessType, countryCode: $countryCode, isPublished: $isPublished) {
      isBeta
      logoUrl
      inNetwork
      id
      name
      authenticationType
      integrationData {
        csOnly
        integrationsConstraints
        learnMore {
          en
          ar
        }
        courierBasicInfo {
          title {
            en
            ar
          }
          description {
            en
            ar
          }
        }
        courierIntegrationInfo {
          header {
            en
            ar
          }
          body {
            en
            ar
          }
        }
        courierDynamicSection {
          header {
            en
            ar
          }
          body {
            template
          }
          title {
            en
            ar
          }
          inputField {
            type
            required
            hintEn
            hintAr
            backendName
          }
        }
        branchLinkingSection {
          title {
            en
            ar
          }
          inputField {
            type
            required
            hintEn
            hintAr
            backendName
          }
        }
      }
    }
  }
`;

export const LIST_POS = gql`
  query pos($isPublished: Boolean) {
    pos(isPublished: $isPublished) {
      isBeta
      logoUrl
      id
      name
      authenticationType
      integrationData {
        whiteListedEmails
        csOnly
        integrationsConstraints
        learnMore {
          en
          ar
        }
        courierBasicInfo {
          title {
            en
            ar
          }
          description {
            en
            ar
          }
        }
        courierIntegrationInfo {
          header {
            en
            ar
          }
          body {
            en
            ar
          }
        }
        courierDynamicSection {
          header {
            en
            ar
          }
          body {
            template
          }
          title {
            en
            ar
          }
          inputField {
            type
            required
            hintEn
            hintAr
            backendName
          }
        }
        branchLinkingSection {
          title {
            en
            ar
          }
          inputField {
            type
            required
            hintEn
            hintAr
            backendName
          }
        }
      }
    }
  }
`;
