import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  NAME,
  MOBILE,
  EMAIL,
  ADDRESS,
  NOTES,
  CAR_MODEL,
  CAR_COLOR,
  CAR_LICENSE,
  UMBRELLA_NUMBER,
  UMBRELLA_INSTRUCTION,
} from 'constants/translations';

const ThermalInvoiceUserInfo = ({ lang, direction, translate, order, isGift, addressTranslation }) => {
  const { userData: { recipient, name, phoneNumber, email, address, car } = {} } = order || {};

  return (
    <div className={cx('flex p-1 border-dashed border-gray-300 border-t ', lang === 'ar' && 'flex-row-reverse')}>
      <div className="flex flex-col">
        <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-xs font-bold" style={{ direction }}>
            {translate(NAME)}:
          </span>
          <span className="text-xs px-3 font-bold" style={{ direction }}>
            {isGift ? recipient?.name : name}
          </span>
        </div>
        <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-xs font-bold" style={{ direction }}>
            {translate(MOBILE)}:
          </span>
          <span className="text-xs font-bold px-3">{isGift ? recipient?.phoneNumber : phoneNumber}</span>
        </div>
        {address && (
          <>
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs font-bold" style={{ direction }}>
                {translate(ADDRESS)}:
              </span>
              <span className="text-xs font-bold px-3" style={{ direction }}>
                {addressTranslation(address)}
              </span>
            </div>
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs font-bold" style={{ direction }}>
                {translate(NOTES)}:
              </span>
              {address.notes ? (
                <span className="text-xs font-bold px-3" style={{ direction }}>
                  {address.notes}
                </span>
              ) : (
                <span className="text-xs font-bold px-3" style={{ direction }}>
                  -
                </span>
              )}
            </div>
          </>
        )}
        {!isGift && (
          <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-xs font-bold" style={{ direction }}>
              {translate(EMAIL)}:
            </span>
            {email ? (
              <span className="text-xs font-bold px-3" style={{ direction }}>
                {email}
              </span>
            ) : (
              <span className="text-xs font-bold px-3" style={{ direction }}>
                -
              </span>
            )}
          </div>
        )}
        {order.beachUmbrella && (
          <div className="w-full flex flex-col" style={{ direction }}>
            <span className="text-xs pb-1 font-bold">
              {translate(UMBRELLA_NUMBER)} {order.beachUmbrella?.number}
            </span>
            {order.beachUmbrella?.specialDirections && (
              <span className="text-xs font-bold">
                {translate(UMBRELLA_INSTRUCTION)} {order.beachUmbrella?.specialDirections}
              </span>
            )}
          </div>
        )}

        {car && (
          <>
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs font-bold" style={{ direction }}>
                {translate(CAR_MODEL)}:
              </span>
              <span className="text-xs px-3 font-bold" style={{ direction }}>
                {car.make || car.model}
              </span>
            </div>
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs font-bold" style={{ direction }}>
                {translate(CAR_COLOR)}:
              </span>
              <span className="text-xs px-3 font-bold" style={{ direction }}>
                {car.color}
              </span>
            </div>
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs font-bold" style={{ direction }}>
                {translate(CAR_LICENSE)}:
              </span>
              {car.licenseNumber ? (
                <span className="text-xs px-3 font-bold" style={{ direction }}>
                  {car.licenseNumber}
                </span>
              ) : (
                <span className="text-xs px-3 font-bold" style={{ direction }}>
                  -
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ThermalInvoiceUserInfo.propTypes = {
  lang: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  order: PropTypes.shape({
    userData: PropTypes.shape({
      recipient: PropTypes.string,
      name: PropTypes.string,
      phoneNumber: PropTypes.number,
      email: PropTypes.string,
      address: PropTypes.shape({
        area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
      }),
      car: PropTypes.string,
    }),
    beachUmbrella: PropTypes.shape({
      number: PropTypes.number,
      specialDirections: PropTypes.string,
    }),
  }),

  isGift: PropTypes.bool,
  addressTranslation: PropTypes.func,
  translate: PropTypes.func,
};

export default ThermalInvoiceUserInfo;
