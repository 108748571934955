import React, { useContext } from 'react';
import { Typography } from '@zydalabs/zac-react';
import { HelpIcon } from '@zydalabs/zac-icons-react';
import noOrders from 'assets/no-orders.svg';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const NoOrders = () => {
  const { translate } = useContext(localeContext);
  return (
    <div className="flex flex-col items-center gap-3">
      <img className="mt-4" src={noOrders} alt="" />
      <Typography variant="heading20">{translate(translations.READY_FOR_ORDERS)}</Typography>
      <Typography variant="body14">{translate(translations.RECEIVE_ORDERS)}</Typography>
      <div className="flex items-center gap-1">
        <HelpIcon width="20px" />
        <Typography variant="body14">{translate(translations.ORDER_WILL_BE_MOVED)}</Typography>
      </div>
    </div>
  );
};

export default NoOrders;
