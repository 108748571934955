import React from 'react';
import cx from 'classnames';
import { Tag } from '@zydalabs/zac-react';

import { ASAP, SCHEDULED, PENDING_ORDERS, ACCEPTED_ORDERS } from 'constants/translations';

import { ORDER_STATUS } from 'constants/order';
import InfoBlock from './InfoBlock';
import { OrderInfoBlockType } from './types';

const OrderInfoBlock: React.FC<OrderInfoBlockType> = ({
  lang,
  number,
  customerName,
  Icon,
  deliveryTypeInText,
  branchName,
  PaymentIcon,
  paymentTypeInText,
  total,
  currency,
  isScheduled,
  translate,
  status,
  theTimeSlot,
  isMobile,
}) => (
  <div
    className={cx('w-full flex flex-wrap flex-1 px-6 py-4 items-center select-none', lang === 'ar' && 'flex-reverse')}
  >
    <div className="w-full flex flex-row justify-between pb-4 md:contents">
      <div className="md:w-1/5 items-start flex flex-col">
        <InfoBlock elementText={`#${number}`} bodyText={customerName} />
      </div>
      <div className="md:w-1/5 md:items-start items-center flex flex-row">
        <span className="md:mt-1 flex md:w-10 md:h-10 w-8 h-8 rounded-full bg-gray-300  items-center justify-center">
          <Icon height={20} width={26} />
        </span>
        <div className={cx('px-3', lang === 'ar' ? 'text-right' : 'text-left')}>
          <InfoBlock elementText={deliveryTypeInText} bodyText={branchName} />
        </div>
      </div>
    </div>

    <div className={cx('w-full md:w-1/5 items-start flex flex-row', isMobile && 'hidden')}>
      <span className="mt-1 flex w-10 h-10 rounded-full bg-gray-300  items-center justify-center">
        <PaymentIcon />
      </span>
      <div className={cx('px-3', lang === 'ar' ? 'text-right' : 'text-left')}>
        <InfoBlock
          elementText={paymentTypeInText}
          bodyText={`${total.toFixed(currency.decimals)} ${
            currency && lang === 'en' ? currency.titleEn : currency.titleAr
          }`}
        />
      </div>
    </div>
    <div className="w-full flex flex-row justify-between md:contents">
      <div className="w-full md:w-1/5 items-start flex flex-col">
        <InfoBlock elementText={translate(isScheduled ? SCHEDULED : ASAP)} bodyText={theTimeSlot} />
      </div>
      <div className="w-full md:w-1/5 flex justify-end items-end">
        <div className="mx-1">
          <Tag
            color={status === ORDER_STATUS.SUBMITTED ? 'orange' : 'blue'}
            inner={{
              text: translate(status === ORDER_STATUS.SUBMITTED ? PENDING_ORDERS : ACCEPTED_ORDERS),
            }}
            size="medium"
          />
        </div>
      </div>
    </div>
  </div>
);

export default OrderInfoBlock;
