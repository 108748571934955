import React, { useContext } from "react"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import { Button, Typography } from '@zydalabs/zac-react';
import { XIcon, CheckIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from "context/notifications"
import * as translations from "constants/translations"
import { replaceParams } from "utils/index"
import { Text } from "components/service"
import * as paths from "paths.js"
import * as schemas from "./schemas"

export default ({ id, storeId, onClose }) => {
  const { translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const { settings } = useContext(userContext);
  const { engageEnabled } = settings || {};

  const [deletePaymentCredentials, { loading: isDeleting }] = useMutation(
    schemas.DELETE_PAYMENT_CREDENTIAL,
    {
      onCompleted: () => {
        notifications.show(
          <Text value={translations.DELETED_PAYMENT_SUCCESS} />
        )
        navigate(replaceParams(paths.paymentGateways, { storeId }))
      },
    }
  );

  return (
    <>
      
      <div>
        <div className="p-4 border-b border-zyda-grey-50 flex justify-between items-center mb-4">
          <div>
            <Typography variant="heading16">{translate(translations.DELETED_PAYMENT_GATEWAY)}</Typography>
          </div>

          <div className="flex items-center justify-center cursor-pointer">
            <XIcon width="32" color="#000" onClick={onClose} />
          </div>
        </div>

        <div className="px-4">
          <ul className="mb-8">
            <li className="mb-2 flex items-start">
              <CheckIcon width="16" color="#000" />
              <div className="flex-1">
                <Typography variant="body12">
                  <Text value={translations.IF_YOU_DELETE_PAYMENT_GATEWAY} className="px-2" />
                </Typography>
              </div>
            </li>
            {engageEnabled && (
              <li className="mb-2 flex items-start">
                <CheckIcon width="16" color="#000" />
                <div className="flex-1">
                  <Typography variant="body12">
                    <Text value={translations.DELETED_PAYMENT_GATEWAY_NOTE} className="px-2" />
                  </Typography>
                </div>
              </li>
            )}
          </ul>

          <div className="flex justify-end gap-2">
            <Button rounded variant="secondary" text={translate(translations.CANCEL)} onClick={onClose} />
            <Button 
              rounded 
              variant="primary" 
              disabled={isDeleting}
              isLoading={isDeleting} 
              text={translate(translations.CONFIRM)} 
              onClick={() =>
                deletePaymentCredentials({
                  variables: {
                    id,
                    storeId,
                  },
                })
              } 
            />
          </div>
        </div>
    </div>
    </>
  )
}
