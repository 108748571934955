import React, { useContext } from 'react';
import { Button, Typography } from '@zydalabs/zac-react';
import { XIcon, CheckIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const DeactivateProgramConfirmation = ({ descriptionText, actionButtonText, onConfirm, onClose, engageEnabled }) => {
  const { translate } = useContext(localeContext);

  return (
    <div>
      <div className="p-4 border-b border-zyda-grey-50 flex justify-between items-start mb-4">
        <div>
          <Typography variant="heading16">{translate(descriptionText)}</Typography>
        </div>

        <div className="flex items-center justify-center cursor-pointer">
          <XIcon width="32" color="#000" onClick={onClose} />
        </div>
      </div>

      <div className="px-4">
        {engageEnabled && (
          <ul className="mb-8">
            <li className="mb-2 flex items-start">
              <CheckIcon width="16" color="#000" />
              <div className="flex-1">
                <Typography variant="body12">
                  <Text value={translations.DEACTIVATE_CASHBACK_NOTE_WITH_ENGAGE} className="px-2" />
                </Typography>
              </div>
            </li>
          </ul>
        )}

        <div className="flex justify-end gap-2">
          <Button rounded variant="secondary" text={translate(translations.CANCEL)} onClick={onClose} />
          <Button rounded variant="primary" text={translate(actionButtonText)} onClick={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default DeactivateProgramConfirmation;
