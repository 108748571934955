import React, { useContext } from 'react';
import cx from 'classnames';
import { Form } from 'formik';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import { useReplaceParams, useSelectedStore } from 'hooks/index';
import { useFetchVerdDrivers } from 'service/hooks';
import useUpdateStoreDriver from 'service/hooks/verd/useUpdateStoreDriver';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { Text, Link } from 'components/service';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { RiderForm, RiderData } from 'components/common/verd';
import { Button, Stack, Modal, Spinner } from 'components/kit';
import * as paths from 'paths.js';
import * as translations from 'constants/translations';

const EditRiderScreen = ({ riderId }) => {
  const selectedStoreId = useSelectedStore();
  const { direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const replace = useReplaceParams();

  const { data, isLoading } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    driverId: riderId,
  });
  const updateStoreDriver = useUpdateStoreDriver();
  const rider = data?.[0];

  const handleUpdateStoreDriver = async variables => {
    const response = await updateStoreDriver({ storeId: selectedStoreId, id: riderId, ...variables });
    if (response.updateStoreDriver.errors?.length) {
      response.updateStoreDriver.errors.map(error => notifications.show(error.messages[0], 'error'));
    } else {
      navigate(replace(paths.verd));
    }
  };

  return (
    <Modal>
      {() => (
        <Layout>
          <div className={cx('w-full flex-row', direction === 'rtl' && 'ml-auto')}>
            {!rider ? (
              <div className="mt-5">
                <Spinner />
              </div>
            ) : (
              <RiderData
                initialValues={rider}
                onSubmit={variables => {
                  handleUpdateStoreDriver(variables);
                }}
              >
                {() => (
                  <Form>
                    <Breadcrumbs
                      links={[paths.verd]}
                      path={breadcrumbs.VERD_EDIT_RIDER}
                      right={
                        <Stack direction="wrap" expandItem={false} spacing="2">
                          <Button kind="primary" isSpinning={isLoading}>
                            <Text value={translations.SAVE_CHANGE} />
                          </Button>
                          <Link omitQuery to={paths.verd}>
                            <Button type="button" kind="secondary">
                              <Text value={translations.CANCEL} />
                            </Button>
                          </Link>
                        </Stack>
                      }
                    />
                    <div style={{ direction }} className={cx('pb-6 pt-4', direction === 'rtl' && 'mr-auto')}>
                      <RiderForm />
                    </div>
                  </Form>
                )}
              </RiderData>
            )}
          </div>
        </Layout>
      )}
    </Modal>
  );
};

EditRiderScreen.propTypes = {
  riderId: PropTypes.string,
};

export default EditRiderScreen;
