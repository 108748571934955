import React, { useRef, useContext } from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import * as paths from 'paths';
import { Button } from 'components/kit';
import { ReactComponent as BackButton } from 'assets/back-button.svg';
import { Text } from 'components/service';
import StoresSwitch from 'components/common/dashboard/Layout/StoresSwitch';
import { SWITCH_STORE, SWITCH_STORE_DESCRIPTION, BACK } from 'constants/translations';
import { usePressEsc, useReplaceParams, useSelectedStore } from 'hooks';

const SwitchStore = () => {
  const storeSwitchRef = useRef();
  const selectedStoreId = useSelectedStore();
  const replace = useReplaceParams();
  const { direction } = useContext(localeContext);

  const handleOnBackClick = () => {
    navigate(replace(paths.pastOrders, selectedStoreId));
  };

  usePressEsc(storeSwitchRef, handleOnBackClick);

  return (
    <div className="flex justify-center w-full h-screen" id="storeSwitch" ref={storeSwitchRef}>
      <div className="flex flex-col justify-center w-4/5 md:w-3/5 lg:w-1/3 ">
        <div className="mt-12" dir={direction}>
          <Button
            kind="tertiaryLink"
            isMenu
            type="button"
            onClick={() => navigate(replace(paths.pastOrders, selectedStoreId))}
          >
            <BackButton className={cx('h-10 text-lg', direction === 'rtl' ? 'ml-2 rotate-180' : 'mr-2')} />
            <Text className="text-primary-base text-sm" value={BACK} />
          </Button>
        </div>
        <Text className="font-semibold text-lg mb-2 mt-4" value={SWITCH_STORE} />
        <Text className="text-sm mt-2 mb-4" value={SWITCH_STORE_DESCRIPTION} />
        <StoresSwitch />
      </div>
    </div>
  );
};

export default SwitchStore;
