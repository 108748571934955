import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { TextInput } from '@zydalabs/zac-react';
import 'style.css';

import { Field, Text } from 'components/service';
import { Label } from 'components/form/generic';
import { Select } from 'components/form/elements';
import { CITIES } from 'pages/setup/branch/List/schemas';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';

const BranchAddress = ({ handleChange, branchId }) => {
  const { lang, translate, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { data } = useQuery(CITIES, {
    variables: {
      countryId: selectedStore.countryId,
    },
  });
  return (
    <div className="flex-col mt-4">
      <div className="flex flex-col md:flex-row justify-between items-start gap-3">
        <div className="w-full mb-6 md:mb-0 text-input" style={{ direction }}>
          <Field
            type="text"
            name="addressEn"
            placeholder={translations.ENTER_ADDRESS[0]}
            component={TextInput}
            label={translate(translations.ADDRESS_IN_ENGLISH)}
            data-testid="business-location-address-en"
          />
        </div>
        <div className="w-full mb-0 md:mb-0 text-input" style={{ direction }}>
          <Field
            type="text"
            name="addressAr"
            placeholder={translations.ENTER_ADDRESS[1]}
            component={TextInput}
            label={translate(translations.ADDRESS_IN_ARABIC)}
            data-testid="business-location-address-ar"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start mt-2 gap-3">
        <div className="w-full mb-6 md:mb-0 text-input" style={{ direction }}>
          <Field
            type="text"
            name="buildingNumber"
            placeholder={lang === 'en' ? translations.ENTER_BUILDING_NUMBER[0] : translations.ENTER_BUILDING_NUMBER[1]}
            component={TextInput}
            label={translate(translations.BUILDING_NUMBER)}
            data-testid="business-location-building-number"
          />
        </div>
        <div className="w-full mb-0 md:mb-0 text-input" style={{ direction }}>
          <Field
            type="text"
            name="street"
            placeholder={lang === 'en' ? translations.ENTER_STREET_NAME[0] : translations.ENTER_STREET_NAME[1]}
            component={TextInput}
            label={translate(translations.STREET_NAME)}
            data-testid="business-location-street-name"
          />
        </div>
      </div>
      <div className="flex flex-col mt-2 md:flex-row justify-between items-start gap-3">
        <div className="w-full mb-6 md:mb-0 text-input" style={{ direction }}>
          <Field
            type="text"
            name="block"
            placeholder={lang === 'en' ? translations.ENTER_BLOCK[0] : translations.ENTER_BLOCK[1]}
            component={TextInput}
            label={translate(translations.BLOCK)}
            data-testid="business-location-block"
          />
        </div>
        <div className="w-full mb-0 md:mb-0">
          <Label title={<Text value={translations.CITY} />}>
            <Field
              name="cityId"
              onChange={e => {
                handleChange(e);
              }}
              defaultValue={data?.cities?.[0].id || ''}
              placeholder={lang === 'en' ? translations.ENTER_CITY[0] : translations.ENTER_CITY[1]}
              component={Select}
              type="squared"
            >
              {data?.cities?.map(item => (
                <option key={item.id} value={item.id}>
                  {translate(item)}
                </option>
              ))}
            </Field>
          </Label>
        </div>
      </div>
      <div className="flex flex-col mt-2 md:flex-row justify-between items-start gap-3 mb-8">
        <div className="w-full mb-6 md:mb-0 text-input" style={{ direction }}>
          <Field
            type="text"
            name="deliveryNotes"
            placeholder={lang === 'en' ? translations.ENTER_DELIVERY_NOTES[0] : translations.ENTER_DELIVERY_NOTES[1]}
            component={TextInput}
            label={translate(translations.DELIVERY_NOTE)}
            data-testid="business-location-notes"
          />
        </div>
        {branchId && (
          <div className="w-full mb-0 md:mb-0 text-input" style={{ direction }}>
            <Field
              disabled
              style={{ backgroundColor: '#f1f1f1' }}
              type="text"
              name="areaEn"
              component={TextInput}
              label={translate(translations.AREA)}
              data-testid="business-location-area"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BranchAddress;
