import React, { useContext, useState, useLayoutEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { useDeliveryZoneMsToken } from 'hooks';
import { Text, Field } from 'components/service';
import { Input, Select } from 'components/form/elements';
import { Button } from 'components/kit';
import { Label, Row } from 'components/form/generic';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import UPDATE_POS_BRANCH_ID from './schemas';
import { getFoodicsBranches } from '../../../../../../rest';
import POS_ENUMS from './enums';

const PosIntegration = ({ branchId, setFieldValue, posBranchId: defaultPosBranchId }) => {
  const { translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const {
    selectedStore: { id: storeId },
    pos,
  } = useContext(userContext);
  const isDeliverect = pos?.courierDetails?.name === POS_ENUMS.DELIVERECT;
  const [posBranchId, setPosBranchId] = useState(defaultPosBranchId);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPosBranch, setCurrentPosBranch] = useState([]);
  const [foodicsBranches, setFoodicsBranches] = useState([]);
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  const shouldListExternalBranches = pos?.isBranchListingEnabled;

  useLayoutEffect(() => {
    const getFoodicsBranchesCall = async () => {
      try {
        const {
          data: { results: foodicsBranchesTemp },
        } = await getFoodicsBranches(storeId);
        setFoodicsBranches(
          foodicsBranchesTemp.map(foodicsBranch => ({
            externalId: foodicsBranch.id,
            titleEn: foodicsBranch.title_en,
            titleAr: foodicsBranch.title_ar,
          })),
        );
        setCurrentPosBranch(foodicsBranches.find(branch => branch.externalId === posBranchId));
      } catch (error) {
        notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      }
    };

    if (shouldListExternalBranches) {
      getFoodicsBranchesCall();
    }
  }, []);

  const [updatePosBranchId] = useMutation(UPDATE_POS_BRANCH_ID, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    onCompleted: data => {
      if (data?.linkInternalBranchToPosBranch?.branchExternalId) {
        setIsLoading(false);
        setIsEditable(false);
      } else {
        notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      }
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]?.extensions?.code === 409) {
        notifications.show(<Text value={translations.BRANCH_ALREADY_LINKED('')} />, 'error');
      }
      if (!err.graphQLErrors) throw err;
    },
  });

  const handleEditable = e => {
    e.preventDefault();
    if (!isEditable) setIsEditable(true);
  };

  const handleUpdatePosId = e => {
    e.preventDefault();

    if (branchId) {
      setIsLoading(true);
      updatePosBranchId({
        variables: {
          storeId,
          branchInternalId: branchId,
          branchExternalId: posBranchId,
          partnerName: pos.courierDetails.name,
        },
      });
    } else {
      setFieldValue('posBranchId', posBranchId);
      setIsEditable(false);
    }
  };

  return (
    <div className="mb-2">
      <Text className="text-lg font-semibold px-4 -mx-4 pb-2" value={translations.POINT_OF_SALE} />

      <div className="border border-gray-300 p-4">
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full pr-2">
            <Label
              title={
                <>
                  <Text
                    className="text-lg font-semibold px-4 -mx-4 pb-2 capitalize"
                    value={[pos?.courierDetails?.name, pos?.courierDetails?.name]}
                  />
                  <Text value={translations.INTEGRATIONS_TYPE_DESCRIPTION(pos?.courierDetails?.name)} />
                </>
              }
            >
              {!shouldListExternalBranches ? (
                <div className="flex flex-col w-full">
                  <Row>
                    <Field
                      type="text"
                      name="posBranchId"
                      value={posBranchId}
                      placeholder={translate(translations.ENTER_EXTERNAL_ID)}
                      component={Input}
                      stopValidateFunctionPropagation
                      onChange={e => {
                        setPosBranchId(e.currentTarget.value);
                        setFieldValue('posBranchId', e.target.value);
                      }}
                      disabled={!isEditable}
                    />
                  </Row>
                </div>
              ) : (
                <Field
                  name="posBranchId"
                  onChange={e => {
                    setPosBranchId(e.currentTarget.value);
                    setFieldValue('posBranchId', e.currentTarget.value);
                  }}
                  defaultValue={currentPosBranch?.branchExternalId || ''}
                  value={posBranchId || ''}
                  stopValidateFunctionPropagation
                  component={Select}
                  type="squared"
                  required
                  disabled={!isEditable}
                >
                  {shouldListExternalBranches &&
                    foodicsBranches?.map(item => (
                      <option key={item.externalId} value={item.externalId}>
                        {translate(item)}
                      </option>
                    ))}
                </Field>
              )}
            </Label>
          </div>

          <div className="flex items-end">
            {isEditable ? (
              <Button kind="primary" isSpinning={isLoading} onClick={handleUpdatePosId}>
                <Text value={translations.SAVE_CHANGE} />
              </Button>
            ) : (
              <Button kind="secondary" onClick={handleEditable} disabled={isDeliverect}>
                <Text value={translations.CHANGE} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PosIntegration.propTypes = {
  branchId: PropTypes.string.isRequired,
  posBranchId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default PosIntegration;
