import React, { useContext } from 'react';
import { path } from 'ramda';

import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';

import { Text } from 'components/service';
import { useSelectedStore } from 'hooks/index';
import { useCreateStoreDriver } from 'service/hooks';
import * as translations from 'constants/translations';
import VERD_RIDER_CREATED_THROUGH from 'constants/verdRiderCreatedThrough';

const useOnSubmitRider = ({
  mutateFetchVerdDrivers,
  handleAssignDriverToOrder,
  changeStatus,
  hasDontShowAgainButton,
  close,
  setIsAddingRiderLoading,
  onSubmitMessage,
  isBulkAssign,
}) => {
  const notifications = useContext(notificationsContext);
  const { selectedStore } = useContext(userContext);

  const selectedStoreId = useSelectedStore();
  const createStoreDriver = useCreateStoreDriver();

  const handleRiderCreation = async data => {
    const variables = {
      ...data,
      phone: data.phone.includes('+') ? data.phone : `+${data.phone}`,
      createdThrough: VERD_RIDER_CREATED_THROUGH.ORDER_DETAILS_POPUP,
      storeName: selectedStore.titleEn,
    };
    const response = await createStoreDriver({ ...variables, storeId: selectedStoreId });
    if (response?.errors?.length) {
      notifications.show(<Text value={[response?.errors?.[0]?.message]} />, 'error');
    } else {
      await mutateFetchVerdDrivers();
      await handleAssignDriverToOrder(response?.createStoreDriver);
      if (!isBulkAssign) {
        notifications.show(onSubmitMessage);
        if (hasDontShowAgainButton) changeStatus();
      }
      close();
      return response;
    }
    setIsAddingRiderLoading(false);
    return null;
  };
  const onSubmit = data => {
    setIsAddingRiderLoading(true);
    if (!data.name.trim()) {
      return notifications.show(<Text value={translations.NAME_SHOULD_CONTAIN_LETTERS} />, 'error');
    }
    try {
      handleRiderCreation(data);
    } catch (err) {
      const errors = path(['graphQLErrors', 0, 'extensions', 'exception', 'body'], err);

      if (errors?.phone_number) {
        notifications.show(<Text value={translations.PHONE_IS_INVALID} />, 'error');
      }
    }
    return null;
  };
  return { onSubmit };
};

export default useOnSubmitRider;
