// @ts-nocheck
import React from 'react';
import { Text } from 'components/service';
import { AJWAN_INVALID_MENU_ID, AJWAN_INVALID_BRAND_NAME, AJWAN_INVALID_BRAND_ID } from 'constants/translations';

export const AJWAN_ENUMS = {
  OK: 'OK',
};

export const validateAjwanForm = (ajwanValues, notifications) => {
  if (ajwanValues.menu_id === '') {
    notifications.show(<Text value={AJWAN_INVALID_MENU_ID} />, 'error');
    return false;
  }
  if (ajwanValues.brand_name === '') {
    notifications.show(<Text value={AJWAN_INVALID_BRAND_NAME} />, 'error');
    return false;
  }
  if (ajwanValues.brand_id === '') {
    notifications.show(<Text value={AJWAN_INVALID_BRAND_ID} />, 'error');
    return false;
  }
  return true;
};
