import React, { useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import 'style.css';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { ClockIcon } from '@heroicons/react/solid';
import { PaidMethodsIcon } from 'utils/imagesResolver';
import { ORDER_STATUS } from 'constants/order';
import CheckBox from 'components/kit/CheckBox';
import OrderDate from './OrderDate';
import StatusOrder from './StatusOrder';
import PaymentStatusOrder from './PaymentStatusOrder';
import PaidThrough from './PaidThrough';
import { getOrderIcon, getDeliveryTypeText, BulkChangeTip, ManualOrderMark } from './utils/orderSectionUtils';

const OrderItemDesktop = ({
  id,
  paidThrough,
  number,
  deliveryType,
  createdAt,
  submittedAt,
  branchName,
  branchData,
  total,
  isManualOrder,
  customerName,
  isSelected,
  updateOrderSelectedState = () => {
    // Do Nothing
  },
  isOrderUpdating,
  bulkChangeStatus,
  isScheduled,
  expectedAt,
  timeSlot,
  isFaded,
  onClick,
  status,
  paymentStatus,
  gift,
  textSize,
  showOrderingUser,
  deliveryZone,
  areaNameEn,
  areaNameAr,
  deliveryStatus,
  currentTabStatus,
}) => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { colors } = useTheme();
  const { currency } = selectedStore;
  const fromDate = timeSlot && timeSlot.split(',')[0];
  const toDate = timeSlot && timeSlot.split(',')[1];
  const theFromDate =
    fromDate &&
    moment(fromDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theToDate =
    toDate &&
    moment(toDate)
      .tz(selectedStore.timeZone)
      .locale(lang === 'ar' ? 'ar' : 'en-gb')
      .format('h:mm A');
  const theTimeSlot = theFromDate && theToDate && `${theFromDate} - ${theToDate}`;
  const areaName = lang === 'en' ? areaNameEn : areaNameAr || areaNameEn;
  return (
    <>
      {isOrderUpdating && <BulkChangeTip status={bulkChangeStatus} />}
      <div
        role="presentation"
        data-tooltip-id={isOrderUpdating && 'bulkChangeTip'}
        className={cx(
          'flex flex-col md:flex-wrap bg-gray-100 relative',
          lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row',
          'items-center cursor-pointer',
          isSelected && 'bg-primary-base/10',
          (isFaded || isOrderUpdating) && 'opacity-25',
        )}
        onClick={onClick}
        data-testid="order-number-select"
      >
        <div
          className={cx(
            'px-6 py-3 pr-4 w-34 flex-initial md:flex-2 border-b  md:border-b-0 border-gray-200',
            lang === 'ar' ? 'md:border-l flex-row-reverse' : 'md:border-r',
            'flex flex-row justify-center items-center',
          )}
        >
          {!isOrderUpdating && (
            <CheckBox value={isSelected} lang={lang} onChange={value => updateOrderSelectedState(id, value)} />
          )}
          {isOrderUpdating && (
            <ClockIcon className={cx('w-5 h-5 text-zyda-yellow-500', lang === 'ar' ? 'ml-3' : 'mr-3')} />
          )}
          <OrderDate timeZone={selectedStore.timeZone} date={submittedAt || createdAt} />
        </div>

        <div
          className={cx(
            'py-4 px-4 w-full flex-1 flex',
            lang === 'ar' && 'flex-row-reverse',
            'items-center select-none justify-between',
          )}
        >
          <div
            className={cx(
              'p-2 order-4 md:order-none md:p-0 flex items-center w-full md:w-1/5 justify-start',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
            )}
          >
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
              {showOrderingUser ? (
                <>
                  <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                    #<span className="select-all cursor-text">{number}</span>
                  </Typography>
                  <div className="text-truncation">
                    <Typography variant="heading16" cursor="pointer">
                      {customerName}
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <span className="block text-gray-700 text-xs">Order No.</span>
                  <span className="block text-sm">
                    #<span className="select-all cursor-text">{number}</span>
                  </span>
                </>
              )}
            </div>
          </div>
          <div
            className={cx(
              'flex items-center justify-start w-1/2 md:w-1/4',
              lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
            )}
          >
            <span
              className={cx(
                lang === 'ar' ? 'ml-2' : 'mr-2',
                'rounded-full bg-gray-200 w-8 h-8 px-2 flex items-center justify-center',
              )}
            >
              <span className="h-5">{getOrderIcon(deliveryType, gift, deliveryStatus, currentTabStatus)}</span>
            </span>
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
              <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                {getDeliveryTypeText(deliveryType)}
              </Typography>
              <Typography cursor="pointer">
                {branchData ? translate(branchData) : branchName}

                {// checking if store use new delivery zone system (useDeliveryzonesMs)
                // NOTE later we will migrate all stores to new system
                deliveryType === 'delivery' && ` - ${deliveryZone?.zoneName ? deliveryZone?.zoneName : areaName}`}
              </Typography>
            </div>
          </div>
          <div className={cx('flex items-center w-1/2 md:w-1/5', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
            <div>
              <PaidMethodsIcon paidThrough={paidThrough} />
            </div>
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left', textSize !== 'sm' && 'pl-2')}>
              <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                <PaidThrough paidThrough={paidThrough} />
              </Typography>
              <div style={{ direction }}>
                <Typography cursor="pointer">
                  {total.toFixed(currency.decimals)}
                  <Text value={currency} className="inline" />
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={cx(
              'w-full p-2 md:w-1/4 md:p-0 flex items-center justify-start',
              lang === 'ar' ? ' flex-row-reverse' : 'flex-row',
            )}
          >
            <div className={cx('px-2', lang === 'ar' ? 'text-right' : 'text-left')}>
              <Typography variant="element14" color={colors.positive.secondary} cursor="pointer">
                {isScheduled ? <Text value={translations.SCHEDULED} /> : <Text value={translations.ASAP} />}
              </Typography>
              <Typography cursor="pointer">
                {theTimeSlot ? (
                  <>
                    <div>{theTimeSlot}, </div>
                    <div>
                      {moment(expectedAt)
                        .tz(selectedStore.timeZone)
                        .locale(lang === 'ar' ? 'ar' : 'en-gb')
                        .format('DD MMM YYYY')}
                    </div>
                  </>
                ) : expectedAt ? (
                  moment(expectedAt)
                    .tz(selectedStore.timeZone)
                    .locale(lang === 'ar' ? 'ar' : 'en-gb')
                    .format('h:mm A, DD MMM YYYY')
                ) : (
                  ''
                )}
              </Typography>
            </div>
          </div>

          <div className={cx('flex items-center md:w-1/5', lang === 'ar' ? ' flex-row-reverse' : 'flex-row')}>
            {status !== ORDER_STATUS.PENDING ? (
              <StatusOrder status={status} />
            ) : (
              <PaymentStatusOrder paymentStatus={paymentStatus} />
            )}
          </div>
        </div>
        <div className={cx('absolute', lang === 'ar' ? 'left-6' : 'right-6')}>
          {isManualOrder && <ManualOrderMark />}
        </div>
      </div>
    </>
  );
};

OrderItemDesktop.propTypes = {
  id: PropTypes.number,
  paidThrough: PropTypes.string,
  number: PropTypes.number,
  updateOrderSelectedState: PropTypes.func,
  isOrderUpdating: PropTypes.bool,
  bulkChangeStatus: PropTypes.string,
  customerName: PropTypes.string,
  userData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
    }),
  }),
  branchData: PropTypes.shape({
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }),
  deliveryType: PropTypes.string,
  createdAt: PropTypes.string,
  submittedAt: PropTypes.string,
  branchName: PropTypes.string,
  total: PropTypes.number,
  isManualOrder: PropTypes.bool,
  isSelected: PropTypes.bool,
  areaNameEn: PropTypes.string,
  areaNameAr: PropTypes.string,
  isScheduled: PropTypes.bool,
  expectedAt: PropTypes.string,
  timeSlot: PropTypes.string,
  isFaded: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string,
  paymentStatus: PropTypes.string,
  gift: PropTypes.string,
  textSize: PropTypes.string,
  showOrderingUser: PropTypes.bool,
  deliveryStatus: PropTypes.string,
  deliveryZone: PropTypes.shape({
    zoneName: PropTypes.string,
  }),
};

export default OrderItemDesktop;
