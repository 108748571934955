import React, { useContext, useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CopyIcon, MapPinIcon, LogosWhatsAppIcon } from '@zydalabs/zac-icons-react';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import Address from '../Address';
import { copyToClipboard } from '../helpers';

const OrderDetailsUserAddress = ({ order, addressGMapURL, orderWhatsAppUrl }) => {
  const addressRef = useRef();
  const {
    settings,
    user: { isSupport },
  } = useContext(userContext);
  const hasShareWhatsapp = settings?.showShareWhatsapp;
  const [addressCopied, setAddressCopied] = useState(false);

  const { lang, direction } = useContext(localeContext);

  const mobileLargeMQ = useMediaQuery({ maxWidth: 425 });
  const mediumToLrgMQ = useMediaQuery({ minWidth: 426, maxWidth: 1024 });

  useEffect(() => {
    addressCopied && setTimeout(() => setAddressCopied(false), 3000);
  }, [addressCopied]);

  return (
    order.userData.address && (
      <>
        <div className={cx('flex flex-row mt-4', 'justify-between')}>
          <Typography variant="heading14">
            <Text value={translations.DELIVERY_ADDRESS} />
          </Typography>
        </div>
        <div className={cx('flex flex-col w-full py-3')}>
          <div className="flex flex-col w-full">
            <span className="block text-sm" style={{ direction }} ref={addressRef}>
              <Address {...order.userData.address} {...order.deliveryZone} />
            </span>
            {order.userData.address.notes && (
              <div
                style={{ direction }}
                className="w-full flex items-center border border-blue-200 mt-2 mb-3 py-2 px-3 bg-blue-50"
              >
                <div className={cx(lang === 'ar' ? 'mr-3' : 'ml-3', 'flex items-center whitespace-pre-line')}>
                  <Text
                    full={false}
                    value={translations.ORDER_NOTE}
                    className={cx(
                      lang === 'ar' ? 'ml-3' : 'mr-3',
                      'font-semibold text-sm text-primary-base flex-shrink-0',
                    )}
                  />
                  <Typography variant="body14">{order.userData.address.notes}</Typography>
                </div>
              </div>
            )}
          </div>
          <div
            className={cx(
              'flex items-center w-full mt-4 gap-7',
              mediumToLrgMQ && 'mt-1',
              mobileLargeMQ && 'w-full mt-1',
              (mobileLargeMQ || mediumToLrgMQ) && 'flex-wrap',
            )}
          >
            <span
              role="presentation"
              className={cx('flex items-center cursor-pointer text-primary-base')}
              onClick={() => {
                copyToClipboard(addressRef.current.innerText);
                setAddressCopied(true);
              }}
              style={{ direction }}
            >
              <CopyIcon width="20px" height="20px" color="#234cfb" className={cx(lang === 'ar' ? 'ml-3' : 'mr-3')} />
              <span className="text-primary-base">
                {addressCopied ? (
                  <Text value={translations.ADDRESS_COPIED} className="inline text-sm" />
                ) : (
                  <Text value={translations.COPY} className="inline text-sm" />
                )}
              </span>
            </span>
            {addressGMapURL && (
              <div className="flex items-center flex-wrap" style={{ direction }}>
                <div>
                  <MapPinIcon
                    width="20px"
                    height="20px"
                    color="#234cfb"
                    className={cx(lang === 'ar' ? 'ml-2' : 'mr-2')}
                  />
                </div>
                <a
                  href={addressGMapURL}
                  target="_blank"
                  rel="noreferrer"
                  className={cx(lang === 'ar' && 'text-right', 'block text-primary-base text-sm')}
                >
                  <Text full={false} value={translations.VIEW_MAP} />
                </a>
              </div>
            )}
            {(hasShareWhatsapp || isSupport) && (
              <div className="flex items-center" style={{ direction }}>
                <LogosWhatsAppIcon
                  width="20px"
                  height="20px"
                  color="#234cfb"
                  className={cx(lang === 'ar' ? 'ml-2' : 'mr-2')}
                />
                <a
                  href={orderWhatsAppUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={cx(lang === 'ar' && 'text-right', 'block text-primary-base text-sm')}
                >
                  <Text full={false} value={translations.SHARE} />
                </a>
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
};

OrderDetailsUserAddress.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({
          cityTitleEn: PropTypes.string,
          cityTitleAr: PropTypes.string,
        }),
        notes: PropTypes.string,
      }),
    }),
    deliveryZone: PropTypes.shape({
      zoneName: PropTypes.string,
    }),
  }),
  addressGMapURL: PropTypes.string,
  orderWhatsAppUrl: PropTypes.string,
};
export default OrderDetailsUserAddress;
