import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@zydalabs/zac-react';
import { XIcon } from '@zydalabs/zac-icons-react';
import { useMutation } from '@apollo/client';

import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import {
  ARE_YOU_SURE,
  CANCEL,
  CONFIRM,
  DEACTIVATE_ENGAGE_MESSAGE,
  DEACTIVATE_ENGAGE_CONFIRM_MESSAGE,
  SOMETHING_WENT_WRONG,
} from 'constants/translations';
import { Text } from 'components/service';
import { useSelectedStore } from 'hooks';
import { UPDATE_ENGAGE_STATUS } from '../../schemas';

const DeactivateConfirmModal = ({ close, setEngageController }) => {
  const storeId = useSelectedStore();
  const { translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);

  const [disableDeactivateButton, setDisableDeactivateButton] = useState(false);

  // handle activate engage mutation
  const [disableEngage] = useMutation(UPDATE_ENGAGE_STATUS, {
    onCompleted: () => {
      setDisableDeactivateButton(false);
      setEngageController({
        registered: false,
        active: false,
      });
      notifications.show(<Text value={DEACTIVATE_ENGAGE_CONFIRM_MESSAGE} />, 'success');
      close();
    },
    onError: () => {
      setDisableDeactivateButton(false);
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  // handle deactivate
  const handleDeactivate = () => {
    setDisableDeactivateButton(true);
    disableEngage({
      variables: { storeId, enableEngage: false },
    });
  };

  return (
    <div className="px-4">
      <div className="pt-4 flex justify-between items-center mb-4">
        <Typography variant="heading20">
          <Text value={ARE_YOU_SURE} />
        </Typography>
        <div className="flex items-center justify-center cursor-pointer">
          <XIcon width="32px" color="#000" onClick={close} />
        </div>
      </div>

      <Typography variant="body14" mb={32}>
        <Text value={DEACTIVATE_ENGAGE_MESSAGE} />
      </Typography>

      <div className="flex justify-end gap-2">
        <Button rounded variant="secondary" text={translate(CANCEL)} onClick={close} />
        <Button
          rounded
          variant="primary"
          text={translate(CONFIRM)}
          disabled={disableDeactivateButton}
          isLoading={disableDeactivateButton}
          onClick={handleDeactivate}
        />
      </div>
    </div>
  );
};

DeactivateConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
  setEngageController: PropTypes.func.isRequired,
};

export default DeactivateConfirmModal;
