import React, { useContext } from 'react';
import cx from 'classnames';
import { Typography } from '@zydalabs/zac-react';

import { context as localContext } from 'context/locale';
import { useMobile } from 'hooks';

type EmptyOverviewStateProps = {
  title: Array<string>;
  Image: any;
};

const EmptyOverviewState: React.FC<EmptyOverviewStateProps> = ({ title, Image }) => {
  const { lang, translate } = useContext(localContext);
  const isMobile = useMobile();
  return (
    <div className={cx(!isMobile && 'w-2/3', 'flex flex-row bg-gray-100 px-4 py-6 border')}>
      <div className={cx(lang === 'ar' ? 'pl-8' : 'pr-8')}>
        <img src={Image} alt="empty-state" />
      </div>
      <div className="w-full flex flex-col py-9">
        <Typography variant="heading14">{translate(title)}</Typography>
      </div>
    </div>
  );
};

export default EmptyOverviewState;
