import { apiMutate } from 'service/utils/mutation';

import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { CreateDeliveryZoneInput, CreateDeliveryZonePayload } from '../../types/generated';
import { createDeliveryZoneMutation } from '../../mutations';

type useCreateDeliveryZoneType = () => ({
  input,
}: {
  input?: CreateDeliveryZoneInput;
}) => Promise<void | Partial<CreateDeliveryZonePayload> | { hasError: boolean }>;

const useCreateDeliveryZone: useCreateDeliveryZoneType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return input =>
    apiMutate({
      mutation: createDeliveryZoneMutation,
      variables: { input },
      clientName: GRAPHQL_CLIENT_NAMES.DELIVERY_ZONES,
      requestHeaders: { token: deliveryZoneMsToken },
      handler: (res: any) => res,
    });
};

export default useCreateDeliveryZone;
