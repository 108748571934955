import { retentionProgress } from 'service/queries';
import { QueryEngageSalesStatsArgs, EngageMetrics } from 'service/types/generated/backend_client_index';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import * as userService from 'services/user';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';

type useFetchRetentionProgressType = (args: QueryEngageSalesStatsArgs) => QueryHookResponse<EngageMetrics>;

const useFetchRetentionProgress: useFetchRetentionProgressType = ({ subdomain }) => {
  const token = userService.getToken();
  return useQuery<EngageMetrics>({
    query: retentionProgress,
    variables: { subdomain },
    clientName: GRAPHQL_CLIENT_NAMES.BACKEND,
    requestHeaders: { Authorization: `Bearer ${token}` },
    handler: (res: any) => res,
    config: { revalidateOnMount: true },
  });
};

export default useFetchRetentionProgress;
