// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { Provider } from './context';
import Ajwan from './Ajwan';

const AjwanContainer = ({ courier, closeable, onCancel, defaultStep }) => {
  const { selectedStore, pos } = useContext(userContext);
  const ajwanBackendTitles = [];

  useEffect(() => {
    if (courier && courier.integrationData && courier.integrationData.branchLinkingSection) {
      const {
        integrationData: { branchLinkingSection },
      } = courier;
      branchLinkingSection.forEach(branchSection => {
        ajwanBackendTitles.push(branchSection.inputField.backendName);
      });
    }
  }, [courier]);
  useEffect(() => {
    closeable(false);
  }, []);

  return (
    <Provider defaultValues={{ storeId: selectedStore.id, defaultTaxType: pos?.isTaxInclusive, ajwanBackendTitles }}>
      <Ajwan courier={courier} onCancel={onCancel} defaultStep={defaultStep} />
    </Provider>
  );
};

AjwanContainer.propTypes = {
  courier: PropTypes.shape({
    integrationData: PropTypes.shape({
      branchLinkingSection: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          inputField: PropTypes.shape({
            backendName: PropTypes.string.isRequired,
            hintAr: PropTypes.string.isRequired,
            hintEn: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }),
  closeable: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultStep: PropTypes.number.isRequired,
};
export default AjwanContainer;
