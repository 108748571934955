import { gql } from 'graphql-request';

const verdDriversQuery = gql`
  query($storeId: Int!, $branchId: [Int], $driverId: Int, $isHidden: Boolean) {
    allStoreDrivers(storeId: $storeId, branchIds: $branchId, driverId: $driverId, isHidden: $isHidden) {
      id
      name
      phone
      email
      branches
      onShift
      jobType
      language
      assignedOrdersCount
      lastAssignedAt
      isHidden
    }
  }
`;

export default verdDriversQuery;
