import React, { useContext } from 'react';
import { Spinner } from '@zydalabs/zac-react';
import cx from 'classnames';

import {
  AVG_ORDER,
  LAST_30_DAYS,
  NEW_CUSTOMERS,
  SUMMARY,
  TOTAL_SALES,
  VIEW_SALES_ANALYTICS,
  ORDERS,
  RETURNING_CUSTOMERS,
} from 'constants/translations';
import * as paths from 'paths.js';
import { useFetchAnalyticsSummary } from 'service/hooks/backend';
import { useMobile } from 'hooks';
import { context as localeContext } from 'context/locale';
import SectionTitle from '../common/SectionTitle';
import CardData from './CardData';

const Summary: React.FC = () => {
  const { data: summaryData, isLoading } = useFetchAnalyticsSummary();
  const { translate } = useContext(localeContext);
  const isMobile = useMobile();

  const {
    totalOrders,
    ordersPercentDiff,
    avgOrderValue,
    avgOrderPercentDiff,
    totalSales,
    salesPercentDiff,
    newCustomers,
    newCustomersPercentDiff,
    returningCustomers,
    returningCustomersPercentDiff,
  } = summaryData || {};

  return (
    <div>
      <SectionTitle
        title={SUMMARY}
        subtitle={LAST_30_DAYS}
        linkText={VIEW_SALES_ANALYTICS}
        linkPath={paths.salesAnalytics}
      />
      <div className={cx('py-6 flex w-full', isMobile ? 'flex-col' : 'flex-row')}>
        {isLoading ? (
          <Spinner radius={20} />
        ) : (
          <>
            <CardData
              title={translate(ORDERS)}
              info={totalOrders}
              delta={`${ordersPercentDiff}%`}
              isHighRate={ordersPercentDiff > 0}
              disableTag={ordersPercentDiff === 0}
            />
            <CardData
              title={translate(AVG_ORDER)}
              infoInCurrency={avgOrderValue}
              delta={`${avgOrderPercentDiff}%`}
              isHighRate={avgOrderPercentDiff > 0}
              disableTag={avgOrderPercentDiff === 0}
            />
            <CardData
              title={translate(TOTAL_SALES)}
              infoInCurrency={totalSales}
              delta={`${salesPercentDiff}%`}
              isHighRate={salesPercentDiff > 0}
              disableTag={salesPercentDiff === 0}
            />
            <CardData
              title={translate(NEW_CUSTOMERS)}
              info={newCustomers}
              delta={`${newCustomersPercentDiff} %`}
              isHighRate={newCustomersPercentDiff >= 1}
              disableTag={newCustomersPercentDiff === 0}
            />
            <CardData
              title={translate(RETURNING_CUSTOMERS)}
              info={returningCustomers}
              delta={`${returningCustomersPercentDiff} %`}
              isHighRate={returningCustomersPercentDiff >= 1}
              disableTag={returningCustomersPercentDiff === 0}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default Summary;
