import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import couriersEstimationsQuery from '../../queries/verd/couriersEstimations';

type useFetchCouriersEstimationsType = (args: any) => QueryHookResponse<any>;

const useFetchCouriersEstimations: useFetchCouriersEstimationsType = args => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  return useQuery<any>({
    query: couriersEstimationsQuery,
    variables: { ...args },
    clientName: GRAPHQL_CLIENT_NAMES.VERD,
    handler: (res: any) => res.getCouriersEstimations,
    config: { revalidateOnMount: true },
    requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
  });
};
export default useFetchCouriersEstimations;
