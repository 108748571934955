import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@zydalabs/zac-react';
import { ArrowRightIcon, ArrowLeftIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import ImageView from 'assets/engage-phone.svg';
import { Text } from 'components/service';
import {
  SEE_HOW_IT_WORKS,
  WHATSAPP_MARKET_AUTOPILOT,
  WHATSAPP_MARKET_AUTOPILOT_MESSAGE,
  ACTIVATE_WHATSAPP_AUTOPILOT,
} from 'constants/translations';
import { Modal } from 'components/kit';
import ActivateModal from '../modals/ActivateModal';

const EngageWizard = ({ engageController, isOnlinePaymentAvailable, setEngageController }) => {
  const { active } = engageController;
  const { translate, lang, direction } = useContext(localeContext);

  return (
    <div className="bg-zyda-purple-50 p-5 md:flex md:flex-row-reverse items-center justify-between">
      <div>
        <img src={ImageView} alt="imageview" />
      </div>
      <div className="md:w-3/6 max-w-lg p-4" dir={direction}>
        <Typography variant="heading24">
          <Text value={WHATSAPP_MARKET_AUTOPILOT} />
        </Typography>

        <Typography variant="body16" mt={12}>
          <Text value={WHATSAPP_MARKET_AUTOPILOT_MESSAGE} />
        </Typography>
        <div className="mt-5">
          <Button
            text={translate(SEE_HOW_IT_WORKS)}
            variant="tertiary"
            rounded
            onClick={() => {
              // return
            }}
          />
        </div>
        {!active && (
          <Modal>
            {({ open, close }) => (
              <div className="mt-5 md:w-3/4">
                <Button
                  isFluid
                  text={translate(ACTIVATE_WHATSAPP_AUTOPILOT)}
                  variant="primary"
                  rounded
                  onClick={e => {
                    e.preventDefault();
                    open({
                      body: (
                        <ActivateModal
                          open={open}
                          close={close}
                          isOnlinePaymentAvailable={isOnlinePaymentAvailable}
                          setEngageController={setEngageController}
                        />
                      ),
                      size: 'max-w-xl',
                    });
                  }}
                  endIcon={lang === 'ar' ? <ArrowLeftIcon width="20px" /> : <ArrowRightIcon width="20px" />}
                />
              </div>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

EngageWizard.propTypes = {
  engageController: PropTypes.shape({
    active: PropTypes.bool,
  }).isRequired,
  setEngageController: PropTypes.func.isRequired,
  isOnlinePaymentAvailable: PropTypes.bool.isRequired,
};

export default EngageWizard;
