/**
 * Converts query string to an Object
 * @param {string} url
 * @returns {Object}
 */
const parseQueryString = url => {
  const urlParams = {};
  url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
    urlParams[$1] = $3;
  });
  return urlParams;
};

export default parseQueryString;
