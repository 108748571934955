import { useDeliveryZoneMsToken } from 'hooks';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { deleteStoreDriverMutation } from '../../mutations/verd';

import { Scalars, InputMaybe } from '../../types/generated';

type DeleteStoreDriverMutationInput = {
  id?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type DeleteStoreDriverMutationPayload = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};
type useDeleteStoreDriverType = () => ({
  input,
}: {
  input?: DeleteStoreDriverMutationInput;
}) => Promise<void | Partial<DeleteStoreDriverMutationPayload> | { hasError: boolean }>;

const useDeleteStoreDriver: useDeleteStoreDriverType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return input =>
    apiMutate({
      mutation: deleteStoreDriverMutation,
      variables: { ...input },
      clientName: GRAPHQL_CLIENT_NAMES.VERD,
      requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
      handler: (res: any) => res,
    });
};

export default useDeleteStoreDriver;
