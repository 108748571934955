import assignScheduledOrderNowMutation from 'service/mutations/verd/assignScheduledOrderNow';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { useDeliveryZoneMsToken } from 'hooks';

type useAssignDriverToOrderType = () => (variables: any) => any;

const useAssignScheduledOrderNow: useAssignDriverToOrderType = () => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  return variables =>
    apiMutate({
      mutation: assignScheduledOrderNowMutation,
      variables,
      clientName: GRAPHQL_CLIENT_NAMES.VERD,
      requestHeaders: deliveryZoneMsToken ? { token: deliveryZoneMsToken } : {},
      handler: (res: any) => res,
    });
};

export default useAssignScheduledOrderNow;
