import React from 'react';
import * as yup from 'yup';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const egyptPhoneNumberRegex = /^201[0125][0-9]{8}$/;
const saudiPhoneNumberRegex = /^966[5][0-9]{8}$/;
const kuwaitPhoneNumberRegex = /^965[569]\d{7}$/;
const qatarPhoneNumberRegex = /^974[0-9]{8}$/;
const emiratesPhoneNumberRegex = /^971([\d]{8})/;
const bahrainPhoneNumberRegex = /^973[1][0-9]{7}$/;
const omanPhoneNumberRegex = /^968[0-9]{8}$/;
const jordanPhoneNumberRegex = /^962[6][0-9]{7}$/;

const validationSchema = yup.object().shape({
  name: yup.string().required(<Text value={translations.FULL_NAME_IS_REQ} />),
  email: yup
    .string()
    .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
    .nullable(),
  phone: yup
    .mixed()
    .test('is-valid-phone-number', <Text value={translations.INVALID_PHONE} />, phoneNumber => {
      let isValidPhoneNumber = true;
      const phoneNumberWithoutPlus = phoneNumber?.replace('+', '');
      if (
        phoneNumberWithoutPlus &&
        !phoneNumberWithoutPlus.replace('+', '').match(egyptPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(saudiPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(kuwaitPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(qatarPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(emiratesPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(bahrainPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(omanPhoneNumberRegex) &&
        !phoneNumberWithoutPlus.match(jordanPhoneNumberRegex)
      ) {
        isValidPhoneNumber = false;
      }
      return isValidPhoneNumber;
    })
    .test('is-phone-number', <Text value={translations.PHONE_IS_REQ} />, phoneNumber => {
      let isPhoneNumber = false;
      if (phoneNumber) isPhoneNumber = true;
      return isPhoneNumber;
    }),
  jobType: yup.string().required(<Text value={translations.JOB_TYPE_IS_REQ} />),
  language: yup.string().required(<Text value={translations.LANGUAGE_IS_REQ} />),
  branches: yup.array().of(yup.string()),
});

export default validationSchema;
