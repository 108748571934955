import React, { useContext } from 'react';
import cx from 'classnames';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Typography, Button } from '@zydalabs/zac-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const OrderDeliveryCancellation = ({ order, unAssignDriverToOrder, assignee, close }) => {
  const { lang, translate } = useContext(localeContext);

  const isArabic = lang === 'ar';
  const { deliveryCourier } = order || {};
  const { supportCancellation, courierDetails } = deliveryCourier || {};
  const { displayNameAr, name, displayNameEn, supportNumber } = courierDetails || {};

  const orderDeliveryCourierName = isArabic ? displayNameAr || name : displayNameEn || name;
  const orderDeliveryCourierSupportNumber = supportNumber;

  const cancellationWarningText = supportCancellation
    ? translations.CANCELLATION_COURIER_WARNING(orderDeliveryCourierName)
    : translations.NO_SUPPORT_CANCELLATION_COURIER_WARNING(orderDeliveryCourierName, orderDeliveryCourierSupportNumber);

  return (
    <>
      <div className={cx('flex items-center bg-zyda-yellow-50 px-4 py-4 -mt-3', isArabic && 'flex-row-reverse')}>
        <ExclamationIcon className={cx(isArabic ? 'ml-2' : 'mr-2', 'w-6 h-6 text-zyda-red-500')} />
        <Typography>{translate(cancellationWarningText)}</Typography>
      </div>
      <div className="w-full flex flex-row-reverse border-t pt-4 items-center gap-2">
        <Button
          onClick={() => {
            unAssignDriverToOrder(assignee);
            close();
          }}
          text={translate(translations.CONFIRM)}
        />
        <Button variant="ghost" text={translate(translations.CANCEL)} onClick={() => close()} />
      </div>
    </>
  );
};

export default OrderDeliveryCancellation;
