import { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { context as userContext } from 'context/user';
import CASHBACK_PROGRAM_STATUS_ENUM from 'pages/orderfast/loyalty/constants';
import { HAS_ACTIVE_OR_SCHEDULED_WITH_END_DATE, HAS_NO_ACTIVE_OR_SCHEDULED } from 'constants/translations';
import { BACKEND_GRAPHQL_SERVER } from '../../../../constants';
import { CASHBACK_PROGRAMS_LIST } from '../schemas';

const useCashbackPrograms = () => {
  const { selectedStore } = useContext(userContext);
  const { subdomain } = selectedStore;
  const [cashbackNote, setCashbackNote] = useState(null);

  // check cashback program
  const { data: { cashbackPrograms } = {}, loading: cashbackLoading, error: cashbackError } = useQuery(
    CASHBACK_PROGRAMS_LIST,
    {
      context: { serverName: BACKEND_GRAPHQL_SERVER },
      variables: { subdomain },
    },
  );

  useEffect(() => {
    if ((!cashbackLoading || !cashbackError) && cashbackPrograms) {
      if (cashbackPrograms && cashbackPrograms.length > 0) {
        // Check if there are no active or scheduled items
        const hasNoActiveOrScheduled = !cashbackPrograms.some(
          obj =>
            obj.status === CASHBACK_PROGRAM_STATUS_ENUM.ACTIVE || obj.status === CASHBACK_PROGRAM_STATUS_ENUM.SCHEDULED,
        );

        // Check if there is an active or scheduled item with an end date
        const hasActiveOrScheduledWithEndDate = cashbackPrograms.some(
          obj =>
            (obj.status === CASHBACK_PROGRAM_STATUS_ENUM.ACTIVE ||
              obj.status === CASHBACK_PROGRAM_STATUS_ENUM.SCHEDULED) &&
            obj.endsAt !== null,
        );

        // Check if there is an active item with no end date
        const hasActiveWithNoEndDate = cashbackPrograms.some(
          obj => obj.status === CASHBACK_PROGRAM_STATUS_ENUM.ACTIVE && obj.endsAt === null,
        );

        // Check if there is a scheduled item with no end date
        const hasScheduledWithNoEndDate = cashbackPrograms.some(
          obj => obj.status === CASHBACK_PROGRAM_STATUS_ENUM.SCHEDULED && obj.endsAt === null,
        );

        // READY TO GOO / PERFECT

        if (hasNoActiveOrScheduled) {
          setCashbackNote(HAS_NO_ACTIVE_OR_SCHEDULED);
        }

        if (hasActiveOrScheduledWithEndDate || hasScheduledWithNoEndDate) {
          setCashbackNote(HAS_ACTIVE_OR_SCHEDULED_WITH_END_DATE);
        }

        if (hasActiveWithNoEndDate) {
          setCashbackNote(null);
        }
      } else {
        setCashbackNote(HAS_NO_ACTIVE_OR_SCHEDULED);
      }
    }
  }, [cashbackPrograms, cashbackLoading, cashbackError]);

  return { cashbackNote };
};

export default useCashbackPrograms;
