import React, { useState, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Button, Modal } from 'components/kit';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import logo from 'assets/logo.svg';
import { SELECT_YOUR_STORE, PUBLISH, UNPUBLISH, TAKE_STORE_OFFLINE } from 'constants/translations';
import { Text } from 'components/service';
import PublishedState from 'common/components/PublishedState';
import { UPDATE_STORE } from 'components/common/dashboard/Layout/StoresSwitch/schemas';
import UnpublishModal from 'components/common/dashboard/Layout/StoresSwitch/UnpublishModal';

const Store = ({ onClick, isSelected = false, isMain = false, ...store }) => {
  const [published, setPublished] = useState(store?.published ? JSON.parse(store.published) : false);
  const { hasRole, isNavBarCollapsed } = useContext(userContext);
  const { lang, direction } = useContext(localeContext);
  const [updateStore, { loading }] = useMutation(UPDATE_STORE, {
    onCompleted: () => setPublished(!published),
  });

  const handleOnClick = (e, isPublished, open, close, updateStoreMutation) => {
    e.stopPropagation();
    isPublished
      ? open({
          title: (
            <div className="flex items-center">
              <span className="text-lg text-red-600">
                <Text value={TAKE_STORE_OFFLINE} />
              </span>
            </div>
          ),
          body: (
            <UnpublishModal
              updateStore={() => {
                updateStoreMutation({
                  variables: {
                    id: store.id,
                    published: !published,
                  },
                });
                close();
              }}
              onCancel={close}
              lang={lang}
            />
          ),

          size: 'max-w-xs',
        })
      : updateStoreMutation({
          variables: {
            id: store.id,
            published: !published,
          },
        });
  };

  return (
    <Modal>
      {({ open, close }) => (
        <div
          role="menuitem"
          tabIndex={0}
          onClick={onClick}
          onKeyDown={onClick}
          className={cx(
            'flex items-center rounded-lg cursor-pointer relative select-none',
            isSelected
              ? 'bg-primary-base/10 border-primary-base hover:bg-primary-base/20'
              : isMain
              ? !isNavBarCollapsed && 'border bg-gray-200 hover:bg-gray-300'
              : 'hover:bg-gray-50',
            isMain ? (isNavBarCollapsed ? 'py-3' : 'py-4') : 'py-4 sm:py-6',
            !isNavBarCollapsed && 'px-4',
          )}
          style={{ direction }}
          data-testid="publish-unpublish-btn"
        >
          <div className="w-full flex flex-col sm:align-middle sm:flex-row sm:justify-between">
            <div className="w-full sm:w-auto flex flex-row align-middle items-center">
              <img
                src={store.logoUrl || logo}
                className={cx('w-auto align-middle', lang === 'en' ? 'mr-1.5' : 'ml-2', !isNavBarCollapsed && 'h-6')}
                alt=""
              />
              {!isNavBarCollapsed && (
                <Text className={cx(isSelected && 'text-primary-base')} value={store || SELECT_YOUR_STORE} />
              )}
            </div>
            {!isSelected && !isMain && (
              <div className="flex flex-row justify-end">
                <PublishedState direction={direction} published={published} />
              </div>
            )}

            {(hasRole('owner') || hasRole('ops_manager')) && isSelected && (
              <div className={cx(direction === 'rtl' ? 'mr-auto' : 'ml-auto')}>
                <Button
                  disabled={loading}
                  onClick={e => handleOnClick(e, published, open, close, updateStore)}
                  kind="tertiary"
                  size="sm"
                  data-testid={published ? 'unpublish' : 'publish'}
                >
                  {published ? (
                    <Text className="text-red-500 font-normal" value={UNPUBLISH} />
                  ) : (
                    <Text className="text-green-600 font-normal" value={PUBLISH} />
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

Store.propTypes = {
  onClick: PropTypes.func.isRequired,
  isMain: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default Store;
